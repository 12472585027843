import React, { useState } from "react";
import { capitalizeFirst } from "../../../../../utils/formatting";
import AppEmpty from "../../../../../shared/appEmpty/appEmpty";

import { useDispatch } from "react-redux";
import { getCodeOrPlanScanRemediation } from "../../../../../redux/actions/ciWorkflowsActions";
import RemediationAllBtn from "../../../remediationAllBtn/remediationAllBtn";
import PolicyScaningItem from "./policyScanningItem";
import { sendEvent } from "../../../../../utils/amplitude";
import { aiRemediationEvents } from "../../../../../utils/amplitudeEvents";
import "./policyScaning.scss";

const PolicyScaning = ({ scanResults = [], taskId = "" }) => {
    const [openRemediationsByDefault, setOpenRemediationsByDefault] = useState(false);
    const [allLoading, setAllLoading] = useState(false);
    const dispatch = useDispatch();

    if (!scanResults.length) {
        return <div className="PolicyScaning__empty">
            <AppEmpty text="No data" customStyle="code" />
        </div>
    }

    const handleRemediateAll = async() => {
        setAllLoading(true);
        const planScans = (scanResults || []).reduce((acc, { id: scanId, resourceId = "" } = {}) => {
            acc[scanId] = resourceId;
            return acc;
        }, {});
        await fetchRemediateScan(planScans);
        setOpenRemediationsByDefault(true);
        setAllLoading(false);
        sendEvent(aiRemediationEvents.clickedWorkflowPolicyViolationsAll, { origin: "plan" });
    };

    const fetchRemediateScan = async(planScans) => {
        const payload = { planScans };
        await dispatch(getCodeOrPlanScanRemediation(payload, taskId, true));
    }

    return (
        <div className="PolicyScaning-container">
            <div className="PolicyScaning__remediateAll">
                <RemediationAllBtn handleRemidiationAllDiagnostics={() => handleRemediateAll()} customBtnTitle="Remediate all violations" loading={allLoading}/>
            </div>
            {scanResults.map((result = {}) => {
            const { severity = "", actualValue = "", expectedValue = "", code = "", line: codeStartLine = 1, id: scanId, resourceId, description, ruleName, ruleUrl } = result;
            const severLower = severity.toLowerCase();
            const severityTitle = capitalizeFirst(severLower);
                return <PolicyScaningItem key={scanId} {...{ severLower, severityTitle, actualValue, expectedValue, codeStartLine, code, allLoading,
                    scanId, resourceId, fetchRemediateScan, ruleName, ruleUrl, description }} openRemediationsByDefault={openRemediationsByDefault}/>
            })}
        </div>
    )
}

export default PolicyScaning;