import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from 'lodash/isEmpty';
import { getInsightsSuggestionAssets, getRemediation } from "../../../redux/actions/insightsV2Actions";
import AppModal from "../../../shared/appModal/appModal";
import { suggestionsArr } from "../../../consts/suggestions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SingleSuggestion from "./singleSuggestion";
import Loading from '../../../shared/loading/loading';
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import { getProvideAccountNameByNum, getProviderDarkIconByAccount } from "../../../utils/helpers";
import { getAllProvidersArray } from "../../../utils/formatting";
import MinimalCopy from "../../../shared/minimalCopy/minimalCopy";
import { Collapse } from 'antd';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import AppBtn from '../../../shared/appBtn/appBtn';
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";
import "./suggestionsModal.scss";
import { PROVIDERS_RECOMMENDATION } from "../../../consts/general";
import { checkCommandsListNotEmpty, checkRemediationCommandsNotEmpty, getCommandGcp } from "../../../utils/insightsHelper";
import AiRemediationManagement from "../insightsTable/aiRemediation/aiRemediationManagement";
import NewAppModal from "../../../shared/newAppModal/newAppModal";


const suggestionsSize = 5000 

const SuggestionsModal = ({
  visible,
  handleClose,
  data = {},
  tableFilters = {},
}) => {
  const dispatch = useDispatch();
  const [enrichSuggestion, setEnrichSuggestion] = useState({});
  const [loading, setLoading] = useState(false);
  const [providerGroupsData, setProviderGroupsData] = useState({});
  const [loadingRemediation, setLoadingRemediation] = useState(true);
  const [selectedAsset, setSelectedAsset] = useState({});
  const [isPRVisible, setIsPRVisible] = useState(false);
  const [IsCodifyVisible, setIsCodifyVisible] = useState(false);

  const { Panel } = Collapse;

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const suggestionsAssets = useSelector(
    (state) => state.insightsV2Reducer.suggestionsAssets
  );

  const responseObjects = useSelector(
    (state) => state.inventoryReducer.responseObjects
  );

  const inventoryFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);

  const remeditation = useSelector(
    (state) => state.insightsV2Reducer.remeditation
  );

  const activeFeatures = useSelector((state) => state.activeFeaturesReducer.activeFeatures) || [];
  const isAzureActive = activeFeatures.find((feature = {}) => feature.path === '/azure');

  const isAzureDisk = (data?.name === "Azure unassociated public IP addresses" || data?.name === "Azure unattached managed disks")

    const getRemediations = async (data) => {   
        setLoadingRemediation(true);     
        setSelectedAsset(data);
        const { classifications = [] } = inventoryFilters || {}
        const { integrationId, frn } = data || {}
        await dispatch(
            getRemediation({ integrationId, frn, classification: classifications[0] })
        );
        setLoadingRemediation(false);
    }
 
    useEffect(() => {
        if (visible && !isEmpty(data)) {
            let match = {};
            if( data?.classificationType === PROVIDERS_RECOMMENDATION.gcp || data?.backendCalculatedCost ){
                match = data
            }
        else{
            match = suggestionsArr?.find((item) => item?.name === data?.name);
        }
        setEnrichSuggestion(!isEmpty(match) ? match : {});
        fetchInsightAssets();
        }
    }, [visible, data]);
  
  useEffect(() => {
    if (!isEmpty(suggestionsAssets) && visible && !isEmpty(enrichSuggestion)) {
      const allAccounts = getAllProvidersArray(providerIntegrations);
      const providersGroup = { ...suggestionsAssets };
      const providersData = {};
      const classificationId = data?._id;
      Object.keys(providersGroup).map((providerId) => {
        const cost = providersGroup[providerId]?.cost;
        if (cost === 0) return;

        const providersGroupData = providersGroup[providerId]?.data;
        const icon = getProviderDarkIconByAccount(providerId, allAccounts);
        const accountName = getProvideAccountNameByNum(providerId, allAccounts);
        let commandsList = [];
        if (
          !isEmpty(providersGroupData[0]) &&
          enrichSuggestion?.name?.includes("AWS")
        ) {
          commandsList = providersGroupData.map((item) =>
            setCommandByType(item, enrichSuggestion?.name)
          );
        }
        const isCommandsListGcpNotEmpty = checkRemediationCommandsNotEmpty(
          providersGroupData,
          classificationId
        );
        if (isCommandsListGcpNotEmpty) {
          const commands = getCommandGcp(providersGroupData, classificationId);
          commandsList.push(...commands);
        }
        providersData[providerId] = { icon, accountName, commandsList, cost };
      });
      setProviderGroupsData(providersData);
    }
  }, [suggestionsAssets]);

  const setCommandByType = (item, type) => {
    let output = "";
    switch (type) {
      case "AWS EC2 - Unattached EBS Volumes":
        output = `aws ec2 delete-volume --volume-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS K8s - Unattached EBS Volumes":
        output = `aws ec2 delete-volume --volume-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS GP2 Type EBS Volumes":
        output = `aws ec2 modify-volume --volume-type gp3 --volume-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS Unassociated Elastic IP Addresses":
        output = `aws ec2 release-address --allocation-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS Unused Classic Load Balancer":
        output = `aws elb delete-load-balancer --load-balancer-name ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS EBS Snapshots that are older than 6 months":
        output = `aws ec2 delete-snapshot --snapshot-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS DB Snapshot older than 6 months":
        output = `aws rds delete-db-snapshot --db-snapshot-identifier ${item?.resourceId} --region ${item?.region}`;
        break;

      default:
        break;
    }
    return output;
  };

  const renderSubTitle = () => {
    if (!isEmpty(enrichSuggestion)) {
      return enrichSuggestion?.description;
    }
    return null;
  };

  const fetchInsightAssets = async () => {
    setLoading(true);
    let awsIntegrations = [];
    let gcpIntegrations = [];
    let azurermIntegrations = [];
    if(isEmpty(tableFilters?.integrations && data.classificationType !== PROVIDERS_RECOMMENDATION.gcp)){
      const  { aws } = providerIntegrations;
      awsIntegrations = aws.map((integration) => integration?.id);
    }
    if(isEmpty(tableFilters?.integrations && data.classificationType === PROVIDERS_RECOMMENDATION.gcp)){
      const  { gcp } = providerIntegrations;
      gcpIntegrations = gcp.map((integration) => integration?.id);
    }
    if(isEmpty(tableFilters?.integrations &&  data.backendCalculatedCost)){
      const  { azurerm } = providerIntegrations;
      azurermIntegrations = azurerm.map((integration) => integration?.id);
    }

    const gcpAndAwsIntegrations = [...awsIntegrations, ...gcpIntegrations, ...azurermIntegrations];
    const integrations = isEmpty(tableFilters?.integrations) ? gcpAndAwsIntegrations : tableFilters?.integrations;

    await dispatch(getInsightsSuggestionAssets(data?._id, 1, suggestionsSize, integrations));
    setLoading(false);
  };

  const onCopyAllSuggestions = () => {
    let string = "";
    Object.keys(providerGroupsData).map((providerId) => {
      const { commandsList, accountName, cost } = providerGroupsData[providerId] || {};
      const header = `# ${accountName !== providerId ? `${accountName} - ${providerId}` : providerId} - Save ${cost}$/m \n`;
      string += header
      const copyList = commandsList ?  commandsList.join("\n") : "";
      string += `${copyList} \n`;
    });
    return string;
  };

  const renderFooterLink = () => {
    const isCommandsListNotEmpty = checkCommandsListNotEmpty(providerGroupsData)

    if (!isEmpty(enrichSuggestion)) {
      return (
        <div className="SuggestionsModal__footer row between">
          {enrichSuggestion?.link ?
            <div className="SuggestionsModal__footer__link purple-text row">
              <FontAwesomeIcon icon={faInfoCircle} />
              <a href={enrichSuggestion?.link} target="_blank" rel="noreferrer">
                {enrichSuggestion?.linkDesc}
              </a>
            </div> : <div />
          }
          {!loading && isCommandsListNotEmpty &&
            <div className="SuggestionsModal__footer__copy row g10">
              <span className="SuggestionsModal__footer-text">Copy All</span>
              <MinimalCopy text={onCopyAllSuggestions()} />
            </div>}
        </div>
      );
    }
    return null;
  };

  const renderRemediations = () => {
    const { type, total_assets, cost, isDefault } = data || {};
    const isCostSaving = !isEmpty(providerGroupsData) && !isEmpty(suggestionsAssets) && visible && !isEmpty(enrichSuggestion) && cost > 0;
    const isAiRemediation = type?.length === 1 && total_assets > 0 && isDefault;

    if (isCostSaving) {
        return loading ? renderDefaultAppModal(<Loading />) : renderDefaultAppModal(renderSuggestionList());
    }
    if (isAiRemediation && !(cost > 0) ) {
      return <AiRemediationManagement visible={visible} handleClose={handleClose} data={data} />
    }
    return null;
  }


    const renderSuggestionList = () => {
        return Object.keys(providerGroupsData).map((keyName) => {
            const { icon, accountName, commandsList, cost } = providerGroupsData[keyName] || {};
            return (
            <SingleSuggestion
                key={keyName}
                accountId={keyName}
                icon={icon}
                accountName={accountName}
                commandsList={commandsList}
                cost={cost}
            />
            );
        });
    };

    const handlePRButton = () => {
        setIsPRVisible(true)
    }

    const handlePRClose = () => {
        setIsPRVisible(false)
    }

    const handleCodifyButton = () => {
        setIsCodifyVisible(true)
    }

    const handleCodifyClose = () => {
        setIsCodifyVisible(false)
    }

    const cliCodify = (command) => {
        return (
            <div className="col">
                <div>
                    {command}
                </div>
                <AppBtn
                    icon={`{ }`}
                    disabled={false}
                    onClick={handleCodifyButton}
                    tooltipText={"Codify"}
                    style={{alignSelf:"flex-end", marginTop: "20px"}}
                    text="Codify"
                />
                <AppModal
                    visible={IsCodifyVisible}
                    handleClose={handleCodifyClose}
                    bodyClassName="SuggestionsModal col"
                    title={"Codify"}
                    hideFooter={true}
                    width={"300px"}
                >
                    <div className="SuggestionsModal__content col">
                        {"Codify has been created successfully"}
                    </div>
                </AppModal>
            </div>
        )
    }

    const syntaxHighlight = (code) => {
        return (
            <>
                <SyntaxHighlighter
                    style={atomDark}
                    showLineNumbers={true}
                    language="hcl"
                    lineProps={{
                        style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                    }}
                >
                    {code.replace(/```/g, "")}

                </SyntaxHighlighter>
                <AppBtn
                        disabled={false}
                        onClick={handlePRButton}
                        text="Open PR"
                        style={{position:"absolute", bottom: "20px", right: "20px"}}
                        icon={<FontAwesomeIcon icon={faGitAlt} />}
                    />

                <AppModal
                    visible={isPRVisible}
                    handleClose={handlePRClose}
                    bodyClassName="SuggestionsModal col"
                    title={"Pull Request"}
                    hideFooter={true}
                    width={"300px"}
                >
                    <div className="SuggestionsModal__content col">
                        {"PR has been created successfully"}
                    </div>
                </AppModal>
            </>
        )
    }

    const renderAIRemediaton = () => {
        return responseObjects.map((asset) => {
            return (
            <Collapse accordion={true} key={asset?.arn} onChange={()=>getRemediations(asset)}>
                <Panel
                    key={asset?.assetId}
                    showArrow={true}
                    header={
                        <div className="SuggestionsModalCustom row between">
                            <div className="SuggestionsModalCustom__text">
                                {asset?.name}
                            </div>
                        </div>
                    }
                >
                    {selectedAsset?.assetId === asset?.assetId && (
                        <div className="SuggestionsModalCustom__remediation-text">
                            {loadingRemediation ? <Loading /> : remeditation?.cliCommand ? cliCodify(remeditation?.cliCommand) : remeditation?.terraformState ? syntaxHighlight(remeditation?.terraformState) : "No remediation available!"}
                        </div>
                    )}
                </Panel>
            </Collapse>
        )
        })
    }

    const renderDefaultAppModal = (data) => {
        return (
            <NewAppModal
                visible={visible}
                handleClose={handleClose}
                bodyClassName="SuggestionsModal__content__saving col"
                title={!isEmpty(enrichSuggestion) ? enrichSuggestion?.name : data?.name}
                hideFooterBtns
                width="85vw"
                footer={renderFooterLink()}
                subtitle={renderSubTitle()}
                customFooterClassName="SuggestionsModal__footer"
            >
                <div className="SuggestionsModal__content col">
                    {data}
                </div>
            </NewAppModal>
        )
    }

    const renderCustomAppModal = (data) => {
        return (
            <AppModal
                visible={visible}
                handleClose={handleClose}
                bodyClassName="SuggestionsModalCustom col"
                title={"AI Remediation"}
                width={"900px"}
                hideFooter={true}
            >
                <div className="SuggestionsModalCustom__content col">
                    {data}
                </div>
            </AppModal>
        )
    }

  return renderRemediations();
};

export default SuggestionsModal;
