import isEmpty from "lodash/isEmpty";
// provider icons
import awsIcon from "../Images/clouds/AWS.svg";
import awsDarkIcon from "../Images/clouds/AWSDark.svg";
import purpleAws from "../Images/clouds/purpleAws.svg";
import whiteAws from "../Images/clouds/whiteAws.svg";
import blackAws from "../Images/clouds/blackAws.svg";
import gcp from "../Images/clouds/Google.svg";
import purpleGcp from "../Images/clouds/purpleGcp.svg";
import whiteGcp from "../Images/clouds/whiteGcp.svg";
import blackGcp from "../Images/clouds/blackGcp.svg";
import azureIcon from "../Images/clouds/Azure.svg";
import purpleAzure from "../Images/clouds/purpleAzure.svg";
import whiteAzure from "../Images/clouds/whiteAzure.svg";
import blackAzure from "../Images/clouds/blackAzure.svg";
import kubernetes from "../Images/clouds/kubernetes.svg";
import kubernetesDark from "../Images/clouds/kubernetesDark.svg";
import whiteK8s from "../Images/clouds/whiteK8s.svg";
import blackK8s from "../Images/clouds/blackK8s.svg";
import purpleK8s from "../Images/clouds/purpleK8s.svg";
import oracle from "../Images/clouds/oracle.svg";
import alibaba from "../Images/clouds/alibaba.svg";
import datadog from "../Images/clouds/datadog.svg";
import datadogDark from "../Images/clouds/datadogDark.svg";
import cloudflare from "../Images/clouds/cloudFlare.svg";
import akamai from "../Images/clouds/akami.svg";
import miniAkamai from "../Images/clouds/mini_akamai.svg";
import newrelic from "../Images/clouds/newrelic.svg";
import newrelicDark from "../Images/clouds/newrelicDark.svg";
import miniNewrelic from "../Images/clouds/mini_newrelic.svg";
import miniNewrelicDark from "../Images/clouds/mini_newrelic_dark.svg";
import unknown from "../Images/clouds/unknown.svg";
import okta from "../Images/clouds/okta.svg";
import oktaDark from "../Images/clouds/oktaDark.svg";
import kafka from "../Images/clouds/kafka.svg";
import kafkaDark from "../Images/clouds/kafkaDark.svg";
import grafana from "../Images/clouds/grafana.svg";
import chef from "../Images/clouds/chef.svg";
import spot from "../Images/clouds/spot.svg";
import postgresql from "../Images/clouds/postgresql.svg";
import helmDark from "../Images/clouds/helmDark.svg";
import auth0 from "../Images/clouds/auth0.svg";
import mongodb from "../Images/clouds/mongodb.svg";
import vault from "../Images/clouds/vault.svg";
import vaultDark from "../Images/clouds/vaultDark.svg";
import torq from "../Images/clouds/torq.svg";
import ns1 from "../Images/clouds/NS1.svg";
import dark_ns1 from "../Images/clouds/dark_ns1.svg";
import deploymentManager from "../Images/clouds/deploymentManager.svg";
import cloudComposer from "../Images/clouds/cloud_composer.svg";
import apacheAirflow from "../Images/clouds/apache_airflow.svg";
import googleCloudSdk from "../Images/clouds/googleCloudSdk.svg";
import cdk8s from "../Images/clouds/cdk8s.svg";
import gke from "../Images/clouds/gke.svg";
import http from "../Images/general_icons/http.svg";
import purpleSaas from "../Images/clouds/purpleSaas.svg";
import saas from "../Images/clouds/saas.svg";
import darkSaas from "../Images/clouds/darkSaas.svg";
import env0 from "../Images/clouds/env0.svg";
import noDataSources from "../Images/clouds/noDataSources.svg";

// iac providers
import helm from "../Images/iacProviders/helm.svg";
import greenHelm from "../Images/iacProviders/green_helm.svg";
import pulumi from "../Images/iacProviders/pulumi.svg";
import darkPurplePulumi from "../Images/iacProviders/darkPurplePulumi.svg";
import whitePulumi from "../Images/iacProviders/whitePulumi.svg";
import terraform from "../Images/iacProviders/terraform.svg";
import purpleTerraform from "../Images/iacProviders/purpleTerraform.svg";
import darkPurpleTerraform from "../Images/iacProviders/darkPurpleTerraform.svg";
import whiteTerraform from "../Images/iacProviders/whiteTerraform.svg";
import greenTerraform from "../Images/iacProviders/green_terraform.svg";
import greenPulumi from "../Images/iacProviders/green_pulumi.svg";
import greyTerraform from "../Images/iacProviders/greyTerraform.svg";
import cloudFormation from "../Images/iacProviders/cloudFormation.svg";
import greenCloudFormation from "../Images/iacProviders/green_cloud_formation.svg";
import greyCloudFormation from "../Images/iacProviders/greyCloudFormation.svg";
import purpleCloudFormation from "../Images/iacProviders/purpleCloudFormation.svg";
import darkPurpleCloudFormation from "../Images/iacProviders/darkPurpleCloudFormation.svg";
import whiteCloudFormation from "../Images/iacProviders/whiteCloudFormation.svg";
import cdk from "../Images/iacProviders/cdk.png";
import purpleHelm from "../Images/iacProviders/purpleHelm.svg";
import darkPurpleHelm from "../Images/iacProviders/darkPurpleHelm.svg";
import purplePulumi from "../Images/iacProviders/purple_pulumi.svg";
import argocd from "../Images/iacProviders/argocd.svg";
import cloudDeploy from "../Images/iacProviders/cloudDeploy.svg";
import kustomize from "../Images/iacProviders/kustomize.svg";
import greenKustomize from "../Images/iacProviders/greenKustomize.svg";
import purpleKustomize from "../Images/iacProviders/purpleKustomize.svg";
import darkPurpleKustomize from "../Images/iacProviders/darkPurpleKustomize.svg";
import whiteKustomize from "../Images/iacProviders/whiteKustomize.svg";
import whiteArgo from "../Images/iacProviders/whiteArgo.svg";
import blackArgo from "../Images/iacProviders/blackArgo.svg";
import greenArgo from "../Images/iacProviders/greenArgo.svg";
import purpleArgo from "../Images/iacProviders/purpleArgo.svg";
import darkPurpleArgo from "../Images/iacProviders/darkPurpleArgo.svg";
import greenCrossplane from "../Images/iacProviders/greenCrossplane.svg";
import greenExternalDns from "../Images/iacProviders/greenExternalDns.svg";
import greenEksctl from "../Images/iacProviders/greenEks.svg";
import greenGke from "../Images/iacProviders/greenGke.svg";
import greenCloudComposer from "../Images/iacProviders/greenCloudComposer.svg";
import greenOpentofu from "../Images/iacProviders/greenOpentofu.svg";
import greenFlux from "../Images/iacProviders/greenFlux.svg";
import purpleOpentofu from "../Images/iacProviders/purpleOpentofu.svg";
import opentofu from "../Images/iacProviders/opentofu.svg";
import greenDeploymentManager from "../Images/iacProviders/greenDeploymentManager.svg";
import azureBackend from "../Images/iacProviders/Storage-Accounts.svg";
import flux from "../Images/iacProviders/flux.svg";

// integrations
import github from "../Images/integrations/github.svg";
import githubDark from "../Images/integrations/githubDark.svg";
import gitlab from "../Images/integrations/gitlab.svg";
import bitbucket from "../Images/integrations/bitbucket.svg";
import webhook from "../Images/integrations/webhooks.svg";
import teams from "../Images/integrations/teams.svg";
import EmailIcon from "../Images/general_icons/Email_icon.svg";
import slack from "../Images/integrations/slack.svg";
import terraformCloud from "../Images/integrations/terraform_cloud.svg";
import terragrunt from "../Images/general_icons/terragrunt.png";
import opa from "../Images/general_icons/opa.svg";
import git from "../Images/general_icons/git.svg";
import consul from "../Images/integrations/consul.svg";
import gcs from "../Images/integrations/gcs.svg";
import opsgenie from "../Images/integrations/opsgenie.svg";
import pagerduty from "../Images/integrations/pagerduty.svg";
import discord from "../Images/integrations/discord.svg";
import codecommit from "../Images/integrations/codecommit.svg";
import smallCDK from "../Images/integrations/cdk.svg";
import bash from "../Images/integrations/bash.svg";
import ansible from "../Images/integrations/ansible.svg";
import docker from "../Images/integrations/docker.svg";
import bicep from "../Images/integrations/bicep.svg";
import crossplane from "../Images/integrations/crossplane.svg";
import azuredevops from "../Images/integrations/azuredevops.svg";
import azurePipelines from "../Images/integrations/azure-pipelines.svg";
import githubActions from "../Images/integrations/github-actions.svg";
import jenkins from "../Images/general_icons/jenkins.svg";
import powershell from "../Images/general_icons/powershell.svg";
import googleChat from "../Images/integrations/googleChat.svg";
import webex from "../Images/integrations/notifications/webex.svg";

//ci
import unknownCiRun from "../Images/ci/icons/unknown-run.svg";
import semaphore from "../Images/ci/icons/semaphore.svg";
import atlantis from "../Images/ci/icons/atlantis.svg";

// codify
import arm from "../Images/codify/azure_resource_manager.svg";

// buckets
import aws_bucket from "../Images/general_icons/aws_bucket.svg";
import gcp_bucket from "../Images/general_icons/gcp_bucket.png";
import servicenow from "../Images/general_icons/service_now.svg";

//projects
import jira from "../Images/general_icons/jira.svg";

// insights
import misconfiguration from "../Images/general_icons/misconfiguration.svg";
import reliability from "../Images/general_icons/reliability.svg";
import optimization from "../Images/general_icons/optimization.svg";
import accessControl from "../Images/general_icons/insights-filters/access-control.svg";
import insecureConfigurations from "../Images/general_icons/insights-filters/insecure-configurations.svg";
import billOfMaterial from "../Images/general_icons/insights-filters/bill-of-material.svg";
import encryption from "../Images/general_icons/insights-filters/encryption.svg";
import buildProcess from "../Images/general_icons/insights-filters/build-process.svg";
import insecureDefaults from "../Images/general_icons/insights-filters/insecure-defaults.svg";
import networkingFirewall from "../Images/general_icons/insights-filters/networking-firewall.svg";
import secretManagement from "../Images/general_icons/insights-filters/secret-management.svg";
import observability from "../Images/general_icons/insights-filters/secret-management.svg";
import bestPractices from "../Images/general_icons/insights-filters/best-practices.svg";
import backup from "../Images/general_icons/insights-filters/backup.svg";
import availability from "../Images/general_icons/insights-filters/availability.svg";
import resourceManagement from "../Images/general_icons/insights-filters/resource-management.svg";
import customCategory from "../Images/general_icons/governance/customCategory.svg";
import security from "../Images/general_icons/insights-filters/security.svg";

import _ from "lodash";
import { CODIFY_TYPES } from "../consts/general";

// states
import child from "../Images/states/child.svg";
import iacIgnored from "../Images/states/iacIgnored.svg";

// Frameworks

import { ReactComponent as pcidssDark } from "../Images/general_icons/insights-filters/pcidssDark.svg";
import { ReactComponent as pciDss } from "../Images/general_icons/insights-filters/pciDss.svg";
import { ReactComponent as soc2Dark } from "../Images/general_icons/insights-filters/soc2Dark.svg";
import { ReactComponent as soc2 } from "../Images/general_icons/insights-filters/soc2.svg";
import { ReactComponent as hipaaDark } from "../Images/general_icons/insights-filters/hipaaDark.svg";
import { ReactComponent as hipaa } from "../Images/general_icons/insights-filters/hipaa.svg";
import { ReactComponent as cloudWasteDark } from "../Images/general_icons/insights-filters/cloudWasteDark.svg";
import { ReactComponent as cloudWaste } from "../Images/general_icons/insights-filters/cloudWaste.svg";
import { ReactComponent as googleCloudInsightDark } from "../Images/general_icons/insights-filters/googleCloudInsightDark.svg";
import { ReactComponent as googleCloudInsight } from "../Images/general_icons/insights-filters/googleCloudInsight.svg";
import { ReactComponent as pciColor } from "../Images/general_icons/insights-filters/pciColor.svg";
import { ReactComponent as hipaaColor } from "../Images/general_icons/insights-filters/hipaaColor.svg";
import { ReactComponent as soc2Color } from "../Images/general_icons/insights-filters/soc2Color.svg";
import { ReactComponent as soc2ColorDark } from "../Images/general_icons/insights-filters/soc2ColorDark.svg";
import { ReactComponent as gcpColor } from "../Images/clouds/googleColor.svg";
import { ReactComponent as taggingPolicies } from "../Images/general_icons/insights-filters/taggingPolices.svg";
import { ReactComponent as taggingPoliciesDark } from "../Images/general_icons/insights-filters/taggingPolicesDark.svg";
import { ReactComponent as nistDark } from "../Images/general_icons/insights-filters/nistDark.svg";
import { ReactComponent as nistLight } from "../Images/general_icons/insights-filters/nistLight.svg";

// FrameworksCell

import { ReactComponent as cloudWasteDarkHover } from "../Images/general_icons/governance/cloudWasteDarkHover.svg";
import { ReactComponent as cloudWasteDarkBig } from "../Images/general_icons/governance/cloudWasteDarkBig.svg";
import { ReactComponent as gcpColorDarkHover } from "../Images/general_icons/governance/gcpColorDarkHover.svg";
import { ReactComponent as gcpColorDark } from "../Images/general_icons/governance/gcpColorDark.svg";
import { ReactComponent as hipaaColorDarkHover } from "../Images/general_icons/governance/hipaaColorDarkHover.svg";
import { ReactComponent as hipaaColorDark } from "../Images/general_icons/governance/hipaaColorDark.svg";
import { ReactComponent as soc2ColorDarkHover } from "../Images/general_icons/governance/soc2ColorDarkHover.svg";
import { ReactComponent as soc2ColorDarkGig } from "../Images/general_icons/governance/soc2ColorDarkGig.svg";
import { ReactComponent as pciColorDarkHover } from "../Images/general_icons/governance/pciColorDarkHover.svg";
import { ReactComponent as pciColorDark } from "../Images/general_icons/governance/pciColorDark.svg";
import { ReactComponent as nistDarkHover } from "../Images/general_icons/governance/nistDarkHover.svg";
import { ReactComponent as nistDarkHoverBig } from "../Images/general_icons/governance/nistDarkHoverBig.svg";
import { ReactComponent as taggingPoliciesHover } from "../Images/general_icons/governance/taggingPoliciesHover.svg";
import { ReactComponent as taggingPoliciesDarkBig } from "../Images/general_icons/governance/taggingPoliciesDarkBig.svg";

import { ReactComponent as cloudWasteLightHover } from "../Images/general_icons/governance/cloudWasteLightHover.svg";
import { ReactComponent as cloudWasteLightBig } from "../Images/general_icons/governance/cloudWasteLightBig.svg";
import { ReactComponent as gcpColorLightHover } from "../Images/general_icons/governance/gcpColorLightHover.svg";
import { ReactComponent as gcpColorLight } from "../Images/general_icons/governance/gcpColorLight.svg";
import { ReactComponent as hipaaColorLightHover } from "../Images/general_icons/governance/hipaaColorLightHover.svg";
import { ReactComponent as hipaaColorLight } from "../Images/general_icons/governance/hipaaColorLight.svg";
import { ReactComponent as soc2ColorLightHover } from "../Images/general_icons/governance/soc2ColorLightHover.svg";
import { ReactComponent as soc2ColorLightGig } from "../Images/general_icons/governance/soc2ColorLightGig.svg";
import { ReactComponent as pciColorLightHover } from "../Images/general_icons/governance/pciColorLightHover.svg";
import { ReactComponent as pciColorLight } from "../Images/general_icons/governance/pciColorLight.svg";
import { ReactComponent as nistLightHover } from "../Images/general_icons/governance/nistLightHover.svg";
import { ReactComponent as nistLightHoverBig } from "../Images/general_icons/governance/nistLightHoverBig.svg";
import { ReactComponent as taggingPoliciesLightHover } from "../Images/general_icons/governance/taggingPoliciesLightHover.svg";
import { ReactComponent as taggingPoliciesLightBig } from "../Images/general_icons/governance/taggingPoliciesLightBig.svg";

export const iacProviders = (key, dark) => {
    let obj = {
        helm: dark ? helmDark : helm,
        pulumi,
        purplePulumi,
        darkPurplePulumi,
        whitePulumi,
        terraform,
        purpleTerraform,
        darkPurpleTerraform,
        whiteTerraform,
        greyTerraform,
        cloudFormation,
        cloudformation: cloudFormation,
        greyCloudFormation,
        purpleCloudFormation,
        purpleCloudformation: purpleCloudFormation,
        darkPurpleCloudFormation,
        darkPurpleCloudformation: darkPurpleCloudFormation,
        whiteCloudformation: whiteCloudFormation,
        kubernetes: dark ? kubernetesDark : kubernetes,
        k8s: dark ? kubernetesDark : kubernetes,
        terragrunt,
        cdk,
        purpleHelm,
        darkPurpleHelm,
        whiteHelm: helmDark,
        argocd,
        cloudDeploy,
        kustomize,
        whiteKustomize,
        purpleKustomize,
        darkPurpleKustomize,
        argo: dark ? whiteArgo : blackArgo,
        whiteArgo,
        darkPurpleArgo,
        purpleArgo,
        crossPlane: crossplane,
        externalDns: greenExternalDns,
        eksctl: greenEksctl,
        gke,
        flux,
        "goog-dm": deploymentManager,
        composer: cloudComposer,
        airflow: apacheAirflow,
        azureBackend,
        opentofu,
    };
    return isEmpty(obj[key]) ? unknown : obj[key];
};

export const greenIacProviders = {
    helm: greenHelm,
    pulumi: greenPulumi,
    terraform: greenTerraform,
    cloudFormation: greenCloudFormation,
    cloudformation: greenCloudFormation,
    kustomize: greenKustomize,
    k8s: greenArgo,
    crossPlane: greenCrossplane,
    crossplane: greenCrossplane,
    externalDns: greenExternalDns,
    eksctl: greenEksctl,
    gke: greenGke,
    "goog-dm": greenDeploymentManager,
    composer: greenCloudComposer,
    airflow: apacheAirflow,
    opentofu: greenOpentofu,
    flux: greenFlux,
};

export const purpleIacProviders = {
    helm: purpleHelm,
    pulumi: purplePulumi,
    terraform: terraform,
    cloudFormation: purpleCloudFormation,
    cloudformation: purpleCloudFormation,
    kustomize: purpleKustomize,
    k8s: purpleArgo,
    opentofu: purpleOpentofu,
};

export const integrationIcons = {
    github,
    gitlab,
    bitbucket,
    bitbucketdc: bitbucket,
    webhook,
    teams,
    slack,
    terraformCloud,
    opa,
    consul,
    gcs,
    opsgenie,
    git,
    pagerduty,
    discord,
    torq,
    slackApp: slack,
    codecommit,
    cdk: smallCDK,
    bash,
    ansible,
    docker,
    bicep,
    crossplane,
    azuredevops,
    powershell,
    email: EmailIcon,
    googleChat,
    webex,
};

export const buckets = {
    aws_bucket,
    gcp_bucket,
    consul,
    cloudFormation,
    cloudformation: cloudFormation,
    pulumi,
};

export const projects = {
    jira,
    servicenow,
};

export const frameworks = (key, isDark) => {
    const icons = {
        "PCI DSS": isDark ? pcidssDark : pciDss,
        "SOC 2": isDark ? soc2Dark : soc2,
        HIPAA: isDark ? hipaaDark : hipaa,
        "Cloud Waste": isDark ? cloudWasteDark : cloudWaste,
        "Google Cloud Insight": isDark ? googleCloudInsightDark : googleCloudInsight,
        "Tagging Policies": isDark ? taggingPoliciesDark : taggingPolicies,
        NIST: isDark ? nistDark : nistLight,
    };

    return key in icons ? icons[key] : null;
};

export const insights = (key) => {
    const icons = {
        "Access Control": accessControl,
        "Insecure Configurations": insecureConfigurations,
        Observability: observability,
        Encryption: encryption,
        "Networking and Firewall": networkingFirewall,
        Misconfiguration: misconfiguration,
        Reliability: reliability,
        Optimization: optimization,
        "Best Practices": bestPractices,
        Availability: availability,
        "Resource Management": resourceManagement,
        Backup: backup,
        "Insecure Defaults": insecureDefaults,
        "Secret Management": secretManagement,
        "Build Process": buildProcess,
        "Bill Of Material": billOfMaterial,
        "Cloud Waste": cloudWaste,
        Security: security,
    };

    return key in icons ? icons[key] : customCategory;
};

export const frameworksScore = (key, isDark) => {
    const icons = {
        "PCI DSS": isDark ? pciColor : pciColor,
        "SOC 2": isDark ? soc2ColorDark : soc2Color,
        HIPAA: isDark ? hipaaDark : hipaaColor,
        "Cloud Waste": isDark ? cloudWasteDark : cloudWaste,
        "Google Cloud Insight": isDark ? gcpColor : gcpColor,
        NIST: isDark ? nistDark : nistLight,
    };

    return key in icons ? icons[key] : null;
};

export const frameworkCellDark = (key, isHover) => {
    const icons = {
        "PCI DSS": isHover ? pciColorDarkHover : pciColorDark,
        "SOC 2": isHover ? soc2ColorDarkHover : soc2ColorDarkGig,
        HIPAA: isHover ? hipaaColorDarkHover : hipaaColorDark,
        "Cloud Waste": isHover ? cloudWasteDarkHover : cloudWasteDarkBig,
        "Google Cloud Insight": isHover ? gcpColorDarkHover : gcpColorDark,
        NIST: isHover ? nistDarkHover : nistDarkHoverBig,
        "Tagging Policies": isHover ? taggingPoliciesHover : taggingPoliciesDarkBig,
    };

    return key in icons ? icons[key] : null;
};

export const frameworkCellLight = (key, isHover) => {
    const icons = {
        "PCI DSS": isHover ? pciColorLightHover : pciColorLight,
        "SOC 2": isHover ? soc2ColorLightHover : soc2ColorLightGig,
        HIPAA: isHover ? hipaaColorLightHover : hipaaColorLight,
        "Cloud Waste": isHover ? cloudWasteLightHover : cloudWasteLightBig,
        "Google Cloud Insight": isHover ? gcpColorLightHover : gcpColorLight,
        NIST: isHover ? nistLightHover : nistLightHoverBig,
        "Tagging Policies": isHover ? taggingPoliciesLightHover : taggingPoliciesLightBig,
    };

    return key in icons ? icons[key] : null;
};

export const states = {
    child: child,
    iacIgnored: iacIgnored,
};

export const clouds = (key = "", dark) => {
    let obj = {
        aws: dark ? awsDarkIcon : awsIcon,
        awsDark: awsDarkIcon,
        plainAws: dark ? whiteAws : blackAws,
        purpleAws,
        gcp,
        plainGcp: dark ? whiteGcp : blackGcp,
        purpleGcp,
        google: gcp,
        azure: azureIcon,
        azurerm: azureIcon,
        purpleAzure,
        purpleAzurerm: purpleAzure,
        plainAzure: dark ? whiteAzure : blackAzure,
        plainAzurerm: dark ? whiteAzure : blackAzure,
        k8s: dark ? kubernetesDark : kubernetes,
        kubectl: dark ? kubernetesDark : kubernetes,
        kubernetes: dark ? kubernetesDark : kubernetes,
        k8sDark: kubernetesDark,
        purpleK8s,
        plainK8s: dark ? whiteK8s : blackK8s,
        oracle,
        alibaba,
        datadog: dark ? datadogDark : datadog,
        cloudflare,
        akamai: miniAkamai,
        full_akamai: akamai,
        newrelic: dark ? miniNewrelicDark : miniNewrelic,
        full_newrelic: dark ? newrelicDark : newrelic,
        none: unknown,
        okta: dark ? oktaDark : okta,
        github: dark ? githubDark : github,
        pagerduty,
        kafka: dark ? kafkaDark : kafka,
        kafkaDark,
        helm: dark ? helmDark : helm,
        helmDark,
        grafana,
        consul,
        chef,
        spot,
        postgresql,
        auth0,
        mongodb,
        mongodbatlas: mongodb,
        mongo: mongodb,
        null: terraform,
        local: terraform,
        random_string: terraform,
        vault: dark ? vaultDark : vault,
        vaultDark,
        bitbucket,
        bitbucketdc: bitbucket,
        pulumi,
        terraform,
        gitlab,
        ns1: dark ? dark_ns1 : ns1,
        codecommit,
        azuredevops,
        gcs,
        tfc: terraform,
        slack,
        teams,
        torq,
        jira,
        webhook,
        opsgenie,
        gke,
        random: terraform,
        time: terraform,
        purpleSaas,
        plainSaas: dark ? darkSaas : saas,
        env0,
        darkEnv0: env0,
        flux,
    };
    if (key.includes("objects")) {
        return noDataSources;
    }
    return isEmpty(obj[key]) ? unknown : obj[key];
};

export const moduleOriginTypes = (key, dark) => {
    let obj = {
        s3: aws_bucket,
        gcs: gcp_bucket,
        registry: terraform,
        github: dark ? githubDark : github,
        gitlab,
        codecommit,
        azuredevops,
        bitbucket,
        bitbucketdc: bitbucket,
        git,
        http,
    };
    return isEmpty(obj[key]) ? unknown : obj[key];
};

export const remoteTypes = (key, dark) => {
    let obj = {
        s3: aws_bucket,
        gcs: gcp_bucket,
        consul,
        cloudformation: cloudFormation,
        pulumi,
        k8s: dark ? kubernetesDark : kubernetes,
        tfc: terraform,
        "gitlab-s3": aws_bucket,
        azure: azureIcon,
        azurerm: azureIcon,
        env0,
        argocd,
        gitlab,
    };
    return isEmpty(obj[key]) ? unknown : obj[key];
};

export const codifyIcons = (key, dark) => {
    const obj = {
        [CODIFY_TYPES.terraform]: terraform,
        [CODIFY_TYPES.pulumi]: pulumi,
        [CODIFY_TYPES.cloudformation]: cloudFormation,
        [CODIFY_TYPES.helm]: dark ? helmDark : helm,
        [CODIFY_TYPES.cdk]: cdk,
        [CODIFY_TYPES.cdkl2]: cdk,
        [CODIFY_TYPES.crossplane]: crossplane,
        [CODIFY_TYPES.ansible]: ansible,
        [CODIFY_TYPES.deploymentManager]: deploymentManager,
        [CODIFY_TYPES.configConnector]: gke,
        [CODIFY_TYPES.googleCloudSdk]: googleCloudSdk,
        [CODIFY_TYPES.cdk8s]: cdk8s,
        [CODIFY_TYPES.manifest]: dark ? kubernetesDark : kubernetes,
        [CODIFY_TYPES.tfcdk]: terraform,
        [CODIFY_TYPES.arm]: arm,
        [CODIFY_TYPES.bicep]: bicep,
        opentofu,
    };
    return obj[key];
};

export const ciDataSourceIcons = (key, dark) => {
    const obj = {
        github: dark ? githubDark : github,
        "github-actions": githubActions,
        gitlab,
        "gitlab-pipelines": gitlab,
        "azure-pipelines": azurePipelines,
        "bitbucket-pipelines": bitbucket,
        "codecommit-pipelines": codecommit,
        bitbucket,
        bitbucketdc: bitbucket,
        codecommit,
        azuredevops,
        jenkins,
        terraform,
        semaphore,
        atlantis,
        opentofu,
        env0,
    };
    return obj[key] || unknownCiRun;
};

export const connectedServicesIcons = (key, dark) => {
    const obj = {
        k8s: dark ? kubernetesDark : kubernetes,
        kubernetes: dark ? kubernetesDark : kubernetes,
    };
    return obj[key] || unknownCiRun;
};
