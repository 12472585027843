import React from "react";
import map from "lodash/map";
import { v4 as uuidv4 } from "uuid";
import "./languageSwitcher.scss";

const LanguageSwitcher = ({
  handleSetActiveTab,
  activeTab,
  typeArr,
  disableTypes,
  disableAllExceptActive,
}) => {
  const typeTitles = {
    typescript: "TypeScript",
    python: "Python",
    go: "Go",
    csharp: "C#",
    java: "Java",
  };
  const renderTypes = () => {
    return map(typeArr, (type) => {
      const disabled = disableTypes && type !== "naive" || (disableAllExceptActive && type !== activeTab);
      return (
        <div
          key={uuidv4()}
          onClick={() => {
            if (disabled) {
              return null;
            }
            handleSetActiveTab(type);
          }}
          className={`LanguageSwitcher-item center ${
            activeTab === type && "active"
          } ${disabled && "disabled"}`}
        >
          {typeTitles[type]}
        </div>
      );
    });
  };
  return <div className="LanguageSwitcher row">{renderTypes()}</div>;
};

export default LanguageSwitcher;
