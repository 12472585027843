import action from "../middleware";
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";
import isEmpty from "lodash/isEmpty";
import { EMPTY_CI_WORKFLOWS_AGGS_FILTERS } from "../../consts/general";
import isEqual from "lodash/isEqual";
import { checkIfShouldUpdateOnlyTotalAgg } from "../../utils/workflowsHelper";

export const GET_WORKFLOWS_AGGS = "GET_WORKFLOWS_AGGS";
export const HANDLE_SET_CI_FILTERS = "HANDLE_SET_CI_FILTERS";
export const HANLDE_SET_CI_SORTING = "HANLDE_SET_CI_SORTING";
export const GET_CODE_FILES = "GET_CODE_FILES";
export const GET_IAC_TYPES_VERSIONS = "GET_IAC_TYPES_VERSIONS";
export const GET_DIAGNOSTIC_REMEDIATION = "GET_DIAGNOSTIC_REMEDIATION";
export const GET_INIT_LOG_DATA = "GET_INIT_LOG_DATA";
export const CLEAR_RUN_LOG_DATA = "CLEAR_RUN_LOG_DATA";
export const GET_CODE_SCAN_REMEDIATION = "GET_CODE_SCAN_REMEDIATION";
export const GET_PLAN_SCAN_REMEDIATION = "GET_PLAN_SCAN_REMEDIATION"; 
export const GET_GUARDRAILS_SCAN_REMEDIATION = "GET_GUARDRAILS_SCAN_REMEDIATION";

let abortWorkspacesController;
const getCiWorkspaces = (ciFilters, searchValue, ciSorting, pageNumber, pageSize) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const payload = { filters: ciFilters, searchValue, offset: pageNumber * pageSize, size: pageSize };
    if (!isEmpty(ciSorting)) {
      payload.sort = ciSorting;
    }
    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/ci/workspaces`,
        "POST",
        payload,
        undefined,
        undefined,
        true,
        null,
        abortWorkspacesController.signal
      );
      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      return data;
    }
    catch (err) {
      if (err.name === 'AbortError') {
        return { abort: true }
      }
      return {};
    }
  });
}

const getTasksByWorkspaceId = (workflowId, payload = {}) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workspaces/${workflowId}/runs`,
      "POST",
      payload,
      true,
      null,
      false,
    );
    let data = null;
    if (req?.ok) {
      data = await req.json();
    }
    return data;


  });
}

const getWorkflowResources = (runId, options = {}) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/tasks/${runId}/resources/list`,
      "POST",
      options,
    );
    let data = null;
    if (req?.ok) {
      data = await req.json();
    }
    return data;
  })
};

const getWorkflowsAggs = (searchValue, ciFilters, fieldsToGroup) => {
  const isEmptyFilters = isEqual(ciFilters, EMPTY_CI_WORKFLOWS_AGGS_FILTERS);
  const filters = isEmptyFilters ? {} : ciFilters;
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workspaces/aggs`,
      "POST",
      { searchValue, filters, fieldsToGroup },
      true,
      null,
      false,
    );
    let data = {};
    
    if (req?.ok) {
      data = await req.json();
    }
    const shouldUpdateOnlyTotal = isEmptyFilters ? false : checkIfShouldUpdateOnlyTotalAgg(ciFilters); 
    dispatch({
      type: GET_WORKFLOWS_AGGS,
      payload: { shouldUpdateOnlyTotal, ...data }
    });
    return;
  })
};

const handleSetCiFilters = (filters) => {
  return action(async (dispatch) => {
    dispatch({
      type: HANDLE_SET_CI_FILTERS,
      payload: filters,
    });
  });
};
const handleSetCiSorting = (sorting) => {
  return action(async (dispatch) => {
    dispatch({
      type: HANLDE_SET_CI_SORTING,
      payload: sorting,
    });
  });
};

const getCodeFiles = (codeId, options = {}) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
          `${BACKEND_URL}/ci/tasks/${codeId}/changed-files-code/list`,
          "POST",
          options,
        );
        let data = {};
        if (req?.ok) {
         data = await req.json();
        }
        dispatch({
            type: GET_CODE_FILES,
            payload: data,
        });
        return data;
    })
};

const isWorkflowExists = ({ workspaceName, runnerType, repo }) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workflows/exists`,
      "POST",
      { workspaceName, runnerType, repo },
      true,
      null,
      false,
    );
    let res = true;
    if (req?.ok) {
      res = await req.json();
    }
    return res
  });
}

const addNewWorkflow = (data, isReviewRequest) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const url = isReviewRequest ? `${BACKEND_URL}/ci/workflows/code` : `${BACKEND_URL}/ci/workflows`;
    const req = await requestWrapper.sendRequest(
      url,
      "POST",
      data,
      true,
      null,
      false,
      null,
      null,
      true
    );
    let res = null;
    if (req?.ok || req?.status === 403) {
      const json = await req.json() || {};
      res = req.status === 403 ? { status: 403, message: json?.message } : json;
    }
    return res;
  });
};

const editWorkflow = (workflowId, data, isReviewRequest) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const method = isReviewRequest ? "POST" : "PUT";
    const url = isReviewRequest ? `${BACKEND_URL}/ci/workflows/code` : `${BACKEND_URL}/ci/workflows/${workflowId}`;
    const req = await requestWrapper.sendRequest(
      url,
      method,
      data,
      true,
      null,
      false,
    );
    let res = null;
    if (req?.ok) {
      res = await req.json();
    }
    return res;
  });
};

const deleteWorkflow = (workflowId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workspaces/${workflowId}`,
      "DELETE",
      null,
      true,
      null,
      false,
      true,
    );
    let res = null;
    if (req?.ok) {
      res = res?.status === 207 ? { error207: true } : await req.json();
    }
    return res;
  });
};

const getWorkflowConfiguration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workflows/${id}`,
      "GET",
      null,
      true,
      null,
      false,
    );
    let res = null;
    if (req?.ok) {
      res = await req.json();
    }
    return res;
  });
};

const getCostPolicyData = (runId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/tasks/${runId}/cost-policy`,
      "GET",
    );
    let data = null;
    if (req?.ok) {
      data = await req.json();
    }
    return data;
  })
};

const getIacTypesVersions = (iacType) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workflows/supported-iac-versions/${iacType}`,
      "GET",
    );
    let data = [];
    if (req?.ok) {
      data = await req.json();
    }

    dispatch({
      type: GET_IAC_TYPES_VERSIONS,
      payload: { iacType, value: data?.availableVersions  || [] },
    });
    
  })
};
const setWorkspaceLabels = (workspaceId, labels = []) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workspaces/${workspaceId}/labels`,
      "PUT",
      { labels },
      true,
      null,
      false,
    );
    let res = null;
    if (req?.ok) {
      res = await req.json();
    }
    return res;
  });
}
const getDiagnosticRemediationById = ({ diagnosticId, ...rest }) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/ci/remediate-diagnostic`,
        "POST",
        { diagnosticId, ...rest },
        true,
        null,
        false
      );
    let data = null;
    const isReqOk = req?.ok;
    if (isReqOk) {
      data = await req.json();
      dispatch({
        type: GET_DIAGNOSTIC_REMEDIATION,
        payload: { diagnosticId, data },
      });
    }
    return isReqOk;
  })
}
const getInitLogData = (taskId, step, format) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/tasks/${taskId}/logs?command=${step}&format=${format}`,
      "GET",
      
    );
    let data = "";
    if (req?.ok) {
      data = await req.json();
    }
    dispatch({
      type: GET_INIT_LOG_DATA,
      payload: { data, step }
    });
    return data;
  });
}

const clearRunLogData = () => action(async (dispatch) => dispatch({ type: CLEAR_RUN_LOG_DATA }));
const getCodeOrPlanScanRemediation = (payload, taskId = "", isPlan = false, isGuardrails = false) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/tasks/${taskId}/remediate-violations`,
      "POST",
      payload,
      true,
      null,
      false
    );
    let data = {};
    if (req?.ok) {
      data = await req.json();
    }
    const ids = isGuardrails ? payload.guardrailsViolations : isPlan ? Object.keys(payload?.planScans) : payload?.codeScans;
    dispatch({
      type: isGuardrails ? GET_GUARDRAILS_SCAN_REMEDIATION : isPlan ? GET_PLAN_SCAN_REMEDIATION : GET_CODE_SCAN_REMEDIATION,
      payload: { data , ids, isPlan, isGuardrails },
    });
    return req.ok;
  });
}
export {
    getCiWorkspaces,
    getTasksByWorkspaceId,
    getWorkflowResources,
    getWorkflowsAggs,
    handleSetCiFilters,
    getCodeFiles,
    addNewWorkflow,
    isWorkflowExists,
    editWorkflow,
    getWorkflowConfiguration,
    getCostPolicyData,
    handleSetCiSorting,
    deleteWorkflow,
    getIacTypesVersions,
    setWorkspaceLabels,
    getDiagnosticRemediationById,
    getInitLogData,
    clearRunLogData,
    getCodeOrPlanScanRemediation,
};