import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import animationDataDark from "./gradiDark.json";
import animationDataLight from "./gradiLight.json";
import "./lottieLoadingBar.scss";
import { useTranslation } from "react-i18next";

const LottieLoadingBar = ({ isIntegrationScreen = false }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const { t } = useTranslation("on-boarding");

  const size = isIntegrationScreen ? 150 : 280;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: themeDark ? animationDataDark : animationDataLight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      className: "LottieLoading-animation",
    },
  };

  return (
    <div className="col">
      <div className={`LottieLoading ${themeDark ? "dark" : "light"}`}>
        <Lottie
          options={defaultOptions}
          isStopped={false}
          isPaused={false}
          height={size}
          width={size}
        />
      </div>
      {!isIntegrationScreen && (
        <div>
          <p className="InventoryCharts__text text center font-12">
            {t("initial-scanning-bar-sub-1")}
          </p>
          <p className="text center font-12">
            {t("initial-scanning-bar-sub-2")}
          </p>
        </div>
      )}
    </div>
  );
};

export default LottieLoadingBar;
