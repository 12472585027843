import { defaultInventoryColumnVisibilityModel } from "../../consts/inventory";
import {
  TOGGLE_THEME,
  TOGGLE_SIDEBAR,
  GET_PREFERENCES,
  TOGGLE_SETTINGS,
  CHANGE_TABLE_PAGE_SIZE,
  TOGGLE_INVENTORY_MODE,
  UPDATE_INVENTORY_COLUMN_VISIBILITY_MODEL,
  UPDATE_USER_PREFERENCE,
  TOGGLE_GUIDE_PLAYLIST,
} from "../actions/userPreferencesActions";
import {forEach} from "lodash";
import has from "lodash/has";

const initialState = {
  themeDark: true,
  sidebarClosed: false,
  settingsOpen: false,
  tablePageSize: 50,
  inventory_mode: "assets",
  workflowsFiltersOpen: false,
  onBoardingProgressBarHide: false,
  showGuidePlaylist: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PREFERENCES:
      let columns = payload?.inventory_columnVisibilityModel ?? defaultInventoryColumnVisibilityModel
      forEach(defaultInventoryColumnVisibilityModel, (value, key) => {
        if (!has(columns, key)) {
          columns[key] = defaultInventoryColumnVisibilityModel[key];
        }
      });

      return {
        ...state,
        themeDark: payload?.themeDark,
        sidebarClosed: payload?.sidebarClosed,
        tablePageSize: payload?.tablePageSize,
        inventory_mode: payload?.inventory_mode ?? 'assets',
        inventory_columnVisibilityModel: payload?.inventory_columnVisibilityModel ?? defaultInventoryColumnVisibilityModel,
        workflowsFiltersOpen: payload?.workflowsFiltersOpen || false,
        isSandboxStatus: payload?.isSandboxStatus,
        onBoardingProgressBarHide: payload?.onBoardingProgressBarHide || false,
      };

    case TOGGLE_THEME:
      return { ...state, themeDark: !state.themeDark };

    case TOGGLE_SIDEBAR:
      return { ...state, sidebarClosed: !state.sidebarClosed };

    case TOGGLE_SETTINGS:
      return { ...state, settingsOpen: payload.bool };

    case CHANGE_TABLE_PAGE_SIZE:
      return { ...state, tablePageSize: payload };

    case TOGGLE_INVENTORY_MODE:
      return { ...state, inventory_mode: payload };
    case UPDATE_INVENTORY_COLUMN_VISIBILITY_MODEL:
      return { ...state, inventory_columnVisibilityModel: payload };
    case UPDATE_USER_PREFERENCE: {
      return { ...state, [payload.field]: payload.value };
    }
    case TOGGLE_GUIDE_PLAYLIST:
      return { ...state, showGuidePlaylist: !state.showGuidePlaylist };
    default:
      return state;
  }
}
