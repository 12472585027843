export const dashboardEvents = {
    taggingCoverage: "clicked tagging coverage",
    clickedOnConnectedServices: "dashboard clicked on connected services",
    connectedServiceClickedOnService: "connected services clicked on service",
    clickedOnClickHere: "clicked on click here",
};

export const inventoryEvents = {
    search: "Inventory Search",
    codifyDrawerOpen: "Inventory Codify",
    driftDrawerOpen: "Inventory Drift",
    driftDrawerFooterBtnClick: "Drift Drawer",
    export: "Inventory Export Table Click",
    createIssue: "Inventory Create Issue Click",
    deleteAsset: "Inventory Delete Asset Click",
    generateIac: "Inventory Generate IaC Asset Click",
    jumpToCode: "Inventory Jump to Code Asset Click",
    jumpToConsole: "Inventory Jump to Console Asset Click",
    driftButton: "Inventory drift details Asset Click",
    removeAssetButton: "Remove Asset Code Click",
    infoDrawerClick: "Asset Data Drawer Click",
    commentActions: "Comments Action",
    infoDrawerTabClick: "Info Drawer Tab Click",
    tfImportCmdDrawerOpen: "Terraform Import Drawer Open",
    pullRequestDrawerOpen: "Pull Request Drawer Click",
    createPullRequest: "clicked create pull request",
    inventoryScreenMode: "Inventory screen mode changed",
    inventoryTypeBlockClicked: "Inventory Type Block Click",
    nestedChildrenClick: "Inventory View relationship Click",
    assetClick: "clicked inventory asset",
    clickedDriftDetails: "clicked drift details",
    clickedDriftDetailsViewButton: "clicked view button (drift details)",
    downloadedDriftDetails: "downloaded drift details",
    copiedDriftDetails: "copied drift details",
    clickedShowMoreDriftDetails: "clicked show more drift details",
    clickedCreatePrDriftDetailsButton: "clicked create pull request button (drift details)",
    clickedDriftDetailsPrLink: "clicked pull request link (drift details)",
    collapsedAlignIacAsset: "collapsed align iac asset",
    excludeDrift: "excluded drift",
    clickedExcludeDriftButton: "clicked exclude drift button",
    clickedGroupedFilterTiles: "clicked inventory grouped filter tiles",
    clickedAssetStateFilter: "clicked asset state filter",
    clickedMutationsRowFlagFilter: "clicked mutations row flag filter",
    clickedCommentsRowFlagFilter: "clicked comments row flag filter",
    clickedInsightsRowFlagFilter: "clicked insights row flag filter",
    clickedRelationshipsRowFlagFilter: "clicked relationships row flag filter",
    clickedVcsRowFlagFilter: "clicked vcs row flag filter",
};

export const generalEvents = {
    startSession: "session start",
    pageClick: "Page Click",
    drakMode: "Dark Mode",
    intercomOpen: "Intercom Click",
    sidebar: "Sidebar Toggled",
    jiraCreateIssue: "clicked create jira issue",
    organizationChange: "Organiztion Changed",
    tablePageSizeSelection: "Table Page Size Selection",
    eventViewerEventExpansion: "Event Viewer Event Expansion",
    clickedFireflyPlaylistOpen: "clicked tutorials list pop-up",
    clickedFireflyPlaylistVideo: "clicked tutorial video",
};

export const composeEvents = {
    generateModuleCallSubmit: "Generate module call submit",
    generateModuleCallTab: "generate module call tab",
    generateModuleCallPR: "generate module call pr",
    provisionRequest: "Provision Request",
};

export const onboardingEvents = {
    integrationClick: "Onboarding Integration Click",
    onboardingDone: "Onboarding Done",
    onboardingStepProgress: "Onboarding user moved to step",
};

export const iacEvents = {
    iacTypeSelected: "Iac Type Selected",
    filterSelected: "Iac Explorer Filter Selected",
    search: "Iac Explorer Search",
    categorySelected: "Iac Explorer Category Selected",
    export: "IaC Stacks Export Table Click",
};

export const integrationEvents = {
    azureIntegrationCreation: "Azure integration created",
    azureIntegrationEdit: "Azure integration edited",
    sendTestNotification: "Send Test Notification",
    selectedIntegrationMethodForProvider: "selected integration method for provider",
    taggingCoverage: "clicked tagging coverage",
    integrationClickedOnService: "integration clicked on connected services",
};

export const CiEvents = {
    workflowCollapseClick: "clicked workflow collapse",
    prRowClick: "clicked workspace run",
    ciRunResourceClick: "clicked workflows ci run resource",
    ciRunResourceTabClick: "clicked workflows horizontal tab ci run resource",
    ciRunResourceCopyClick: "copied workflows ci run resource",
    ciSharedWorkflowsLink: "shared workflows link",
    ciClickedWorkflowStepTab: "clicked workflows step tab",
    ciCompletePullRequestLinkClick: "clicked workflows link to external environment",
    ciAdvancedConfigurationNextClick: "clicked workflows next button",
    ciSearchText: "searched text via workflows main horizontal panel",
    ciClickAddNewWorkflow: "clicked add new workflow",
    ciClickedFilterToggle: "clicked workflows vertical filter toggle",
    ciSearchTextFromFilter: "searched text via workflows vertical category filter",
    ciFilterCollapseClick: "clicked workflows vertical category filter",
    ciWorkspaceConfirmedDeletion: "confirmed workspace deletion",
    ciClickedSetWorkspaceLabel: "clicked set workspace label",
    clickedRemdiationDiagnostic: "clicked remediation diagnostic (workflows)",
    changedViewMode: "changed view mode",
    openSummaryConfig: "opened summary drawer",
};

export const assetHistoryEvents = {
    selectedRevision: "selected revision timestamp",
    clickedNavigation: "clicked navigation timeline arrow",
    openCelendar: "open calendar",
};

export const guardrailsEvents = {
    clickedAddGuradrail: "clicked add new guardrails rule",
    filterSelected: "filtered via guardrails main horizontal pane",
    searchText: "searched text via guardrails main horizontal pane",
    guardrailsStepCollapse: "clicked guardrails step rule collapse",
};

export const governanceEvents = {
    frameworks: "clicked governance vertical filter - frameworks",
    categories: "clicked governance vertical filter - categories",
    providers: "clicked governance vertical filter - providers",
    dataSources: "clicked governance vertical filter - data sources",
    scopes: "clicked governance vertical filter - scopes",
    severities: "clicked governance vertical filter - severities",
    policy: "clicked governance vertical filter - policy type filter",
    searchMainPanel: "searched text via governance main horizontal panel",
    verticalFilter: "clicked governance vertical filter - toggles filters",
    collapseFilter: "clicked governance vertical filter - collapse filters",
    textSearched: "searched text via governance vertical filter",
    violatingAssets: "clicked governance violated assets - inventory link",
    createCustomPolicy: "clicked governance Create Custom Policy",
};

export const codifyEvents = {
    generatedIac: "generated iac",
    downloadCodification: "downloaded iac codification code",
    copyCodification: "copied iac codification code",
    copyTfImportCmd: "copied terraform import commands",
    exportedTfImportCmd: "exported terraform import commands",
    clickedEditCodification: "clicked codification edit code",
    clickedSaveEditCodification: "clicked codification save edit code",
    clickedCancelEditCodification: "clicked codification cancel edit code",
    clickedCodifyEditExitWithoutSaving: "clicked codification exit without saving",
    clickedCodifyEditCancelWithouSaving: "clicked codify edit cancel exit without saving",
    clickedCodificationAddNewFile: "clicked codification add new file",
    clickedCodificationDeleteFile: "clicked codification delete file",
    clickedCodificationRenameFile: "clicked codification rename file",
};

export const aiRemediationEvents = {
    action: "clicked governance action",
    tiles: "clicked AI remediation tiles",
    reviewFix: "clicked AI remediation review fix",
    createPullRequest: "clicked AI remediation Create PR",
    cost: "cost",
    remediate: "remediate",
    clickedWorkflowPolicyViolations: "clicked workflow policy violation remediation",
    clickedWorkflowPolicyViolationsAll: "clicked workflow policy violation all remediation",
    clickedWorkflowPolicyGuardrailsViolation : "clicked workflow guardrail violation remediation"
};
