import { ReactComponent as GitLab } from "../../../../Images/integrations/gitlab.svg";
import { ReactComponent as GitHubDark } from "../../../../Images/integrations/githubDark.svg";
import { ReactComponent as Bitbucket } from "../../../../Images/integrations/bitbucket.svg";
import { ReactComponent as GitHub } from "../../../../Images/integrations/github.svg";

export const formatUrl = (url) => {
    const urlParts = url.replace(/https?:\/\/[^\/]+/, '').split('/').filter(part => part);
    const firstPart = urlParts.shift();
    const formattedUrl = [firstPart, ...urlParts.map(part => `<u>${part}</u>`)].join(' / ');
    return formattedUrl;
  };

  export const getIcon = (vcsType, darkMode) => {
    switch (vcsType) {
      case "gitlab":
        return <GitLab />;
      case "github":
        return darkMode ? <GitHubDark />: <GitHub />;
      case "bitbucketdc":
        return <Bitbucket />;
      default:
        return null;
    }
  };