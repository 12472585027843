import React, { useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import L from "leaflet";
import { clouds } from "../../../utils/icons";
import { getMapData } from "../../../redux/actions/dashboardActions";
import PercentageBar from "../../../shared/percentageBar/percentageBar";
import ReactDOM from "react-dom";
import AppPopoverSelect from "../../../shared/appPopoverSelect/appPopoverSelect";
import azureIcon from "../../../Images/clouds/Azure.svg";
import gcpIcon from "../../../Images/clouds/Google.svg";
import awsIconDark from "../../../Images/clouds/AWSDark.svg";
import awsIconWhite from "../../..//Images/clouds/AWS.svg";
import { Provider } from "react-redux";
import store from "../../../store";

import "leaflet/dist/leaflet.css";
import "./mapComponent.scss";

const providerTypes = [
    { name: "AWS", value: "aws" },
    { name: "GCP", value: "gcp" },
    { name: "AzureRM", value: "azurerm" },
];

const MapComponent = () => {
    const mapContainer = useRef(null);
    const [map, setMap] = useState(null);
    const [filters, setFilters] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const providersData = useSelector((state) => state.dashboardReducer.providersData);


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (isEmpty(providersData)) {
            return;
        }
        const map = createMap();
        const mapType = themeDark ? "dark_all" : "light_all";
        const url = `https://{s}.basemaps.cartocdn.com/${mapType}/{z}/{x}/{y}{r}.png`
        
        L.tileLayer(url, { opacity: 0.8 }).addTo(map);
        return () => {
            map.remove();
        };
    }, [providersData, themeDark]);

    useEffect(() => {
        if (!map) {
            return;
        }
        const layerGroup = L.layerGroup().addTo(map);

        const filteredData = isEmpty(filters) ? providersData : providersData.filter((provider) => filters.includes(provider.type));
        filteredData.forEach((provider) => {
            const { type = "", regions = [] } = provider;
            const icon = getProviderTypeIcon(type);
            regions.forEach((region = {}) => {
                const marker = L.marker(region.coordinates, { icon }).addTo(layerGroup);
                const popupContent = createMarker(region);
                marker.bindPopup(popupContent);
            });
        });

        setTimeout(() => addLoadedClass() , 200);

        return () => {
            layerGroup.clearLayers();
        };
        
    }, [map, filters]);

    const addLoadedClass = () => document?.getElementsByClassName("mapComponent__mapContainer")[0]?.classList?.add("loaded");

    const fetchData = async () => {
        if (isEmpty(providersData)) {
            await dispatch(getMapData());
        }
    };

    const createMap = () => {
        const map = L.map(mapContainer.current, {
            attributionControl: false,
            zoomControl: false,
            center: [51.505, -0.09],
            zoom: 2,
            minZoom: 2,
            maxZoom: 10,
        });
        const bounds = L.latLngBounds(L.latLng(-53, -170), L.latLng(80, 170));

        map.setMaxBounds(bounds);
        map.on("drag", () => {
            map.panInsideBounds(bounds, { animate: false })
        });
        setMap(map);
        return map;
    };

    const iacCoverageBar = (states) => {
        const total = states?.total;
        if (total === 0) {
            return null;
        }
        return (
            <div className="mapComponent__bar-wrapper">
                <div className="mapComponent__bar-wrapper__header">IaC Coverage:</div>
                <ul className="mapComponent__coverage__progress">
                    {Object.keys(states).map((item) => {
                        if (item === "total") {
                            return null;
                        }
                        return (
                            <li
                                key={item}
                                className={item}
                                style={{
                                    width: `${(states[item] / total) * 100}%`,
                                }}
                            ></li>
                        );
                    })}
                </ul>
            </div>
        );
    };

    const getTaggingCovergeContent = (taggingStatus) => {
        if (taggingStatus?.total === 0) {
            return null;
        }
        return (
            <div className="mapComponent__bar-wrapper">
                <div className="mapComponent__bar-wrapper__header">Tagging Coverage:</div>
                <PercentageBar
                    count={taggingStatus?.count}
                    total={taggingStatus?.total}
                    height="12px"
                    tooltipTitle={`Tagging Status: ${taggingStatus?.count} / ${taggingStatus?.total}`}
                    width="150px"
                    useLabel={true}
                />
            </div>
        );
    };

    const getProviderTypeIcon = (providerType) => {
        let providerIcon = awsIconWhite;
        if (themeDark) {
            providerIcon = awsIconDark;
        }

        if (providerType === "gcp") {
            providerIcon = gcpIcon;
        } else if (providerType === "azurerm") {
            providerIcon = azureIcon;
        }
        const icon = L.icon({
            iconUrl: providerIcon,
            iconSize: [22, 22],
            iconAnchor: [20, 40],
            popupAnchor: [0, -40],
        });

        return icon;
    };

    const onRegionClick = (region) => {
        history.push({
            pathname: `/inventory`,
            search: `?region=${region}`,
        });
    };

    const createMarker = (region) => {
        const popupContentContainer = document.createElement("div");
        const popupContent = (
            <div className="mapComponent__popup-container">
                <div className="mapComponent__coverage__wrapper-name" onClick={() => onRegionClick(region?.code)}>
                    <div>{region?.code}</div>-<div>{region?.name}</div>
                </div>
                <div className="mapComponent__coverage__count">
                    <div className="mapComponent__coverage__count__text">Total Resources:</div>
                    <div className="mapComponent__coverage__count__value">{addCommaToNumber(region?.count)}</div>
                </div>
                <div className="mapComponent__coverage__wrapper">
                    <Provider store={store}>
                        {iacCoverageBar(region?.states)}
                        {getTaggingCovergeContent(region?.taggingStatus)}
                    </Provider>
                </div>
            </div>
        );

        ReactDOM.render(popupContent, popupContentContainer);
        return popupContentContainer;
    };

    const addCommaToNumber = (number) => {
        return new Intl.NumberFormat("en-US").format(number);
    };

    const createFilters = () => {
        return <div className="mapComponent__filter-wrapper">{providerFilters()}</div>;
    };

    const filterProvidersWithoutData = () => {
        const filters = [];
        for (const provider of providersData) {
            for (const type of providerTypes) {
                if (provider?.type === type?.value) {
                    filters.push(type);
                    break;
                }
            }
        }
        return filters;
    };

    const providerFilters = () => {
        const providerTypeFilter = filterProvidersWithoutData();
        return (
            <div className="mapComponent__filter-wrapper__provider-filter">
                <AppPopoverSelect
                    label={"Providers"}
                    options={providerTypeFilter.map((provider) => {
                        return {
                            label: (
                                <div className="mapComponent__select-label" key={provider.name}>
                                    <img src={clouds(provider.value, themeDark)} />
                                </div>
                            ),
                            value: provider.value,
                        };
                    })}
                    checkedOptions={filters || []}
                    onSubmit={(selected) => setFilters([...selected])}
                />
            </div>
        );
    };

    return (
        <div ref={mapContainer} className="mapComponent__mapContainer">
            {/* {createFilters()} */}
        </div>
    );
};

export default MapComponent;
