import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "antd/lib/tooltip";
import "./percentageBar.scss";

const PercentageBar = ({ count = 0, total = 0, tooltipTitle = "", height = "35%", width = "100%", useLabel = true }) => {
    const isThemeDark = useSelector((state) => state?.userPreferencesReducer?.themeDark);

    const getLabel = (percentage) => {
        if (typeof percentage !== "number" || isNaN(percentage)) {
            percentage = 0;
        }
        return `${Math.floor(percentage)}%`;
    };

    const getSize = (label) => {
        let size;
        switch (label) {
            case "1%":
                size = "one";
                break;
            case "2%":
                size = "two";
                break;
            case "3%":
                size = "three";
                break;
            case "4%":
                size = "four";
                break;
            case "98%":
            case "99%":
            case "100%":
                size = "hundred";
                break;
            default:
                break;
        }
        return size;
    };

    const percentage = count > 0 && total > 0 ? (count / total) * 100 : 0;
    const theme = isThemeDark ? "dark" : "light";
    const label = getLabel(percentage);
    const size = getSize(label);

    return (
        <Tooltip
            overlayClassName="PercentageBar-Tooltip"
            title={
                <span>
                    {" "}
                    {tooltipTitle} <br /> {`${count} / ${total}`}
                </span>
            }
        >
            <div className="PercentageBar" style={{ width: width }}>
                {useLabel ?? <span className="PercentageBar__progress-label">{label}</span>}
                <div className={`PercentageBar__progress-container ${theme}`} style={{ height: height }}>
                    <div className={`PercentageBar__progress-bar ${size}`} style={{ width: label }}></div>
                </div>
            </div>
        </Tooltip>
    );
};

export default PercentageBar;
