import LogRocket from "logrocket";
import { cutDomainFromEmail, getSubdomain } from "./helpers";
import { REB2B_KEY } from "../consts/config";

const FIREFLIES = ["gofirefly", "infralight", "firefly"];
const FIREFLIES_DEV = ["app-env1", "app-env2", "app-env3"];
const sensitiveKeywords = ["token", "secret", "password", "apiKey", "apiToken", "apiSecret"];

export const initLogRocket = async (user = {}) => {
    const subDomain = getSubdomain();
    const isLocal = subDomain?.includes("localhost");
    const recordLogRocket = !isLocal && !FIREFLIES.includes(subDomain);
    const company = await cutDomainFromEmail(user.email);
    let logRocketProject = "duddjo/firefly";
    if (!recordLogRocket) {
        return;
    }
    if (FIREFLIES_DEV.includes(subDomain)) {
        logRocketProject = "duddjo/firefly-dev";
    }

    LogRocket.init(logRocketProject, {
        network: {
            requestSanitizer: (request = {}) => {
                request.headers = sanitizeHeaders(request.headers);
                request.body = sanitizeBody(request.body);
                return request;
            },
            responseSanitizer: (response = {}) => {
                response.headers = sanitizeHeaders(response.headers);
                response.body = sanitizeBody(response.body);
                return response;
            },
        },
    });
    LogRocket.identify(user.sub, {
        name: user.name,
        email: user.email,
        company,
    });
};

const sanitizeHeaders = (headers = {}) => {
    headers.Authorization = null;
    headers.authorization = null;
    return headers;
};

const sanitizeBody = (body) => {
    if (sensitiveKeywords.some((keyword) => body?.includes(keyword))) {
        return null;
    }
    return body;
};

export const setReb2bScript = () => {
    !(function () {
        var reb2b = (window.reb2b = window.reb2b || []);
        if (reb2b.invoked) return;
        reb2b.invoked = true;
        reb2b.methods = ["identify", "collect"];
        reb2b.factory = function (method) {
            return function () {
                var args = Array.prototype.slice.call(arguments);
                args.unshift(method);
                reb2b.push(args);
                return reb2b;
            };
        };
        for (var i = 0; i < reb2b.methods.length; i++) {
            var key = reb2b.methods[i];
            reb2b[key] = reb2b.factory(key);
        }
        reb2b.load = function (key) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
            var first = document.getElementsByTagName("script")[0];
            first.parentNode.insertBefore(script, first);
        };
        reb2b.SNIPPET_VERSION = "1.0.1";
        reb2b.load(REB2B_KEY);
    })();
};
