import React from "react";
import { ReactComponent as RemediationIcon } from "../../../Images/general_icons/remediation.svg";
import { Tooltip } from "antd";

import SpinnerLoader from "../../../shared/spinnerLoader/spinnerLoader";
import "./remediationAllBtn.scss";

const RemediationAllBtn = ({ t, handleRemidiationAllDiagnostics, customBtnTitle = "", loading = false }) => {
  return (
    <Tooltip title={customBtnTitle ? "" : t("diagnosticRemediation.btnAll")}>
      <span className={`RemediationAllBtn ${loading ? "loading" : ""} row g5`} onClick={handleRemidiationAllDiagnostics}>
        {loading ? <SpinnerLoader customStyle={{ color: "#9872FE"}} />
        : <RemediationIcon className="RemediationAllBtn-icon" />}
          <span className="RemediationAllBtn-text">
            {customBtnTitle || t("diagnosticRemediation.btnAll")}
          </span>
      </span>
    </Tooltip>
  );
};

export default RemediationAllBtn;
