import React, { useEffect, useState } from "react";
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirst } from "../../../../../utils/formatting";
import DarkCopyBox from "../../../../../shared/darkCopyBox/darkCopyBox";
import { ReactComponent as RemediationIcon } from "../../../../../Images/general_icons/remediation.svg";

import { useDispatch, useSelector } from "react-redux";
import { getDiagnosticRemediationById } from "../../../../../redux/actions/ciWorkflowsActions";
import { useAuth0 } from "@auth0/auth0-react";
import { appToast } from "../../../../../shared/appToast/appToast";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

import { sendEvent } from "../../../../../utils/amplitude";
import { CiEvents } from "../../../../../utils/amplitudeEvents";
import RemediationBlock from "../../remediationBlock/remediationBlock";
import './diagnostic.scss';

const Diagnostic = ({ item = {}, withHeaderBg = true, taskId = "", openRemediationByDefault = false }) => {
    const [isRemediationOpen, setIsRemediateOpen] = useState(false);
    const [remediationLoading, setRemedationLoading] = useState(false);
    const dispatch = useDispatch();
    const { user } = useAuth0();
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "diagnosticRemediation" })

    const { id = '',  severity = '', summary = "", range = {}, snippet = {}, detail = "", diagnosticType = "" } = item;
    const { filename = "", start = {} } = range;
    const { context = "", code = "", } = snippet;
    const { line: startLine = 0 } = start;
    const severLower = severity.toLowerCase();
    const severityTitle = capitalizeFirst(severLower);
    const contextAddition = context ? ` in ${context}` : "";
    const openingLine = `on ${filename} line ${startLine}${contextAddition}:`;
    const withHeaderBgColorBySeverity = withHeaderBg ? severLower: "";

    const diagnosticsRemediationById = useSelector((state) => state.ciWorkflowsReducer.diagnosticsRemediationById) || {};
    const diagnosticRemediation = diagnosticsRemediationById[id] || {};
    const isDiagnosticRemediation = !isEmpty(diagnosticRemediation);

    useEffect(() => {
        if (openRemediationByDefault && (["error", "warning"].includes(severLower))) {
            setIsRemediateOpen(true);
            fetchRemediateAI();
        }
    }, [openRemediationByDefault]);

    const fetchRemediateAI = async () => {
        if (isDiagnosticRemediation) return;
        setRemedationLoading(true);
        const summaryAndDetail = summary + " " + detail;
        const payload = { diagnosticId: id, diagnosticType, diagnosticDetails: summaryAndDetail, taskId,
            codeStartLine: startLine?.toString(), diagnosticCodeSnippet: code, diagnosticFilePath: filename, userEmail: user.email }; 
        const resOk = await dispatch(getDiagnosticRemediationById(payload));
        if (!resOk) {
            appToast("error", t("error.title"), t("error.subtitle"));
            setIsRemediateOpen(false);
        }
        setRemedationLoading(false);
    };

    const onRemediateClick = () => {
        const showRemediation = !isRemediationOpen;
        setIsRemediateOpen(showRemediation);
        if (showRemediation) {
            fetchRemediateAI();
            sendEvent(CiEvents.clickedRemdiationDiagnostic, { taskId, name: summary });
        }
    }
    return (
        <>
        <div className="Diagnostic col" key={id}>
            <div className={`Diagnostic__header ${withHeaderBgColorBySeverity} row between`}>
                <div className="row g8">
                    <Tooltip title={severityTitle}>
                        <FontAwesomeIcon icon="exclamation" className={`Diagnostic__icon ${severLower}`}/>
                    </Tooltip>
                    <span className="Diagnostic__header-title">{summary}</span>
                </div>
                <div className="row g8">
                    <Tooltip title={t("thinkerTooltip")}>
                        <div className={`CiCode__content-collapse-content-policyViolations-item-remediateIcon ${isRemediationOpen ? "clicked" : ""} row g5`} onClick={onRemediateClick}>
                            <RemediationIcon/>
                            <span>Remediate</span>
                       </div>
                    </Tooltip>
                </div>
            </div>
            <div className={`Diagnostic__body ${isRemediationOpen ? 'withRemediate' : ''} col g5`}>
                {startLine != 0  ? <span className="Diagnostic__text">{openingLine}</span> : null }
                {code ? <DarkCopyBox text={code}/> : null}
                <span className="Diagnostic__text">{detail}</span>
            </div>
        </div>
        {isRemediationOpen && <RemediationBlock data={diagnosticRemediation} remediationLoading={remediationLoading} isDiagnosticRemediation={isDiagnosticRemediation} setIsRemediateOpen={setIsRemediateOpen} isFromExpandedBox loadingText="Generating a diagnostic remediation" />}
        </>)


}

export default Diagnostic;