import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { TablePagination } from "@mui/material";
import "./aiRemediationVc.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { clearAiRemediationData, getAiRemediationCloud } from "../../../../redux/actions/aiRemediationActions";
import Loading from "../../../../shared/loading/loading";
import AiRemediationCliCollapse from "./aiRemediationCliCollapse";
import { clouds } from "../../../../utils/icons";
import HeaderSearchBox from "../../../../shared/headerSearchBox/headerSearchBox";
import FilterTag from "../../insightsHeader/insightFilterBadges/filterTag";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";

const AiRemediationProvider = ({ data }) => {
    const [activeKey, setActiveKey] = useState(null);
    const [page, setTablePage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [activeKeyCollapse, setActiveKeyCollapse] = useState({});
    const [searchValue, setSearchValue] = useState("");

    const dispatch = useDispatch();

    const cloudData = useSelector((state) => state.aiRemediationReducer?.cloud || {});

    const afterKey = useSelector((state) => state.aiRemediationReducer?.afterKeyCloud || {});

    const totalFiles = useSelector((state) => state.aiRemediationReducer?.totalFilesCloud);

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

    const classificationsId = data?._id;

    useEffect(() => {
        dispatch(clearAiRemediationData());
        setTablePage(0);
    }, [rowsPerPage, searchValue]);

    useEffect(() => {
        let skip = "";
        if (page > 0) {
            skip = afterKey[page - 1] || "";
        }
        fetchCode(skip);
    }, [rowsPerPage, page, searchValue]);

    const fetchCode = async (skip) => {
        setLoading(true);
        const response = await dispatch(getAiRemediationCloud(classificationsId, rowsPerPage, skip, searchValue));
        if (response?.abort) return;
        setLoading(false);
    };

    const { Panel } = Collapse;

    const onCollapseChange = (keys = [], integrationId = "") => {
        const shouldCloseCollapseActiveKeys = keys[0] === integrationId;
        if (shouldCloseCollapseActiveKeys) {
            setActiveKeyCollapse((prev) => {
                return { ...prev, [integrationId]: [] };
            });
        }
    };

    const handleSetRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setTablePage(0);
    };

    const handleSetSearchVal = (val) => {
        return setSearchValue(val);
    };

    const updateFilters = (key) => {
        return setSearchValue("");
    };

    const renderHeader = (title, count, provider) => (
        <div className="panel-header">
            <div className="panel-header-content">
                <RightOutlined className="panel-arrow-icon" />
                <span className="panel-icon-provider">
                    <img src={clouds(provider, themeDark)} />
                </span>
                <span className="panel-title">{title}</span>
                <span className="panel-count purple-flag g5 row">
                    <FontAwesomeIcon icon="layer-group" className="purple-text" />
                    {count}
                </span>
            </div>
        </div>
    );

    const codeDataFlat = Object.entries(cloudData).flatMap(([vcId, items]) => items);

    const isCodeDataPagination = (totalFiles || 0) > 10;
    const isDataExist = !!codeDataFlat?.length;

    return (
        <div className="AiRemediationVc AiRemediationProvider col g15">
            <div className="AiRemediationVc__header">
                <span className="font-20 bold">Cloud Patch</span>
                <HeaderSearchBox placeholder="Search" value={handleSetSearchVal} currentValue={searchValue} resetSearch={handleSetSearchVal} />
                {searchValue && <FilterTag value={searchValue} onClose={updateFilters} />}
            </div>
            {loading ? (
                <Loading />
            ) : isDataExist ? (
                <>
                    {codeDataFlat.map((item = {}) => (
                        <Collapse onChange={(open) => onCollapseChange(open, item.integrationId)} key={item.integrationId}>
                            <Panel header={renderHeader(item.integrationName, item.count, item.provider)} key={item.integrationId}>
                                <AiRemediationCliCollapse
                                    classificationsId={classificationsId}
                                    totalAssets={item.count}
                                    integrationId={item.integrationId}
                                    setActiveKey={setActiveKeyCollapse}
                                    activeKey={activeKeyCollapse}
                                    classificationData={data}
                                    searchValue={searchValue}
                                />
                            </Panel>
                        </Collapse>
                    ))}

                    {isCodeDataPagination && (
                        <TablePagination
                            component="div"
                            className="AiRemediationVc__pagination"
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            onRowsPerPageChange={handleSetRowsPerPage}
                            count={totalFiles}
                            page={page}
                            onPageChange={(e, page) => setTablePage(page)}
                        />
                    )}
                </>
            ) : (
                <div className="AiRemediationVc__no-data center col g10">
                    <AppEmpty customStyle="code" />
                    <div className="row g5 font-15">
                        <span>No results for</span>
                        <span className="bold">{searchValue}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AiRemediationProvider;
