import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppToggle from "../appToggle/appToggle";

import "./prodToggle.scss";
import { useDispatch, useSelector } from "react-redux";
import { sendEvent } from "../../utils/amplitude";
import { setIsToggleSwitch } from "../../redux/actions/inventoryv3Actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ProdToggle = ({ value, setStoreValue, disabled, setInsightsFilterProd, location }) => {
  const [val, setVal] = useState(value);

  const onlyProd = useSelector((state) => state.dashboardReducer.onlyProd);

  const dispatch = useDispatch();

  const history = useHistory();

  const handleChange = () => {
      setVal(!val);
      setStoreValue(!val);
      setInsightsFilterProd(!val);
      sendEvent("toggled production filter", { isProductionToggleEnabled: !val, location: location });
      dispatch(setIsToggleSwitch(!val));
      history.push({
          search: "",
      });
  };

  useEffect(() => {
    setVal(value);
  }, [onlyProd]);

  return (
    <div className="ProdToggle row">
      <FontAwesomeIcon icon="flag" className="ProdToggle__icon" />
      <span className="muted">Production</span>
      <AppToggle
        disabled={disabled}
        checked={val}
        toggleChecked={handleChange}
        loading={false}
      />
    </div>
  );
};

export default ProdToggle;
