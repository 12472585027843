import React, { useState, useEffect } from "react";
import Loading from "../loading/loading";
import AppEmpty from "../appEmpty/appEmpty";
import { useDispatch, useSelector } from "react-redux";
import { getMetabaseEmbedUrl } from "../../redux/actions/globalAppActions";
import "./metabaseEmbedWrapper.scss";

const MetabaseEmbedWrapper = ({
    noUrlText = 'The page is currently unavailable',
}) => {
    const [metabaseEmbedUrl, setMetabaseEmbedUrl] = useState("");
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const isThemeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

    useEffect(() => {
        fetchUrl()
    }, [isThemeDark]);

    const fetchUrl = async () => {
        setLoading(true);
        const theme = isThemeDark ? 'dark' : 'light';
        const req = await dispatch(getMetabaseEmbedUrl(theme));
        if(req) {
            setMetabaseEmbedUrl(req);
        }
        setLoading(false);
    }

    return (
        <div className="MetabaseEmbedWrapper">
            {loading ? <Loading/> : !loading && !metabaseEmbedUrl?.iframeUrl ?
                <AppEmpty text={noUrlText} customStyle="code"/> :
                <iframe src={metabaseEmbedUrl?.iframeUrl} width="100%" height="100%" allowFullScreen/>
            }
        </div>
    );
};

export default MetabaseEmbedWrapper;