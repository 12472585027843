import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Loading from "../../shared/loading/loading";
import useScrollPosition from "../../hooks/useScrollPosition";

const PlaylistDrawer = ({ loading, playlists, handleClose, onPlayPreview }) => {
    const [playlistsElement, setPlaylistsElement] = useState(document.getElementById("playlist-content"));
    const { hasMoreToScroll } = useScrollPosition(playlistsElement);
    
    useEffect(() => {
        initializePlaylistsElement();
    },[])
    const initializePlaylistsElement = () => {
        if (playlistsElement) return;
        const element = document.getElementById("playlist-content");
        if (element) setPlaylistsElement(element);
    };
    const handleScroll = (divToScroll) =>  {
        if (!divToScroll) return;
        divToScroll.scrollTo({ top: divToScroll.scrollHeight, behavior: "smooth" });
    }
    return (
        <div className="PlaylistDrawer">
            <div className="PlaylistDrawer__container">
                <div className="PlaylistDrawer__header row g20">
                    <span className="font-16 centered">
                        <span className="gradient-text bold">Firefly Essentials</span> - Bitesize tutorials
                    </span>
                    <FontAwesomeIcon icon="times" className="wb-text pointer PlaylistDrawer-closeBtn" onClick={handleClose} />
                </div>
                <div className="PlaylistDrawer__content col g10" id="playlist-content">
                  {loading ? <div className="basic-loading"> <Loading /> </div>
                    : playlists.map((playlist = {}) => {
                        const { url: playlistUrl = "", title = "" } = playlist;
                        const playlistId = `playlist-${playlistUrl}`;
                        return (
                            <div key={playlistUrl} className="PlaylistDrawer__item col g5" id={playlistId}>
                                <span className="font-14 bold">{title}</span>
                                <ReactPlayer
                                    url={playlistUrl}
                                    muted={false}
                                    controls
                                    light={true}
                                    onClickPreview={() => onPlayPreview(playlistUrl, title)}
                                    width="100%"
                                    height="200px"
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 2, autoplay: 0 },
                                        },
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                 {hasMoreToScroll && playlistsElement && playlists?.length > 0 && <FontAwesomeIcon className="PlaylistDrawer-scrollIcon pointer scrollMoveDown" size="2x" icon="chevron-down" onClick={() => handleScroll(playlistsElement)} />}
            </div>
        </div>
)};

export default PlaylistDrawer;