import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ReactComponent as ExpectedValueIcon } from "../../../../../Images/ci/scan-expected.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as RemediationIcon } from "../../../../../Images/general_icons/remediation.svg";

import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RemediationBlock from "../../remediationBlock/remediationBlock";
import "./policyScaning.scss";

const PolicyScaningItem = ({ severLower, severityTitle, actualValue, expectedValue, codeStartLine, code, scanId,
    resourceId, fetchRemediateScan , description, ruleUrl, ruleName, openRemediationsByDefault, allLoading }) => {
   const { t } = useTranslation("ci-pipeline", { keyPrefix: "diagnosticRemediation" });
   const [isRemediationOpen, setIsRemediateOpen] = useState(false);
   const [remedationLoading, setRemedationLoading] = useState(false);
   const remediationPlanByScanId = useSelector((state) => state.ciWorkflowsReducer.remediationPlanByScanId) || {};
   const diagnosticRemediation = remediationPlanByScanId[scanId] || {};
   const isDiagnosticRemediation = !isEmpty(diagnosticRemediation);
  
   useEffect(() => {
       if (openRemediationsByDefault && isDiagnosticRemediation) {
           setIsRemediateOpen(true)
       }
   }, [openRemediationsByDefault]);

   const handleFetch = async() => {
       if (isDiagnosticRemediation) return;
       setRemedationLoading(true);
       await fetchRemediateScan({ [scanId]: resourceId });
       setRemedationLoading(false);
   }

   const onRemediateClick = () => {
       const showRemediation = !isRemediationOpen;
       setIsRemediateOpen(showRemediation);
       if (showRemediation) {
           handleFetch();
       }
   }

   return (
       <div className="PolicyScaning__container g8">
           <div className="PolicyScaning col g5">
               <div className="row g8">
                   <span className={`PolicyScaning__title ${severLower}`}><span className="bold">{severityTitle}</span> Severity</span>
                   <Tooltip title={t("thinker2Tooltip")}>
                        <div className="CiCode__content-collapse-content-policyViolations-item-remediateIcon row g5" onClick={() => onRemediateClick(scanId, resourceId)}>
                            <RemediationIcon />
                            <span>Remediate</span>
                        </div>
                   </Tooltip>
               </div>
               {!expectedValue ? <span>{actualValue}</span> : null}
               <div className="col">
                   <div className="PolicyScaning__code">
                       <SyntaxHighlighter
                           style={atomDark}
                           showLineNumbers={true}
                           language="hcl"
                           lineProps={{
                           style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                           }}
                           startingLineNumber={codeStartLine}
                       >
                           {code}
                       </SyntaxHighlighter>
                   </div>
                   {expectedValue ?
                   <div className="PolicyScaning__patch col g5">
                       <div className="row g8">
                           <ExpectedValueIcon className="PolicyScaning__patch-icon"/>
                           <span className="PolicyScaning__patch-title bold">{ruleName}</span>
                       </div>
                       <span className="PolicyScaning__patch-description">{description}</span>
                       <div className="row g8">
                           <span className="PolicyScaning__patch-value">{expectedValue}</span>
                           {ruleUrl ? <FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => { 
                               e.stopPropagation();
                               window.open(ruleUrl, "_blank");
                               }}/> : null }
                       </div>
                   </div> : null }
               </div>
           
           </div>
           {isRemediationOpen && <RemediationBlock data={diagnosticRemediation} remediationLoading={remedationLoading || allLoading} isDiagnosticRemediation={isDiagnosticRemediation} setIsRemediateOpen={setIsRemediateOpen} isFromExpandedBox />}
       </div>
   )
}

export default PolicyScaningItem;