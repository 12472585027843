import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash/debounce";

import "./headerSearchBox.scss";

import { ReactComponent as CmdDark } from "../../Images/general_icons/inventory/cmdDark.svg";
import { ReactComponent as KDark } from "../../Images/general_icons/inventory/kDark.svg";
import { ReactComponent as CmdLight } from "../../Images/general_icons/inventory/cmdLight.svg";
import { ReactComponent as KLight } from "../../Images/general_icons/inventory/kLight.svg";
import { useSelector } from "react-redux";

const HeaderSearchBox = ({ placeholder, value, resetSearch, currentValue, hideClear, dynamicSearch = false, page = "", width = "420px" }) => {
    const [val, setVal] = useState("");
    const [active, setActive] = useState(false);

    const isDarkTheme = useSelector((state) => state.userPreferencesReducer.themeDark);

    useEffect(() => {
        setVal(currentValue);
    }, [currentValue]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setVal(newValue);
        if (dynamicSearch) {
            debounceFunc(newValue);
        }
    };

    const debounceFunc = useCallback(debounce((val) => value(val), 500));

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !dynamicSearch) {
            e.preventDefault();
            handleSearch();
        }
    };

    const handleSearch = () => {
        return value(val);
    };

    const handleClear = () => {
        setVal("");
        return resetSearch("");
    };

    const isShowClear = val?.length > 0 && !hideClear;

    const commndKIconDark = () => (
        <div className="HeaderSearchBox__inner-ck">
            <span className="mgRight4">
                <CmdDark />
            </span>
            <KDark />
        </div>
    );

    const commndKIconLight = () => (
        <div className="HeaderSearchBox__inner-ck">
            <span className="mgRight4">
                <CmdLight />
            </span>
            <KLight />
        </div>
    );

    return (
        <div
            className={`HeaderSearchBox row`}
            style={{
                width: width,
            }}
        >
            {!dynamicSearch && (
                <button className="HeaderSearchBox__btn" onClick={handleSearch} style={{ height: "40px" }}>
                    <FontAwesomeIcon icon="search" />
                </button>
            )}
            <div className="HeaderSearchBox__inner">
                <input
                    placeholder={active || val?.length > 0 ? null : placeholder}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={val}
                    onFocus={() => setActive(true)}
                    onBlur={() => setActive(false)}
                />
                {!isShowClear && page === "inventory" && (isDarkTheme ? commndKIconDark() : commndKIconLight())}
                {isShowClear && <FontAwesomeIcon icon="times-circle" className="HeaderSearchBox__inner-clear" onClick={handleClear} />}
            </div>
        </div>
    );
};

export default HeaderSearchBox;
