import { v4 as uuidv4 } from 'uuid';
import { ACTIVE_CODIFICATION_TYPES, CODIFY_LANGUAGES, MODULE_CALL_FORMAT_TYPES } from "../../components/inventory/codifyDrawer/codifyHelpers";
import { CODIFY_TYPES, IAC_TYPES } from "../../consts/general";
import {
  GET_REPO_MODULES,
  GET_MODULES_BY_REPO,
  MODULE_CALL,
  SET_PULL_REQUEST_PARAMETERS,
  CREATE_MODULE,
  SELECTED_MODULE_FILE,
  CLEAR_MODULE_DATA,
  SET_SELECTED_MODULE_CALL_FILE_PATH,
  SET_SELECTED_MODULE_CALL_FORMAT,
  CHANGE_CODIFY_DRAWER_PROPERTY,
  CLEAR_CODIFY_DRAWER_STATE,
  CLEAR_SAVED_CODE,
  HANDLE_UNSAVED_MODAL_ACTION,
  GET_CODIFY_DATA_BY_PROMPT
} from "../actions/iacImportActions";
import _ from 'lodash';

const EMPTY_TEMP_CODE = { new: "", old: "" };

const initialState = {
  moduleRepos: [],
  modules: [],
  moduleData: {},
  pullReqParams: null,
  moduleFileSelected: null,
  moduleDataCreated: {},
  selectedModuleCallFilePath: "",
  selectedModuleCallFormat: MODULE_CALL_FORMAT_TYPES.HCL,
  //codify drawer state - renovation
  codifyDrawerOpen: false,
  codifySessionId: uuidv4(),
  terraformIacData: {},
  tfCdkData: {},
  k8sIacData: null,
  otherIacData: {},
  activeIacTab: IAC_TYPES.terraform,
  activeLanguage: CODIFY_LANGUAGES[0],
  activeCodificationType: ACTIVE_CODIFICATION_TYPES.naive,
  isImportBlocksClicked: false,
  isProviderBlockClicked: false,
  importBlocks: [],
  providerBlock: [],
  isOverweightResponse: false,
  isK8sRedacted: false,
  moduleProtocol: "ssh",
  dependenciesToggle: false,
  //editMode tricks
  hasSavedCode: false,
  tempCode: EMPTY_TEMP_CODE,
  unsavedModal: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REPO_MODULES:
      return { ...state, moduleRepos: payload };
    case GET_MODULES_BY_REPO:
      return { ...state, modules: payload };
    case MODULE_CALL: {
      const files = payload?.Files || {};
      const defaultSelectedModuleCallCodeFilePath = state.selectedModuleCallFormat === MODULE_CALL_FORMAT_TYPES.HCL ? "main.tf" : Object.keys(files)?.[0] || "";
      return { ...state, moduleData: payload, selectedModuleCallFilePath: defaultSelectedModuleCallCodeFilePath };
    }
    case SET_PULL_REQUEST_PARAMETERS:
      return { ...state, pullReqParams: payload };
    case CREATE_MODULE:
      // set the module data and set the first object key as a selected file name
      const { redactedValues, ...files } = payload;

      return { ...state, moduleDataCreated: files, moduleFileSelected: !_.isEmpty(files) && _.isObject(files) ? Object.keys(files)[0] : null, redactedValues };
    case SELECTED_MODULE_FILE: {
      const { tempCode = {}, editMode } = state;
      const isUnsavedCodeIdentified = editMode && tempCode.new !== tempCode.old;
      if (isUnsavedCodeIdentified) {
        const newTemp = {...tempCode, newModuleFileSelected: payload };
        return { ...state, tempCode: newTemp, unsavedCodeModal: true };
      }
      return { ...state, moduleFileSelected: payload };
    }
    case CLEAR_MODULE_DATA:
      return { ...state, moduleData: {}};
    case SET_SELECTED_MODULE_CALL_FILE_PATH: {
      const { tempCode = {}, editMode } = state;
      const isUnsavedCodeIdentified = editMode && tempCode.new !== tempCode.old;
      if (isUnsavedCodeIdentified) {
        const newTemp = { ...tempCode, newSelectedModuleCallFilePath: payload };
        return { ...state, tempCode: newTemp, unsavedCodeModal: true };
      }
      return { ...state, selectedModuleCallFilePath: payload };
    }
    case SET_SELECTED_MODULE_CALL_FORMAT: 
      return { ...state, selectedModuleCallFormat: payload };
    case CHANGE_CODIFY_DRAWER_PROPERTY: {
      const { key = "", value = "", isMultipleKeys = false} = payload;
      const { tempCode = {}, activeIacTab, editMode } = state;
      const isActiveIacTabKey = key === "activeIacTab";
      const isSmartTabKey = key === "activeCodificationType";
      const isLanguageKey = key === "activeLanguage";
      const tryingToCloseCodifyDrawer = key === "tryingToCloseModal";
      const isUnsavedCodeIdentified = (isActiveIacTabKey || isSmartTabKey) && editMode && tempCode.new !== tempCode.old;

      if (isMultipleKeys) {
        if (isUnsavedCodeIdentified) {
          const { activeCodificationType, activeIacTab: newTab } = value;
          const newTemp = { ...tempCode, newCodificationType: isActiveIacTabKey ? ACTIVE_CODIFICATION_TYPES.naive : activeCodificationType, newIacTab: isActiveIacTabKey ? newTab : activeIacTab };
          return { ...state, tempCode: newTemp, unsavedCodeModal: true };
        }
        const hasToResetEditMode = value?.activeIacTab || value?.activeCodificationType;
        return { ...state, ...value, tempCode: EMPTY_TEMP_CODE, editMode: hasToResetEditMode ? false : editMode };
      }

      const isSwitchSmartTabOrCodifyTab = isActiveIacTabKey || isSmartTabKey || isLanguageKey;
      const hasToResetEditMode = isSwitchSmartTabOrCodifyTab;
      if (tryingToCloseCodifyDrawer) {
        const newTemp = { new: tempCode.new, old: tempCode.old, closeCodifyDrawer: true };
        return { ...state, tempCode: newTemp, unsavedCodeModal: true};
      }
      if (isUnsavedCodeIdentified) {
        const newTemp = { ...tempCode, newCodificationType: isActiveIacTabKey ? ACTIVE_CODIFICATION_TYPES.naive : value, newIacTab: isActiveIacTabKey ? value : activeIacTab };
         return { ...state, tempCode: newTemp, unsavedCodeModal: true };
      }
      if (hasToResetEditMode) {
        return { ...state, [key]: value, hasSavedCode: false, editMode: false, tempCode: EMPTY_TEMP_CODE };
      }
      
      return { ...state, [key]: value };
    }
    case CLEAR_CODIFY_DRAWER_STATE:
      return {...state, k8sIacData: null, hasSavedCode: false, terraformIacData: {}, tfCdkData: {}, otherIacData: {}, moduleDataCreated: {}, moduleData: {}, moduleFileSelected: null, selectedModuleCallFilePath: "",
      isOverweightResponse: false, editMode: false, tempCode: EMPTY_TEMP_CODE, isImportBlocksClicked: false, isProviderBlockClicked: false }
    case CLEAR_SAVED_CODE: 
      return {...state, hasSavedCode: false, editMode: false, tempCode: EMPTY_TEMP_CODE};
    case HANDLE_UNSAVED_MODAL_ACTION: {
      const { continueToOtherScreen = false } = payload;
      const { editMode: currEditMode } = state;
      if (continueToOtherScreen) {

        const { newCodificationType, newIacTab, newModuleFileSelected = "", newSelectedModuleCallFilePath = "", closeCodifyDrawer = false } = state.tempCode;
        const clearTempCodeState = { tempCode: EMPTY_TEMP_CODE, unsavedCodeModal: false, editMode: false };
        const isTabChange = newCodificationType || newIacTab;
        if (isTabChange) {
          return { ...state, ...clearTempCodeState, activeCodificationType: newCodificationType, activeIacTab: newIacTab };
        }
        if (closeCodifyDrawer) {
          return { ...state, ...clearTempCodeState, codifyDrawerOpen: currEditMode ? true : false };
        }
        if (newModuleFileSelected) {
          return { ...state, ...clearTempCodeState, moduleFileSelected: newModuleFileSelected };
        }
        if (newSelectedModuleCallFilePath) {
          return { ...state, ...clearTempCodeState, selectedModuleCallFilePath: newSelectedModuleCallFilePath };
        }
        return { ...state, ...clearTempCodeState };
      }
      return { ...state, unsavedCodeModal: false }
    }
    case GET_CODIFY_DATA_BY_PROMPT: {
      const { activeIacTab: activeTab = CODIFY_TYPES.terraform, activeCodificationType = ACTIVE_CODIFICATION_TYPES.naive } = state;
      const { data } = payload;
      const { newCode } = data || {};
      const updatedState = { ...state, hasSavedCode: false, editMode: true, tempCode: EMPTY_TEMP_CODE };
      if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.createModule) {
        const data = (newCode || []).reduce((acc, item) => {
          const { filePath, content } = item;
          acc[filePath] = content;
          return acc
        }, {});
        return { ...updatedState, moduleDataCreated: { ...state.moduleDataCreated, ...data } };
      }
      if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules) {
        const data = (newCode || []).reduce((acc, item) => {
          const { filePath, content } = item;
          acc[filePath] = content;
          return acc
        }, {});
        return { ...updatedState, moduleData: { ...state.moduleData, Files: data} };
      }
      if (activeTab === CODIFY_TYPES.terraform) {
        return { ...updatedState, terraformIacData: { ...state.terraformIacData, [activeCodificationType]: newCode } };
      }
      if (activeTab === CODIFY_TYPES.tfcdk) {
        return { ...updatedState, tfCdkData: { ...state.tfCdkData, [activeCodificationType]: newCode } };
      }
      if (activeTab === CODIFY_TYPES.crossplane && activeCodificationType === ACTIVE_CODIFICATION_TYPES.createComposition) {
        return { ...updatedState, compositionData: newCode };
      }
      else {
        return { ...updatedState, otherIacData: { ...state.otherIacData, [activeTab]: newCode } };
      }
    }
    default:
      return state;
  }
};
