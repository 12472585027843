import isEmpty from 'lodash/isEmpty';
import {capitalizeFirst, renderEndEllipsis} from "./formatting";
import moment from "moment";
import { CI_STEPS } from '../consts/ci-workflows';

const CI_STEP_DATE_FORMAT = "MMM DD YYYY, HH:mm"

export const checkIfPolicyOrCostExists = (planObj = {}) => Object.values(planObj.scanResultsSummary?.rego?.notNoOpResourcesViolationsSeverityCount || {}).some(val => val > 0)
|| planObj?.costResultsSummary?.cost?.notNoOpResourcesCostEstimationCount > 0;

export const getGeneralPolicyCostSummaryData = (planObj = {}) => {
    const { scanResultsSummary = {}, costResultsSummary = {}, parsedDataSummary = {} } = planObj;
    const beforeCost = costResultsSummary.cost?.before?.totalMonthlyCost || 0;
    const afterCost = costResultsSummary.cost?.after?.totalMonthlyCost || 0;
    const was = beforeCost == 0 ? beforeCost : parseFloat(beforeCost).toFixed(2);
    const now = afterCost == 0 ? afterCost : parseFloat(afterCost).toFixed(2);
    
    const { notNoOpTaggedResourcesCount = 0, notNoOpTaggableResourceCount = 0 } = parsedDataSummary?.plan || {};
    const totalTaggingPercenetage = notNoOpTaggableResourceCount ? (notNoOpTaggedResourcesCount / notNoOpTaggableResourceCount) * 100 : 100;

    const severities = scanResultsSummary.rego?.notNoOpResourcesViolationsSeverityCount || {};
    return { policySummary: severities, costSummary: { was, now }, tagsSummary:  { totalTaggingPercenetage, a: notNoOpTaggedResourcesCount, b: notNoOpTaggableResourceCount } };
};
export const checkIfShouldUpdateOnlyTotalAgg = (ciFilters = {}) => {
    const { repos: reposFilters = [], ciTool: ciToolFilters = [], labels: labelsFilters = [], status: statusFilters = [], workspaceId = [] } = ciFilters;
    if (!workspaceId.length) return true;
    const otherFilters = [reposFilters, ciToolFilters, labelsFilters, statusFilters];
    const isNotOnlyWorkspaceId = otherFilters.some(filter => filter?.length > 0);
    return isNotOnlyWorkspaceId;
}
export const getPolicySeveritiesObject = (planArr = [], returnCategoriesByLowerCase = false) => {
    const scanResultsSummary = planArr[0]?.scanResultsSummary || {};
    let severities = scanResultsSummary.rego?.notNoOpResourcesViolationsSeverityCount || { HIGH: 0, MEDIUM: 0, LOW: 0 };
    if (returnCategoriesByLowerCase) {
        severities = ['HIGH', 'MEDIUM', 'LOW'].reduce((acc, key = {}) => {
            const lowerKey = key?.toLowerCase(); 
            acc[lowerKey] = severities[key] || 0;
            return acc;
        }, {});
    }
    return severities;
};
export const getCostSummaryPrices = (planArr = []) => {
    const costResultsSummary = planArr[0]?.costResultsSummary;
    if (isEmpty(costResultsSummary)) return { isEmptySummary: true };
    const beforeCost = costResultsSummary.cost?.before?.totalMonthlyCost || 0;
    const afterCost = costResultsSummary.cost?.after?.totalMonthlyCost || 0;
    const diffCost = afterCost - beforeCost;
    const wasPrice = beforeCost == 0 ? beforeCost : parseFloat(beforeCost).toFixed(2);
    const nowPrice = afterCost == 0 ? afterCost : parseFloat(afterCost).toFixed(2); 
    const diffPrice = diffCost == 0 ? 0 : parseFloat(Math.abs(diffCost)).toFixed(2);
    return { wasPrice, nowPrice, diffPrice, diffCost, beforeCost, afterCost };
};
export const getActionsCountsSummary = (planArr = [], applyArr = []) => {
    const planSummary = planArr[0];
    const applySummary = applyArr[0];
    const parsedDataSummary = applySummary?.parsedDataSummary?.apply || planSummary?.parsedDataSummary?.plan;
    const { createdResourcesCount: createCount = 0, updatedResourcesCount: updateCount = 0, 
        deletedResourcesCount: deleteCount = 0, importedResourcesCount: importCount = 0,
        failedResourcesCount: failedCount = 0
     }  = parsedDataSummary || {};
    return { create: createCount, update: updateCount, delete: deleteCount, import: importCount, fail: failedCount };
}
export const getMonthlyCost = (costResults = [], action) => {
    const costElement = costResults[0] || {};
    const { before = {}, after = {},  } = costElement;
    const costObj = action === 'delete' ? before : after;
    const { monthlyCost: monthlyTotalCost = "0" } = costObj;
    return monthlyTotalCost;
}

export const formatWorkspaceName = (workspaceName = "") => {
    return renderEndEllipsis(workspaceName, 23);
};
export const formatRunTimeDate = (lastRunTime = "") => {
    const isRealDate = new Date(lastRunTime).getFullYear() > 1970;
    return lastRunTime && isRealDate ? moment(lastRunTime).format(CI_STEP_DATE_FORMAT) : "-";
}
export const getProperFilters = (actionFilters = {}, severityFilters) => {
    const filters = { "orClauses": {"action": ["create", "update", "delete", "import"], "hasDiagnostics": true, isDrifted: true }, "andClauses" : {}};
    const actionArr = Object.keys(actionFilters).filter(key => actionFilters[key]);
    if (!isEmpty(actionFilters)) {
        filters.andClauses.action = actionArr;
    }
    const severityArr = Object.keys(severityFilters).filter(key => severityFilters[key]).map((key) => key?.toUpperCase());
    if (!isEmpty(severityFilters)) {
        filters.andClauses["scanResults.severity"] = severityArr;
    }
    return filters;
};

export const checkIfGuardrailScopeStatementNotEmpty = (scopeStatement, withIncludeExclude =  true) => {
    if (!withIncludeExclude) return scopeStatement?.length > 0 && scopeStatement[0] != "*";
    const { include = [], exclude = [] } = scopeStatement || {};
    return include.length > 0 && include[0] != "*" || exclude.length > 0 && exclude[0] != "*";
}

export const WORKFLOWS_STATUSES_TITLES = {
    "plan_success": "PLAN COMPLETED",
    "plan_error": "PLAN FAILED",
    "apply_error": "APPLY FAILED",
    "apply_success": "APPLIED",
    "init_error": "INIT FAILED",
    "blocked": "BLOCKED",
    "apply_no_changes": "APPLY NO CHANGES",
    "plan_no_changes": "PLAN NO CHANGES",
}
export const formatCiToolName = (str = "") => {
    let newStr = capitalizeFirst(str);
    if (str.includes("-")) {
        newStr = str.split("-").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    }
    return newStr;
}

export const getDefaultStepFromStatus = (status = "", guardrailIsEnabled, guardrailsSummary, initDefault) => {
    if (status.includes(CI_STEPS.apply)) return CI_STEPS.apply;
    if (guardrailIsEnabled && guardrailsSummary.violationsCount > 0) return CI_STEPS.guardrail;
    if (initDefault) return CI_STEPS.init;
    return CI_STEPS.plan;
}

export const formatJsonDiffs = (diffs) => {
    const parsed = JSON.parse(diffs) || [];
    parsed.map((item = {}) => {
        const { before = '', originalBefore = "", originalAfter = "", after = '' } = item;
        item.before = originalBefore || before;
        item.after = originalAfter || after;
        delete item.originalBefore;
        delete item.originalAfter;
        return item;
    })
    const jsonDiffs = JSON.stringify(parsed);
    return jsonDiffs;
}