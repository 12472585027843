import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { WORKFLOWS_ACTIONS } from "../../consts/ci-workflows";
export const GET_DASHBOARD_BASE_AGGREGATIONS = "GET_DASHBOARD_BASE_AGGREGATIONS";
export const GET_DASHBOARD_FIREFLY_AGGREGATIONS = "GET_DASHBOARD_FIREFLY_AGGREGATIONS";
export const SET_ONLY_PROD = "SET_ONLY_PROD";
export const SET_PROD_ACCOUNTS = "SET_PROD_ACCOUNTS";
export const SET_ONBOARDING_COMPLETE = "SET_ONBOARDING_COMPLETE";
export const GET_ACCOUNT_TOTAL_ASSETS = "GET_ACCOUNT_TOTAL_ASSETS";
export const GET_WORKFLOWS_DASHBOARD_DATA = "GET_WORKFLOWS_DASHBOARD_DATA";
export const GET_TAGGING_COVERAGE = "GET_TAGGING_COVERAGE";
export const GET_CONNECTED_SERVICES = "GET_CONNECTED_SERVICES";
export const SET_DASHBOARD_PROPERTY = "SET_DASHBOARD_PROPERTY";
export const GET_DRIFT_COST = "GET_DRIFT_COST";
export const GET_MAP_DATA = "GET_MAP_DATA";

const getDashboardBaseAggregations = (screenFilters, freeTextSearch, showError, onlyProd) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/dashboard/base-aggs`,
            "POST",
            { screenFilters, freeTextSearch, onlyProd },
            undefined,
            undefined,
            showError,
        );
        let data = {}
        if (req.ok) {
            data = await req.json();
        }

        dispatch({
            type: GET_DASHBOARD_BASE_AGGREGATIONS,
            payload: data,
        });
    });
};

const getDashboardFireflyAggregations = (onlyProd, showError) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/dashboard/firefly-aggs`, "POST", { onlyProd }, undefined, undefined, showError);
        let data = {}
        if (req.ok) {
            data = await req.json();
        }

        dispatch({
            type: GET_DASHBOARD_FIREFLY_AGGREGATIONS,
            payload: data,
        });
    });
};

const setOnlyProd = (onlyProd) => {
    return action(async (dispatch) => {
        dispatch({
            type: SET_ONLY_PROD,
            payload: onlyProd,
        });
    });
};

const setProdAccounts = (arr) => {
    return action(async (dispatch) => {
        dispatch({
            type: SET_PROD_ACCOUNTS,
            payload: arr,
        });
    });
};

const setOnboardingComplete = (isComplete) => {
    return action(async (dispatch) => {
        dispatch({
            type: SET_ONBOARDING_COMPLETE,
            payload: isComplete,
        });
    });
};

const getAccountTotalAssets = (onlyProd, showError) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/dashboard/inventory-total`, "POST", { onlyProd }, undefined, undefined, false);
        let data = {}
        if (req.ok) {
            data = await req.json();
        }

        dispatch({
            type: GET_ACCOUNT_TOTAL_ASSETS,
            payload: data,
        });
    });
};

const getTaggingCoverage = () => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/dashboard/tagging-coverage`, "GET", {}, undefined, undefined, false);
        let data = [];
        if (req.ok) {
            data = await req.json();
        }
        dispatch({
            type: GET_TAGGING_COVERAGE,
            payload: data,
        });
    });
};

const getMapData = () => {
    return action(async (dispatch) => {
        try {
            const requestWrapper = new RequestWrapper();
            const response = await requestWrapper.sendRequest(`${BACKEND_URL}/dashboard/map-data`, "GET", {}, undefined, undefined, false);
            let data = [];
            if (response.ok) {
                data = await response.json();
            }
            if (data?.errors) {
                data = []
            }
            dispatch({
                type: GET_MAP_DATA,
                payload: data,
            })
        } catch (error) {
            return [];
        }
    });
};

const getWorkflowsDashboardData = () => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const currentDateTime = new Date().getTime();
        const oneWeekAgo = new Date(currentDateTime - 7 * 24 * 60 * 60 * 1000);
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/ci/tasks/resources/aggregations`,
            "POST",
            {
                filters: {
                    orClauses: { action: [WORKFLOWS_ACTIONS.create, WORKFLOWS_ACTIONS.update, WORKFLOWS_ACTIONS.delete, WORKFLOWS_ACTIONS.import] },
                    andClauses: { taskType: "post-apply" },
                    createdAfter: oneWeekAgo,
                    disableLookup: true,
                },
                fieldsToGroup: ["taskType"],
            },
            undefined,
            undefined,
            false,
        );
        let data = null;
        if (req?.ok) {
            data = await req.json();
        }
        dispatch({
            type: GET_WORKFLOWS_DASHBOARD_DATA,
            payload: data,
        });
    });
};
export const getConnectedServices = () => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/ibom/connected-services`, "GET", undefined, undefined, undefined, false);
        let data = null;
        if (req?.ok) {
            data = await req.json();
        }
        dispatch({
            type: GET_CONNECTED_SERVICES,
            payload: data,
        });

        return data;
    });
};
const getConnectedServicesByProvider = (provider, service) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/ibom/connected-services/type/${provider}`, "GET", undefined, undefined, undefined, true);
        let data = null;
        if (req?.ok) {
            data = await req.json();
            const modalDetails = { selectedService: service, data, selectedProvider: provider, visible: true };
            dispatch({
                type: SET_DASHBOARD_PROPERTY,
                payload: { key: "connectedServicesModal", value: modalDetails },
            });
        }
    });
};
const setDashboardProperty = (key, value) => {
    return action(async (dispatch) => {
        dispatch({
            type: SET_DASHBOARD_PROPERTY,
            payload: { key, value },
        });
    });
};
const getDriftCost = () => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/dashboard/drift-cost`, "GET", undefined, true, undefined, false);
        let data = 0;
        if (req?.ok) {
            data = await req.json();
        }

        dispatch({
            type: GET_DRIFT_COST,
            payload: data,
        });
    });
};

export {
    getDashboardBaseAggregations,
    getDashboardFireflyAggregations,
    setOnlyProd,
    setProdAccounts,
    setOnboardingComplete,
    getAccountTotalAssets,
    getWorkflowsDashboardData,
    getTaggingCoverage,
    getConnectedServicesByProvider,
    setDashboardProperty,
    getDriftCost,
    getMapData,
};
