import { EMPTY_CI_WORKFLOWS_AGGS_FILTERS } from "../../consts/general";
import {
    GET_WORKFLOWS_AGGS,
    HANDLE_SET_CI_FILTERS,
    GET_CODE_FILES,
    HANLDE_SET_CI_SORTING,
    GET_IAC_TYPES_VERSIONS,
    GET_DIAGNOSTIC_REMEDIATION,
    GET_INIT_LOG_DATA,
    CLEAR_RUN_LOG_DATA,
    GET_CODE_SCAN_REMEDIATION,
    GET_PLAN_SCAN_REMEDIATION,
    GET_GUARDRAILS_SCAN_REMEDIATION
  } from "../actions/ciWorkflowsActions";
import { DELETE_GUARDRAIL_RULE, EDIT_GUARDRAIL_RULE, GET_GUARDRAILS_AGGS, SET_GUARDRAILE_RULE, SET_GUARDRAILS_DATA, SET_GUARDRAILS_FILTERS, SET_GUARDRAILS_NOTIFICATIONS_DATA } from "../actions/workflowsGuardrailsActions";
  
  const initialState = {
    codeFiles: {},
    ciFilters: EMPTY_CI_WORKFLOWS_AGGS_FILTERS,
    ciSorting: {},
    ciAggs: EMPTY_CI_WORKFLOWS_AGGS_FILTERS,
    total: 0,
    iacTypesVersions: {
      terraform: [],
      opentofu: [],
    },
    guardrailsData: [],
    guardrailsAggs: {},
    guardrailsFilters: {},
    guardrailsNotificationsDetails: [],
    diagnosticsRemediationById: {},
    remediationCodeByScanId: {},
    remediationPlanByScanId: {},
    remediationGuardrailsByViolationId: {},
    logs: {
      init: "",
      plan: "",
      apply: "",
    }
  };
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
      case GET_WORKFLOWS_AGGS:
        const { total, shouldUpdateOnlyTotal = false, ...aggs } = payload;
        const totalWorkflows = total?.[0]?.count || 0;
        const ciAggs = shouldUpdateOnlyTotal ? state?.ciAggs : aggs;
        return { ...state, ciAggs , total: totalWorkflows };
      case HANDLE_SET_CI_FILTERS:
        return { ...state, ciFilters: payload };
      case HANLDE_SET_CI_SORTING:
        return { ...state, ciSorting: payload };
      case GET_CODE_FILES:
        return { ...state, codeFiles: payload };
      case GET_IAC_TYPES_VERSIONS:
        return { ...state, iacTypesVersions: { ...state?.iacTypesVersions, [payload.iacType]: payload?.value } };
      case SET_GUARDRAILS_DATA:
        return { ...state, guardrailsData: payload };
      case SET_GUARDRAILS_NOTIFICATIONS_DATA: 
      return { ...state, guardrailsNotificationsDetails: payload };
      case GET_GUARDRAILS_AGGS: 
        return { ...state, guardrailsAggs: payload };
      case SET_GUARDRAILS_FILTERS:
        return { ...state, guardrailsFilters: payload };
      case SET_GUARDRAILE_RULE:
        const newArray = [...state.guardrailsData, payload];
        return { ...state, guardrailsData: newArray };
      case EDIT_GUARDRAIL_RULE:
        const updatedArray = (state.guardrailsData || []).map((rule) => rule?.id === payload?.id ? payload : rule);
        return { ...state, guardrailsData: updatedArray };
      case DELETE_GUARDRAIL_RULE:
        const filteredArray = (state.guardrailsData || []).filter((rule) => rule?.id !== payload);
        return { ...state, guardrailsData: filteredArray };
      case GET_DIAGNOSTIC_REMEDIATION: 
        const { data = {}, diagnosticId = "" } = payload || {};
        return { ...state, diagnosticsRemediationById: { ...state.diagnosticsRemediationById, [diagnosticId]: data  } };
      case GET_INIT_LOG_DATA: 
        const { data: stepData = {}, step } = payload;
        return { ...state, logs: { ...state.logs, [step]: stepData } };
      case CLEAR_RUN_LOG_DATA: 
        return { ...state, logs: initialState.logs };
      case GET_CODE_SCAN_REMEDIATION:
      case GET_PLAN_SCAN_REMEDIATION:
      case GET_GUARDRAILS_SCAN_REMEDIATION:
        const { data: remediationData = {}, ids = [], isPlan = false, isGuardrails = false } = payload || {};
        const updatedField = isGuardrails ? "remediationGuardrailsByViolationId" : isPlan ? "remediationPlanByScanId" : "remediationCodeByScanId";
        const updatedRemediationState = { ...(state[updatedField] || {})};
        ids.forEach((scanId) => {
          const match = remediationData[scanId];
          if (!match) return;
          updatedRemediationState[scanId] = match;
        });
        return { ...state, [updatedField]: { ...state[updatedField], ...updatedRemediationState } };
      default:
        return state;
  }
}