import isEmpty from "lodash/isEmpty";

import {
  GET_DASHBOARD_BASE_AGGREGATIONS,
  GET_DASHBOARD_FIREFLY_AGGREGATIONS,
  SET_ONLY_PROD,
  SET_PROD_ACCOUNTS,
  SET_ONBOARDING_COMPLETE,
  GET_ACCOUNT_TOTAL_ASSETS,
  GET_WORKFLOWS_DASHBOARD_DATA,
  GET_TAGGING_COVERAGE,
  GET_CONNECTED_SERVICES,
  SET_DASHBOARD_PROPERTY,
  GET_DRIFT_COST,
  GET_MAP_DATA,
} from "../actions/dashboardActions";

const initialState = {
  onlyProd: false,
  prodAccountNumbers: [],
  onboardingComplete: true,
  prodAccountIntegrationIds: [],
  aggregationsProviderId: [],
  aggregationsIsManaged: [],
  aggregationsIsManagedCloud: [],
  aggregationsRegion: [],
  aggregationsAssetsVolume: [],
  aggregationsIacTypes: [],
  providerIdByProviderType: [],
  aggregationsClassificaition: [],
  aggregationsExcludedAssets: [],
  accountTotals: {},
  taggingCoverage: {},
  workflowsAssetAffected: 0,
  connectedServicesModal: {},
  driftCost: 0,
  providersData: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DASHBOARD_BASE_AGGREGATIONS:
      return {
        ...state,
        aggregationsProviderId: isEmpty(payload?.providerId)
          ? state.aggregationsProviderId
          : payload?.providerId,
        aggregationsIsManaged: isEmpty(payload?.ismanaged?.buckets)
          ? state.aggregationsIsManaged
          : payload?.ismanaged?.buckets,
        aggregationsIsManagedCloud: isEmpty(payload?.ismanagedCloud?.buckets)
            ? state.aggregationsIsManagedCloud :
            payload?.ismanagedCloud?.buckets,
        aggregationsRegion: isEmpty(payload?.providerRegion?.buckets)
          ? state.aggregationsRegion
          : payload?.providerRegion?.buckets,
        aggregationsAssetsVolume: payload?.assetsVolume?.buckets,
        aggregationsIacTypes: isEmpty(payload?.iacType?.buckets)
          ? state.aggregationsIacTypes
          : payload?.iacType?.buckets,
        providerIdByProviderType: payload.providerIdByProviderType?.buckets,
      };

    case GET_DASHBOARD_FIREFLY_AGGREGATIONS:
      return {
        ...state,
        aggregationsClassificaition: payload.classifications?.buckets,
        aggregationsExcludedAssets: payload.excludedAssets?.buckets,
      };

    case SET_ONLY_PROD:
      return {
        ...state,
        onlyProd: payload,
      };

    case SET_PROD_ACCOUNTS:
      return {
        ...state,
        prodAccountNumbers: payload,
      };

    case SET_ONBOARDING_COMPLETE:
      return {
        ...state,
        onboardingComplete: payload,
      };
    case GET_ACCOUNT_TOTAL_ASSETS:
      return {
        ...state,
        accountTotals: payload,
      };
    case GET_TAGGING_COVERAGE:
        return {
            ...state,
            taggingCoverage: payload,
        };
    case GET_WORKFLOWS_DASHBOARD_DATA:
      return {
        ...state,
        workflowsAssetAffected: payload?.taskType?.['post-apply'] || 0,
      }
    case GET_CONNECTED_SERVICES:
      return { ...state, connectedServices: payload }
    case SET_DASHBOARD_PROPERTY:
      return { ...state, [payload.key]: payload.value }
    case GET_DRIFT_COST: 
      return { ...state, driftCost: payload }
    case GET_MAP_DATA: 
      return { ...state, providersData: payload }
    default:
      return state;
  }
};
