import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import DevOpsLoader from "../../../shared/devOpsLoader/devOpsLoader";
import {
  getScanStatus,
} from "../../../redux/actions/onbordingActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./initialDiscoveryAlert.scss";
import { useTranslation } from "react-i18next";

const CHECK_SCAN_INTERVAL = 300000;

const InitialDiscoveryAlert = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.onbordingReducer.account);
  const [loadingCheckScan, setLoadingCheckScan] = useState(false);

  const { t } = useTranslation("on-boarding");

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const isInitialScanComplete = account?.initial_scan_complete;

  useEffect(() => {
    if (!isEmpty(account) && !isInitialScanComplete && !loadingCheckScan) {
      fetchScanStatus();
    }
  }, [isInitialScanComplete, loadingCheckScan]);

  const fetchScanStatus = async () => {
    if (!isEmpty(account) && !isInitialScanComplete) setLoadingCheckScan(true);
    await dispatch(getScanStatus());
    setTimeout(() => {
      setLoadingCheckScan(false);
    }, CHECK_SCAN_INTERVAL);
  };
  
  return !isInitialScanComplete ? (
    <div
      className={`InitialDiscoveryAlert ${themeDark ? "dark" : "light"}
      row center`}
    >
      <DevOpsLoader />
      {t("initial-scanning")}
      <Tooltip
        placement="bottomRight"
        title={t("initial-scanning-title")}
        overlayInnerStyle={{
          width: "350px",
        }}
      >
        <FontAwesomeIcon
          icon={["far", "question-circle"]}
          className="InitialDiscoveryAlert__info"
        />
      </Tooltip>
    </div>
  ) : null;
};

export default InitialDiscoveryAlert;
