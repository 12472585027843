import { useEffect, useState } from "react";
import { Popover, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import map from "lodash/map";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { dateTimeFormat, formatIacStatus, numberWithCommas } from "../../../utils/formatting";
import { ReactComponent as EventDriven } from "../../../Images/general_icons/eventdriven.svg";
import { ReactComponent as NonEventDriven } from "../../../Images/general_icons/periodic-scan-icon.svg";
import { ReactComponent as NonProdIcon } from "../../../Images/general_icons/Non-Production-flag-icon.svg";
import { ReactComponent as ProjectsIcon } from "../../../Images/general_icons/Projects.svg";
import { ReactComponent as StacksIcon } from "../../../Images/general_icons/Stacks.svg";
import { ReactComponent as IacStack } from "../../../Images/general_icons/iac_stack.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import IntegrationDiscoveryCard from "../../../containers/integrationDiscovery/components/IntegrationDiscoveryCard/IntegrationDiscoveryCard";
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";
import DiscoveryMenuActionButtons from "../../../containers/integrationDiscovery/components/DiscoveryMenuActionButtons/DiscoveryMenuActionButtons";
import IntegrationSyncBadge from "../singleIntegrationItem/IntegrationSyncBadge";
import { getAllIntegrationsByTypeDiscovery, runFullScan, scanAllIntegrationsByType, scanIntegrationAssetsByType, triggerAzureFetching } from "../../../redux/actions/integrationsActions";
import { alreadyScanned } from "../../../containers/dashboard/utils";
import { appToast } from "../../../shared/appToast/appToast";
import { IAC_TYPES, PROVIDERS, RESOURCE_STATE_TYPES, CLOUD_PROVIDERS } from "../../../consts/general";
import { ReactComponent as NoTaggingCoverage } from "../../../Images/general_icons/governance/noTaggingCoverage.svg";
import { useTranslation } from "react-i18next";
import sortBy from "lodash/sortBy";
import { getTotalAssetsLastSynced } from "../../../utils/helpers";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";
import { setFilters as setIacFilters } from "../../../redux/actions/iacStacksActions";
import PercentageBar from "../../../shared/percentageBar/percentageBar";
import { sendEvent } from "../../../utils/amplitude";
import { integrationEvents } from "../../../utils/amplitudeEvents";
import LottieLoadingBar from "../../inventory/inventoryCharts/lottieLoadingBar";
import "./SingleIntegrationTable.scss";
import ConnectedServices from "../../dashboard/connectedServices/connectedServices";
import { getConnectedServices } from "../../../redux/actions/dashboardActions";

const STATUSES = [RESOURCE_STATE_TYPES.managed, RESOURCE_STATE_TYPES.ghost, RESOURCE_STATE_TYPES.unmanaged, RESOURCE_STATE_TYPES.modified];

const SingleIntegrationTable = ({ integrationsData, category, providerType, loading, onDeleteClick, handleEdit, handleSetPage, currentPage, handleSortChange, pageSize, onPageSizeChange }) => {
    const { t } = useTranslation(["common", "integrations"]);
    const [discoveryData, setDiscoveryData] = useState();
    const [loadingServices, setLoadingServices] = useState(false);
    const [discoveryLoading, setDiscoveryLoading] = useState(false);
    const [fullScanLoading, setFullScanLoading] = useState(false);

    const integrations = integrationsData.integrations;

    const totalAssetsLastSync = getTotalAssetsLastSynced(integrations);

    const account = useSelector((state) => state.onbordingReducer.account);
    const connectedServices = useSelector((state) => state.dashboardReducer.connectedServices);

    const isInitialScanComplete = account?.initial_scan_complete;
    const isK8sOrAWS = providerType === CLOUD_PROVIDERS.k8s || providerType === CLOUD_PROVIDERS.aws;
    const isK8sProvider = providerType === CLOUD_PROVIDERS.k8s;

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        handleFetchIntegrations();
    }, []);
    useEffect(() => {
        if (isEmpty(connectedServices) && !isEmpty(integrations) && isK8sOrAWS) {
            handleFetchConnectedServices();
        }
    }, [integrations]);

    const handleFetchConnectedServices = async () => {
        setLoadingServices(true);
        await dispatch(getConnectedServices());
        setLoadingServices(false);
    };

    const handleFetchIntegrations = async () => {
        setDiscoveryLoading(true);
        const response = await dispatch(getAllIntegrationsByTypeDiscovery(providerType));
        setDiscoveryData(response);
        setDiscoveryLoading(false);
    };
    const handleScanAllIntegrations = async () => {
        const localLastScanned = localStorage.getItem(`integrations-fullScan-${providerType}`);
        if (alreadyScanned(localLastScanned)) {
            return appToast("info", "", t("common:scanning.error"));
        }
        setFullScanLoading(true);
        const scanResult = await dispatch(scanAllIntegrationsByType(providerType));
        if (scanResult) {
            appToast("success", "", t("common:scanning.new-scan"));
            localStorage.setItem(`integrations-fullScan-${providerType}`, moment().unix());
        } else {
            appToast("error", "", "Field to scan integrations");
        }
        setFullScanLoading(false);
    };
    const handleViewIacStack = (e, rowData = {}) => {
        e.stopPropagation();
        const { _id: integrationId = "", stacks = {} } = rowData;
        const isStacks = stacks.doc_count > 0;
        if (!isStacks) return;
        const isCloudFormation = stacks.cloudformation?.doc_count > 0;
        const isTerraform = stacks.terraform?.doc_count > 0;
        const filters = {
            ...emptyIacScreenFilters,
            "stackProviders.providerIntegrationId": [integrationId],
        };
        filters.stateType = [];
        if (isCloudFormation) {
            filters.stateType.push[IAC_TYPES.cloudformation];
        }
        if (isTerraform) {
            filters.stateType.push[(IAC_TYPES.terraform, IAC_TYPES.opentofu)];
        }
        dispatch(setIacFilters(filters));
        return history.push({
            pathname: `/iac`,
            search: `?view=${isTerraform ? IAC_TYPES.terraform : isCloudFormation ? IAC_TYPES.cloudformation : ""}`,
        });
    };

    const handleScanIntegration = async (integrationId, scanType) => {
        const localLastFetched = localStorage.getItem(`Integration-${scanType}-Scan-${integrationId}`);
        const isAlreadyScanned = alreadyScanned(localLastFetched);
        if (!isAlreadyScanned) {
            let provider = providerType;
            let remoteType;
            if (providerType === PROVIDERS.azurerm) provider = remoteType = PROVIDERS.azure;

            let scanResult;
            switch (scanType) {
                case "assets":
                    if (providerType === PROVIDERS.azurerm) {
                        scanResult = await dispatch(triggerAzureFetching(integrationId));
                    } else {
                        scanResult = await dispatch(scanIntegrationAssetsByType(provider, integrationId));
                    }
                    break;
                case "stacks":
                    scanResult = await dispatch(runFullScan(integrationId, remoteType));
                    break;
            }
            if (scanResult) {
                localStorage.setItem(`Integration-${scanType}-Scan-${integrationId}`, moment().unix());
                appToast("success", "", t("common:scanning.new-scan"));
            }
        } else {
            return appToast("info", "", t("common:scanning.error"));
        }
    };

    const handleNavigateToInventory = (columnType, integration) => {
        let searchUrl = `?integration=${integration?._id}`;
        if (columnType === "taggingCoverage") {
            searchUrl += "&taggingStatus=NOT_TAGGED";

            sendEvent(integrationEvents.taggingCoverage, {
                page: `${providerType} integration`,
            });
        }

        history.push({
            pathname: `/inventory`,
            search: searchUrl,
        });
    };

    const formatLastSyncDatetime = (datetime) => {
        if (!datetime) return "-";
        return moment(datetime).format(dateTimeFormat);
    };

    const isProviderAzureRM = providerType === PROVIDERS.azurerm;

    const columns = compact([
        {
            headerName: t(`integrations:discovery.table.headers.${isProviderAzureRM ? "subscription-name" : "account-name"}`),
            field: "name",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            sortable: true,
            renderCell: ({ row }) => {
                return <div>{row?.name}</div>;
            },
        },
        !isK8sProvider && {
            headerName: t(`integrations:discovery.table.headers.${isProviderAzureRM ? "subscription-id" : "account-id"}`),
            field: "accountNumber",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            sortable: true,
            renderCell: ({ row }) => {
                return <div>{row?.accountNumber}</div>;
            },
        },
        {
            headerName: t("integrations:discovery.table.headers.iac-coverage"),
            field: "iac",
            filterable: false,
            disableColumnMenu: true,
            width: 165,
            sortable: false,
            renderCell: ({ row }) => {
                const bucketsByStatuses = (row?.discovery?.coverage?.buckets ?? []).filter((b) => STATUSES.includes(b.key));
                const sortedBuckets = sortBy(bucketsByStatuses ?? [], (bucket) => STATUSES.indexOf(bucket.key));
                const totalCount = sortedBuckets?.reduce((acc, bucket) => (acc += bucket?.doc_count), 0);
                return !isInitialScanComplete ? (
                    <LottieLoadingBar isIntegrationScreen={true} />
                ) : (
                    <div className="SingleIntegrationTable__coverage">
                        <Popover
                            title="IaC Coverage"
                            content={
                                <ul className="SingleIntegrationTable__coverage__tooltip">
                                    {map(sortedBuckets, (item) => {
                                        return (
                                            <li className={item?.key} key={item.key}>
                                                <span className={item?.key}></span>
                                                <span>{formatIacStatus(item.key)}</span>
                                                <span>{((item?.doc_count / totalCount) * 100).toFixed(2)}%</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            }
                        >
                            <ul className="SingleIntegrationTable__coverage__progress">
                                {map(sortedBuckets, (item) => {
                                    return (
                                        <li
                                            key={item.key}
                                            className={item?.key}
                                            style={{
                                                width: `${(item?.doc_count / totalCount) * 100}%`,
                                            }}
                                        ></li>
                                    );
                                })}
                            </ul>
                        </Popover>
                    </div>
                );
            },
        },
        !isK8sProvider &&
            CLOUD_PROVIDERS[providerType] && {
                headerName: t("integrations:discovery.table.headers.tagging-coverage"),
                field: t("integrations:discovery.table.headers.tagging-coverage"),
                flex: 1,
                filterable: false,
                align: "center",
                disableColumnMenu: true,
                sortable: false,
                renderCell: ({ row }) => {
                    return (
                        <div className="SingleIntegrationTable__wrapper">
                            <div className="SingleIntegrationTable__percentage-bar-style">
                                <PercentageBar count={row?.taggingCoverage?.count} total={row?.taggingCoverage?.total} tooltipTitle={t("integrations:discovery.table.cell-tooltip.tagging-coverage")} />
                            </div>
                            <Tooltip title={`${t("integrations:discovery.table.cell-tooltip.icon-untagged")} ${row?.taggingCoverage?.total - row?.taggingCoverage?.count || 0}`}>
                                <div className="SingleIntegrationTable__percentage-no-tagging-style" onClick={() => handleNavigateToInventory("taggingCoverage", row)}>
                                    <NoTaggingCoverage width="18px" height="18px" />
                                </div>
                            </Tooltip>
                        </div>
                    );
                },
            },
        {
            headerName: t("integrations:discovery.table.headers.discovered-assets"),
            field: "assets",
            flex: 1,
            align: "center",
            filterable: false,
            disableColumnMenu: true,
            sortable: true,
            renderCell: ({ row }) => {
                const assets = row?.discovery?.doc_count;
                return (
                    <div className={`SingleIntegrationTable__${assets ? "badge pointer" : ""}`} onClick={() => handleNavigateToInventory("discoveredAssets", row)}>
                        {assets ? (
                            <span className="row g8">
                                <FontAwesomeIcon icon="layer-group" /> {assets}
                            </span>
                        ) : (
                            "-"
                        )}
                    </div>
                );
            },
        },
        {
            headerName: t("integrations:discovery.table.headers.scanning-status"),
            field: "status",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            sortable: true,
            renderCell: ({ row }) => {
                return <IntegrationSyncBadge data={row} type={providerType} category={category} />;
            },
        },
        ...(isK8sOrAWS
            ? [
                  {
                      headerName: t(`integrations:discovery.table.headers.integrated-clouds-${providerType}`),
                      field: "clouds",
                      flex: 1,
                      filterable: false,
                      disableColumnMenu: true,
                      sortable: false,
                      renderCell: ({ row }) => {
                          const idToSearch = row._id || "";
                          const providerServices = connectedServices?.[providerType] || [];
                          const connectedServicesData = providerServices.filter((service) => service?.integrationIds?.includes(idToSearch));
                          return <ConnectedServices provider={providerType} disableOnClick={true} outerData={connectedServicesData} />;
                      },
                  },
              ]
            : []),
        {
            headerName: t("integrations:discovery.table.headers.discovered-stacks"),
            field: "stacks",
            flex: 1,
            filterable: false,
            align: "center",
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) => {
                const stacks = row?.stacks?.doc_count;
                return (
                    <div className={`SingleIntegrationTable__${stacks > 0 ? "badge pointer" : ""}`} onClick={(e) => handleViewIacStack(e, row)}>
                        {stacks > 0 ? (
                            <span className="row g8">
                                <IacStack /> {stacks}
                            </span>
                        ) : (
                            "-"
                        )}
                    </div>
                );
            },
        },
        {
            headerName: t("integrations:discovery.table.headers.last-stacks-scan"),
            field: "lastScan",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) => {
                return <div>{formatLastSyncDatetime(row?.stacks?.lastSync?.value)}</div>;
            },
        },
        {
            filterable: false,
            disableColumnMenu: true,
            align: "right",
            width: 120,
            sortable: false,
            renderCell: ({ id, row, api }) => {
                const handleInnerScan = async (scanType, integrationId) => {
                    api.updateRows([{ _id: id, loading: true }]);
                    await handleScanIntegration(integrationId, scanType);
                    api.updateRows([{ _id: id, loading: false }]);
                };
                return (
                    <div className="SingleIntegrationTable__actions">
                        <div>
                            <Tooltip title={`Event-Driven ${row?.isEventDriven ? "Enabled" : "Disabled"}`}>{row?.isEventDriven ? <EventDriven className="SingleIntegrationItem__actions-flags-event" /> : <NonEventDriven className="SingleIntegrationItem__actions-flags-event" />}</Tooltip>
                        </div>
                        <div>
                            <Tooltip title={`${!row?.isProd ? "Non " : ""}Production Account`}>{row?.isProd ? <FontAwesomeIcon icon={faFlag} className="SingleIntegrationItem__actions-flags-prod" /> : <NonProdIcon />}</Tooltip>
                        </div>
                        <div>
                            <DiscoveryMenuActionButtons rowData={row} loading={row.loading} handleDeleteClick={() => onDeleteClick(row)} handleEditClick={handleEdit} handleScanAssetsClick={() => handleInnerScan("assets", row?._id)} handleScanStacksClick={() => handleInnerScan("stacks", row?._id)} providerType={providerType} />
                        </div>
                    </div>
                );
            },
        },
    ]);

    return (
        <>
            <div className="SingleIntegrationDiscoveryCards">
                <IntegrationDiscoveryCard title={t("integrations:discovery.cards.assets")} icon={<FontAwesomeIcon icon="layer-group" />} displayCount={numberWithCommas(discoveryData?.totalAssets)} isLoading={discoveryLoading} lastScanDate={formatLastSyncDatetime(totalAssetsLastSync)} />
                <IntegrationDiscoveryCard title={t("integrations:discovery.cards.stacks")} icon={<StacksIcon />} displayCount={numberWithCommas(discoveryData?.totalStacks)} isLoading={discoveryLoading} lastScanDate={formatLastSyncDatetime(discoveryData?.totalStacksLastSync?.value)} />
                <IntegrationDiscoveryCard title={t("integrations:discovery.cards.accounts")} icon={<ProjectsIcon />} displayCount={discoveryData?.totalIntegrations} isLoading={discoveryLoading} onScanHandler={handleScanAllIntegrations} scanLoading={fullScanLoading} scanText={t("integrations:discovery.cards.scan-all")} />
            </div>
            {loadingServices ? (
                <LottieLoadingBar isIntegrationScreen={true} />
            ) : (
                <TableWrapper
                    rowKey="_id"
                    tableData={integrations}
                    columns={columns}
                    disableSelectionOnClick
                    tableWrapperClassName="SingleIntegrationTableWrapper"
                    className="SingleIntegrationTable"
                    headerHeight={45}
                    rowHeight={44}
                    serverSide
                    height={600}
                    pageSize={pageSize}
                    rowCount={integrationsData?.total}
                    autoHeight={(integrations?.length ?? 0) <= 6}
                    loading={loading}
                    handlePageChange={handleSetPage}
                    controlledPage={currentPage}
                    handleSortChange={handleSortChange}
                    rowsPerPageOptions={[10, 50, 150]}
                    onPageSizeChange={onPageSizeChange}
                />
            )}
        </>
    );
};

export default SingleIntegrationTable;
