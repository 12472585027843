import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import LinkSubMenu from "./linkSubMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as MatchingRules } from "../../../Images/general_icons/classification.svg";
import { ReactComponent as DashboardIcon } from "../../../Images/general_icons/sidebar/dashboard.svg";
import { ReactComponent as InventoryIcon } from "../../../Images/general_icons/sidebar/inventory.svg";
import { ReactComponent as EventCenterIcon } from "../../../Images/general_icons/sidebar/event-explorer.svg";
import { ReactComponent as AnalyticsIcon } from "../../../Images/general_icons/sidebar/analytics.svg";
import { ReactComponent as NotificationIcon } from "../../../Images/general_icons/sidebar/notification.svg";
import { ReactComponent as CiPipelineIcon } from "../../../Images/general_icons/sidebar/ci-pipeline.svg";
import { ReactComponent as BackupIcon } from "../../../Images/general_icons/sidebar/backup.svg";
import { ReactComponent as Yak } from "../../../Images/general_icons/yak.svg";
import  {ReactComponent as FavoriteIcon} from "../../../Images/general_icons/sidebar/favorite.svg";
import { generalEvents } from '../../../utils/amplitudeEvents';
import { sendEvent } from '../../../utils/amplitude';
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import { handleToggleProvisioning } from "../../../redux/actions/globalAppActions";
import "./navLinks.scss";

const NavLinks = () => {
  const { t } = useTranslation("sidebar");
  const sidebarClosed = useSelector((state) => state.userPreferencesReducer.sidebarClosed);
  const activeFeatures = useSelector((state) => state.activeFeaturesReducer.activeFeatures) || [];
  const dispatch = useDispatch();

  const isBackupEnabled = activeFeatures.find((feature = {}) => feature.path === '/backup-dr');
  const isEventCenterEnabled = activeFeatures.find((feature = {}) => feature.path === '/event-center') || false;

  const sections = {
    dashboard: "dashboard",
    iacManagement: "iacManagement",
    assetManagement: "assetManagement",
    general: "general",
  }

  const navLinks = [
    {
      text: t("dashboard"),
      url: "/dashboard",
      icon: <DashboardIcon />,
      section: sections.dashboard,
      exact: false,
      permission: true,
    },
    {
      text: t("workflows"),
      icon: <CiPipelineIcon />,
      exact: false,
      section: sections.iacManagement,
      permission: true,
      defaultUrl: "/workflows",
      children: [
        {
          text: t("workspaces"),
          url: "/workflows",
          exact: true,
        },
        {
          text: t("guardrails"),
          url: "/workflows/guardrails",
          exact: true,
        },
        {
          text: t("self-service"),
          url: "/workflows/self-service",
          exact: true,
          onClick: () => dispatch(handleToggleProvisioning())
        }
      ],
    },
    {
      text: t("iac-explorer"),
      url: "/iac",
      icon: <Yak />,
      section: sections.iacManagement,
      exact: false,
      permission: true,
    },
    {
      text: t("inventory"),
      url: "/inventory",
      icon: <InventoryIcon />,
      section: sections.assetManagement,
      exact: false,
      permission: true,
    },
    {
      text: t("insights"),
      url: "/governance",
      icon: <MatchingRules />,
      section: sections.assetManagement,
      exact: false,
      permission: true,
    },
    {
      text: t("settings.backup"),
      url: "/backup",
      icon: <BackupIcon />,
      exact: false,
      favorite: true,
      section: sections.assetManagement,
      permission: isBackupEnabled,
    },
    {
      text: t("event-center"),
      url: "/event-center",
      icon: <EventCenterIcon />,
      section: sections.assetManagement,
      exact: false,
      permission: isEventCenterEnabled,
    },
    {
      text: t("settings.analytics"),
      url: "/analytics",
      icon: <AnalyticsIcon />,
      section: sections.general,
      exact: false,
      permission: true,
    },
    {
      text: t("settings.notifications"),
      url: "/notifications",
      icon: <NotificationIcon />,
      section: sections.general,
      exact: false,
      permission: true,
    },
    {
      text: t("settings.title"),
      icon: <FontAwesomeIcon icon="cog" />,
      section: sections.general,
      exact: false,
      permission: true,
      defaultUrl: "/integrations",
      children: [
        {
          text: t("settings.excluded-assets"),
          url: "/iac-ignored-rules",
          exact: false,
        },
        {
          text: t("settings.excluded-drifts"),
          url: "/excluded-drifts",
          exact: false,
        },
        {
          text: t("settings.integrations"),
          url: "/integrations",
          exact: false,
        },
        {
          text: t("settings.users"),
          url: "/users",
          exact: false,
        },
      ],
    },
  ];

  const groupBySections = _.groupBy(navLinks, 'section');
  const ordered = [sections.dashboard, sections.iacManagement, sections.assetManagement, sections.general].reduce((acc, section) => {
    acc.push({ key: section, links: groupBySections[section], withTitle: [sections.iacManagement, sections.assetManagement].includes(section) });
    return acc;
  }, []);
  
  const renderSections = () => {
    return ordered.map(({ key, links = [], withTitle } = {}, ind) => {
      const isNotLastIndex = ind < ordered.length - 1;
      return (
        <div key={key} className="NavLinks__section col">
          {withTitle && <div className="NavLinks__section-title">{t(`sections.${key}`)}</div>}
          {renderNavLinks(links)}
          {isNotLastIndex && <div className="NavLinks__section-line" />}
        </div>
      );
    });
  };

  const renderNavLinks = (arr) => {
    return arr.map((item, index) => {
      if (item?.permission) {
        if (item?.children) {
          return (
            <LinkSubMenu
              icon={item?.icon}
              text={item?.text}
              key={item.url + item.text}
              sidebarClosed={sidebarClosed}
              defaultUrl={item.defaultUrl}
              children={item?.children}
            />
          );
        }
        return (
          <NavLink
            to={item.url}
            key={item.url + item.text}
            className={`NavLinks__item ${sidebarClosed && "centered"}`}
            activeClassName="activeNavLink"
            exact={item.exact}
            isActive={item.isActive}
            onClick={() => sendEvent(generalEvents.pageClick, { page: item?.text })}
          >
            <Tooltip title={sidebarClosed ? item.text : ''} placement="right">
              <span className="NavLinks__item-icon">{item.icon}</span>
            </Tooltip>
            {!sidebarClosed && (
              <>
              <div className="NavLinks__withNew row">
                <span className="NavLinks__item-text">{item.text}</span>
                {item?.new && <span className="NavLinks__item-new">Soon</span>}
              </div>
              {item?.favorite && <FavoriteIcon className="NavLinks__withNew-favorite" />}
              </>
            )}
          </NavLink>
        );
      }
    });
  };

  return (
  <div className="NavLinks">
    {sidebarClosed ? renderNavLinks(navLinks) : renderSections()}
  </div>);
};

export default NavLinks;
