import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import uniq from 'lodash/uniq';
import Loading from '../../../../shared/loading/loading';
import { useDispatch, useSelector } from 'react-redux';
import { getCodeFiles, getCodeOrPlanScanRemediation } from '../../../../redux/actions/ciWorkflowsActions';
import AppEmpty from '../../../../shared/appEmpty/appEmpty';
import { ReactComponent as FolderIcon } from "../../../../Images/general_icons/Folder_icon.svg";
import { ReactComponent as Chevron } from "../../../../Images/general_icons/chevron.svg";
import CodeDiff from '../../../../shared/codeDiff/codeDiff';
import { useTranslation } from 'react-i18next';
import CodeScanResult from './codeScanResult';
import RemediationAllBtn from '../../remediationAllBtn/remediationAllBtn';
import { sendEvent } from '../../../../utils/amplitude';
import { aiRemediationEvents } from '../../../../utils/amplitudeEvents';
import './ciCode.scss';

const { Panel } = Collapse;
const severityOrder = { high: 1, medium: 2, low: 3 };
const CiCode = ({ codeId = '', filesCount = 0, defaultSettings , clearDefaultSettings = () => null }) => {
    const [loading, setLoading] = useState(false);
    const [activeFile, setActiveFile] = useState({});
    const [allRemedLoading, setAllRemedLoading] = useState(false);
    const [openRemediationByDefault, setOpenRemediationByDefault] = useState(false);

    const { t } = useTranslation("ci-pipeline");
    
    const codeFiles = useSelector((state) => state.ciWorkflowsReducer.codeFiles);
    const folders = Object.keys(codeFiles) || [];
    const [activeFolder, setActiveFolder] = useState("");
    const allFiles = Object.values(codeFiles).flat();
    const isCodeScanResultInFiles = allFiles?.find((file = []) => file?.codeScanResults?.length > 0);

    const dispatch = useDispatch();

    const fetchCodeFiles = async () => {
        setLoading(true);
        const response = await dispatch(getCodeFiles(codeId));
        if (!isEmpty(response)) {
            const keys = Object.keys(response);
            setActiveFolder(keys[0]);
        }
        setLoading(false);
    };
    
    useEffect(() => {
        if (codeId && filesCount > 0) {
            fetchCodeFiles();
        }
        return () => clearDefaultSettings();
    }, []);
    
    useEffect(() => {
        const shouldLinkLine = !isEmpty(defaultSettings) && !isEmpty(codeFiles) && !loading;
        if (shouldLinkLine) {
            const { fileName, line } = defaultSettings;
            const folder = fileName.split('/')[0];
            const file = codeFiles[folder].find((file) => file.filePath === fileName) || {};
            const { id } = file;
            if (!id) return;
            setActiveFile(file);
            setActiveFolder(folder);
            onClickPolicyViolation(null, line, id);
        }
    }, [codeFiles, loading, defaultSettings]);

    const handleRemediationAllClick = async() => {
        if (openRemediationByDefault) return;
        setAllRemedLoading(true);
        const allCodeScanResults = (allFiles || []).map(((file = {}) => (file.codeScanResults || []).map((scan) => scan?.id))).flat();
        const uniqResults = uniq(allCodeScanResults);
        const onlyTheFirst50 = uniqResults.slice(0, 50);
        const payload = { codeScans: onlyTheFirst50, taskId: codeId };
        await dispatch(getCodeOrPlanScanRemediation(payload, codeId));
        setOpenRemediationByDefault(true);
        setAllRemedLoading(false);
        sendEvent(aiRemediationEvents.clickedWorkflowPolicyViolationsAll, { origin: "code" });
    };

    const onFolderClick = (folder) => setActiveFolder(folder === activeFolder ? '' : folder);
    const onFileClick = (file = {}, folder) => {
        setActiveFile(file);
        setActiveFolder(folder);
        const elementToScroll = document.getElementById(file.id);
        if (elementToScroll) elementToScroll.scrollIntoView({ behavior: "smooth" });
    }
    const onClickPolicyViolation = (e, targetText, classId) => {
        e?.preventDefault();
        const divById = document.getElementById(classId);
        if (!divById) return;
        const container = divById.querySelector('.CodeDiff__diffViewer');
        if (!container) return;
        const elements = container.querySelectorAll('*') || [];
        elements.forEach((el = {}) => {
            if (el.textContent?.includes(targetText)) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center', 
                    inline: 'nearest'
                });
            }
        });
    }
    if (loading) return <Loading/>;
    if (isEmpty(codeFiles) && !loading) {
        return (
        <div className="CiCode center">
            <AppEmpty customStyle="ciResourcesNotFound" text={t("codeStep.notFoundMsg")}/>
        </div>)
    }

    return (
        <div className="CiCode">
            <div className="CiCode__folders col">
                {folders.map((folder) => {
                    const files = codeFiles[folder] || [];
                    const isActiveFolder = activeFolder === folder;
                    return (
                        <div className="CiCode__folder col g10" key={folder}>
                            <div className={`CiCode__folder-titleContainer ${isActiveFolder ? 'active' : ""} row g8`} onClick={() => onFolderClick(folder)}>
                                <Chevron className={`CiCode__folder-titleContainer-arrow ${isActiveFolder ? "active" : ""}`} />
                                <FolderIcon />
                                <span className="CiCode__folder-titleContainer-title">{folder}</span>
                            </div>
                            {isActiveFolder && <div className="CiCode__folder-files">
                                <div className='row g15'>
                                    <div className='CiCode__folder-divider'/>
                                    <div className="col g10">
                                        {files.map(( file = {}) => <span className={`CiCode__folder-files-file ${ file.fileSuffix === activeFile.fileSuffix ? 'active' : ""} `} 
                                        onClick={() => onFileClick(file, folder)} key={file.id}>{file.fileSuffix}</span>)}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    )
                })}
            </div>
            {isCodeScanResultInFiles && (
                <div className="CiCode__remediateAll">
                    <RemediationAllBtn t={t} customBtnTitle="Remediate all violations" handleRemidiationAllDiagnostics={handleRemediationAllClick} loading={allRemedLoading}/>
                </div>)}
            <div className="CiCode__content col g10">
                {allFiles.map((file = {}) => {
                    const { id = "", filePath = "", currentContent = "", previousContent = "", codeScanResults = [] } =  file;
                    const oldValue = isString(previousContent) ? previousContent : JSON.stringify(previousContent, null, 2);
                    const newValue = isString(currentContent) ? currentContent : JSON.stringify(currentContent, null, 2);
                    const hasPolicyViolations = !isEmpty(codeScanResults);
                    const sorted = codeScanResults.sort((a, b) => severityOrder[a?.severity?.toLowerCase()] - severityOrder[b.severity?.toLowerCase()]);
                    return (
                    <div key={id} id={id}>
                        <Collapse className="CiCode__content-collapse" defaultActiveKey={id}>
                            <Panel header={filePath} key={id}>
                                <div className={`CiCode__content-collapse-content ${hasPolicyViolations ? "col g15": ""}`}>
                                    {hasPolicyViolations && 
                                    <div className="CiCode__content-collapse-content-policyViolations col g15 align-items-start">
                                        {sorted.map((codeScan = {}, ind ) => <CodeScanResult codeScan={codeScan} ind={ind} 
                                        onClickPolicyViolation={onClickPolicyViolation} codeDivId={id} key={id} taskId={codeId} openRemediationByDefault={openRemediationByDefault} />)}
                                    </div>}
                                    <CodeDiff oldValue={oldValue} newValue={newValue} lang="yaml" hideLineNumbers={false} showDiffOnly={!hasPolicyViolations} />
                                </div>
                            </Panel>
                        </Collapse>
                    </div>)
            })}
            </div>
        </div>
    )
};

export default CiCode;