import { CODIFY_TYPES } from "../../../../consts/general";
import isEmpty from "lodash/isEmpty";
import { ACTIVE_CODIFICATION_TYPES, CREATE_MODULE_CODIFICATION_MODES } from "../codifyHelpers";

const onClickImportBlock = (changeCodifyAction, isImportBlocksClicked, importBlocks, terraformIacData, activeCodificationType, moduleFileSelected) => {
    const terraformClone = terraformIacData[activeCodificationType] || [];
    if (isEmpty(terraformClone)) return;
    
    let specificIndex = terraformClone.findIndex((item) => item?.filePath === moduleFileSelected);
    if (specificIndex < 0) specificIndex = 0;
    const selectedContent = terraformClone[specificIndex]?.content || "";
    if (!isImportBlocksClicked) {
        changeCodifyAction("isImportBlocksClicked", true);
        terraformClone[specificIndex].content = `${importBlocks.join("\n")}\n${selectedContent}`; //import
    }
    else {
        changeCodifyAction("isImportBlocksClicked", false);
        terraformClone[specificIndex].content = selectedContent?.replace(`${importBlocks.join("\n")}\n`, "") //destruct
    }
    changeCodifyAction("terraformIacData", { ...terraformIacData, [activeCodificationType]: terraformClone });

}

const onClickProviderBlock = (changeCodifyAction, isProviderBlockClicked, providerBlock, terraformIacData, activeCodificationType, moduleFileSelected) => {
    const terraformClone = terraformIacData[activeCodificationType] || [];
    if (isEmpty(terraformClone)) return;
    
    let specificIndex = terraformClone.findIndex((item) => item?.filePath === moduleFileSelected);
    if (specificIndex < 0) specificIndex = 0;
    const selectedContent = terraformClone[specificIndex]?.content || "";
    if (!isProviderBlockClicked) {
        changeCodifyAction("isProviderBlockClicked", true);
        terraformClone[specificIndex].content = `${providerBlock}\n${selectedContent}`; //import
    }
    else {
        changeCodifyAction("isProviderBlockClicked", false);
        terraformClone[specificIndex].content = selectedContent.replace(`${providerBlock}\n`, "") //destruct
    }
    changeCodifyAction("terraformIacData", { ...terraformIacData, [activeCodificationType]: terraformClone });
};

export const suggetsionsTitles = {
    addComments: "Add comments",
    addProviderBlock: "Add provider block",
    removeProviderBlock: "Remove provider block",
    addImportBlocks: "Add import blocks",
    removeImportBlocks: "Remove import blocks",
    addTags: "Add tags",
    addDependsOnBlocks: "Add depends_on blocks for explicit dependencies",
    addCountForEach: "Add count/for_each for resource iteration",
    addDataSourceBlocks: "Add data source blocks for existing resources",
    addLocalsBlock: "Add locals block for common variables",
    seperateToMultipleFiles: "Seperate to multiple files"
}

export const getChatSuggestions = (codifyState = {}, changeCodifyAction = () => null) => { 
    const { activeIacTab, isImportBlocksClicked, isProviderBlockClicked, importBlocks,
        terraformIacData, activeCodificationType, providerBlock, moduleFileSelected } = codifyState;
    const isTerraform = activeIacTab === CODIFY_TYPES.terraform;
    const isCreateModuleCodifications = CREATE_MODULE_CODIFICATION_MODES.includes(activeCodificationType);
    return [
    {
        title: suggetsionsTitles.addComments,
        onClick: () => null,
        isAiPrompt: true
    },
    {
        title: isProviderBlockClicked ? suggetsionsTitles.removeProviderBlock : suggetsionsTitles.addProviderBlock,
        onClick: () => onClickProviderBlock(changeCodifyAction, isProviderBlockClicked, providerBlock, terraformIacData, activeCodificationType, moduleFileSelected),
        disabled: !isTerraform || !providerBlock || isCreateModuleCodifications,
        successMessage: isProviderBlockClicked ? "Removed provider block" : "Added provider block"
    },
    {
        title: isImportBlocksClicked ? suggetsionsTitles.removeImportBlocks : suggetsionsTitles.addImportBlocks,
        onClick: () => onClickImportBlock(changeCodifyAction, isImportBlocksClicked, importBlocks, terraformIacData, activeCodificationType, moduleFileSelected),
        disabled: !isTerraform || !importBlocks || isCreateModuleCodifications,
        successMessage: isImportBlocksClicked ? "Removed import blocks" : "Added import blocks"
    },
    {
        title: suggetsionsTitles.seperateToMultipleFiles,
        onClick: () => null,
        isAiPrompt: true
    },
    {
        title: suggetsionsTitles.addTags,
        onClick: () => null,
        isAiPrompt: true
    },
    {
        title: suggetsionsTitles.addDependsOnBlocks,
        onClick: () => null,
        isAiPrompt: true
    },
    {
        title: suggetsionsTitles.addCountForEach,
        onClick: () => null,
        isAiPrompt: true
    },
    {
        title: suggetsionsTitles.addDataSourceBlocks,
        onClick: () => null,
        isAiPrompt: true
    },
    {
        title: suggetsionsTitles.addLocalsBlock,
        onClick: () => null,
        isAiPrompt: true
    }
  ]
};

export const getCurrentDataCodeForPrompt = ({ activeTab, activeCodificationType, compositionData, moduleCallData, createModuleData, mapData }) => {
    if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules) {
        return moduleCallData || {};
    }
    if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.createModule) {
        return createModuleData || {};
    }
    let currentData = mapData[activeTab]
    if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.createComposition) {
        currentData = compositionData;
    }
    const currentCode = (currentData || []).reduce((acc, item = {}) => {
      acc[item.filePath] = item.content;
      return acc;
    }, {});
    return currentCode;
}