import action from "../middleware";

import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";
import { ONBORDING_STATUS_NAMES } from "../../consts/onbording";

export const GET_ACCOUNT_INTEGS = "GET_ACCOUNT_INTEGS";
export const GET_ONBORDING_STATUS = "GET_ONBORDING_STATUS";
export const SET_STATUS = "SET_STATUS";
export const GET_SCAN_STATUS = "GET_SCAN_STATUS";

const getOnbordingStatus = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/onboarding`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_ONBORDING_STATUS,
      payload: data,
    });
    return data;
  });
};

const handleSetStep = (status, isSandboxClick) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    if (!isSandboxClick) {
      dispatch({
        type: SET_STATUS,
        payload: status,
      });
  }
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/onboarding`,
      "POST",
      { status: ONBORDING_STATUS_NAMES[status - 1] }
    );

    const data = await req.json();
    return data;
  });
};

const getAccountIntegrations = (fields) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    let url = `${BACKEND_URL}/onboarding/integrations`
    if (fields) {
      url += `?fields=${fields}`
    }
    const req = await requestWrapper.sendRequest(url, "GET");

    const data = await req.json();

    dispatch({
      type: GET_ACCOUNT_INTEGS,
      payload: data,
    });
    return data;
  });
};

const sendGetRequest = async (url) => {
  const requestWrapper = new RequestWrapper();
  const req = await requestWrapper.sendRequest(url, "GET");

  let data = req.ok ? await req.json() : {};

  return data;
};

const getScanStatus = () => {
  return action(async (dispatch) => {
    const data = await sendGetRequest(`${BACKEND_URL}/onboarding/init-scan`);

    dispatch({
      type: GET_SCAN_STATUS,
      payload: data,
    });

    return data;
  });
};

export { getAccountIntegrations, handleSetStep, getOnbordingStatus, getScanStatus };
