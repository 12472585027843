import React, { useEffect, useState } from "react";
import { Collapse, Radio } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { TablePagination } from "@mui/material";
import "./aiRemediationVc.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AiRemediationVcTable from "./table/aiRemediationVcTable";
import { useDispatch, useSelector } from "react-redux";
import { clearAiRemediationData, getAiRemediationCode } from "../../../../redux/actions/aiRemediationActions";
import Loading from "../../../../shared/loading/loading";
import LongLoader from "../../../../shared/longLoader/longLoader";
import TypingDots from "../../../../shared/typingDots/typingDots";
import { formatUrl, getIcon } from "./aiRemediation.utils";
import HeaderSearchBox from "../../../../shared/headerSearchBox/headerSearchBox";
import FilterTag from "../../insightsHeader/insightFilterBadges/filterTag";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";

const AiRemediationVc = ({ data, setSlectedPath, selectedPath, loadingFix }) => {
    const [activeKey, setActiveKey] = useState(null);
    const [vcPage, setVcPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const dispatch = useDispatch();

    const codeData = useSelector((state) => state.aiRemediationReducer?.code || {});

    const afterKey = useSelector((state) => state.aiRemediationReducer?.afterKeyCode || {});

    const totalFiles = useSelector((state) => state.aiRemediationReducer?.totalFiles);

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

    const classificationsId = data?._id;

    useEffect(() => {
        dispatch(clearAiRemediationData());
        setVcPage(0);
        setSlectedPath("")
    }, [rowsPerPage, searchValue]);

    useEffect(() => {
        let skip = 0;
        if (vcPage > 0) {
            skip = afterKey[vcPage - 1] || "";
        }
        fetchCode(skip);
    }, [rowsPerPage, vcPage, searchValue]);

    const fetchCode = async (skip) => {
        setLoading(true);
        const response = await dispatch(getAiRemediationCode(classificationsId, rowsPerPage, skip, searchValue));
        if (response?.abort) return;
        setLoading(false);
    };

    const { Panel } = Collapse;

    const onChangeRadio = (e) => {
        setSlectedPath(e?.target?.value);
    };

    const onCollapseChange = (key = []) => {
        setActiveKey(key);
    };

    const handleSetRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setVcPage(0);
    };

    const handleOpenLink = (e, link) => {
        e?.stopPropagation();
        if (link) return window.open(link, "_blank");
    };

    const renderHeader = (icon, title, count, value) => (
        <div className="panel-header" onClick={() => setSlectedPath(value)}>
            <div className="panel-header-content">
                <Radio value={value} checked={selectedPath === value} onChange={onChangeRadio} onClick={(e) => e.stopPropagation()} />
                <span className="panel-icon">{icon}</span>
                <span className="panel-title" dangerouslySetInnerHTML={{ __html: title }}></span>
                <span className="panel-link g5 row" onClick={(e) => handleOpenLink(e, value)}>
                    <FontAwesomeIcon icon="external-link-alt" size="sm" />
                </span>
                <span className="panel-count purple-flag g5 row">
                    <FontAwesomeIcon icon="layer-group" className="purple-text" />
                    {count}
                </span>
            </div>
            <RightOutlined className="panel-arrow-icon" />
        </div>
    );

    const handleSetSearchVal = (val) => {
        return setSearchValue(val);
    };

    const updateFilters = (key) => {
        return setSearchValue("");
    };

    const codeDataFlat = Object.entries(codeData).flatMap(([vcId, items]) => items);

    const isDataExist = !!codeDataFlat?.length;

    const isCodeDataPagination = (totalFiles || 0) > 10;

    if (loadingFix) {
        return (
            <div className="center basic-100">
                <LongLoader
                    customLoader={<Loading />}
                    duration={10000}
                    loading={loadingFix}
                    msg1={
                        <span className="row g8">
                            Generating remediation <TypingDots isInText />
                        </span>
                    }
                    msg2={
                        <span className="row g8">
                            Hold on, it is almost ready <TypingDots isInText />
                        </span>
                    }
                    customClassName="col g10 center font-18 bold"
                />
            </div>
        );
    }

    return (
        <div className="AiRemediationVc col g15">
            <div className="AiRemediationVc__header">
                <span className="font-20 bold">IaC Patch</span>
                <HeaderSearchBox placeholder="Search" value={handleSetSearchVal} currentValue={searchValue} resetSearch={handleSetSearchVal} />
                {searchValue && <FilterTag value={searchValue} onClose={updateFilters} />}
            </div>
            {loading ? (
                <Loading />
            ) : isDataExist ? (
                <>
                    {codeDataFlat.map((item) => (
                        <Collapse onChange={onCollapseChange} key={item.vcsCodeFileLink}>
                            <Panel
                                header={renderHeader(getIcon(item.vcsType, themeDark), formatUrl(item.vcsCodeFileLink), item.assetCount, item.vcsCodeFileLink)}
                                key={item.vcsCodeFileLink}
                            >
                                <AiRemediationVcTable
                                    classificationsId={classificationsId}
                                    pathCollapse={item.vcsCodeFileLink}
                                    totalAssets={item.assetCount}
                                    integrationId={item.integrationId}
                                />
                            </Panel>
                        </Collapse>
                    ))}
                    {isCodeDataPagination && (
                        <TablePagination
                            component="div"
                            className="AiRemediationVc__pagination"
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            onRowsPerPageChange={handleSetRowsPerPage}
                            count={totalFiles}
                            page={vcPage}
                            onPageChange={(e, page) => setVcPage(page)}
                        />
                    )}
                </>
            ) : (
                <div className="AiRemediationVc__no-data center col g10">
                    <AppEmpty customStyle="code" />
                    <div className="row g5 font-15">
                        <span>No results for</span>
                        <span className="bold">{searchValue}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AiRemediationVc;
