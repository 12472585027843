import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import isFunction from "lodash/isFunction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as SendMessage } from "../../../../Images/general_icons/send_msg_gradient.svg";
import { ReactComponent as ThinkerbellIcon } from "../../../../Images/general_icons/thinkerbell/thinkerbell_logo.svg";
import { ReactComponent as ThinkerbellStars } from "../../../../Images/general_icons/thinkerbell/thinkerbell_stars.svg";

import { Input } from 'antd';
import { setInventoryFiltersByPrompt } from '../../../../redux/actions/inventoryv3Actions';
import TypingDots from '../../../../shared/typingDots/typingDots';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import './filtersChat.scss';

const FAILURE_MESSAGES = ["Coludn't find it. Another go?", "No results. Give it another shot.", "Oops, nothing there. Retry?", "Nothing found. Please try again."];
const SUCCESS_MESSAGES = ["Done! Here’s what we found for you.", "Search complete! Check out these results.", "Success! Take a look at your search results."];
const PLACEHOLDER_DEFAULT = "Ask me about your cloud assets...";
const THINKER_CREATOR = "Thinkerbell AI";
/**
 * 
 * @param {*} handleClose - handle close chat
 * @param {*} visible - is chat visible
 * @param {*} customClassName - custom class name
 * @param {*} placeholder - input placeholder
 * @param {*} onNewMessageHandler - custom function to handle new message
 * @param {*} customMsgsAddition - custom component to add messages // quick actions
 * @param {*} startMessage - start message of the chat
 * @param {*} disableChat - disable chat flag
 */
const FiltersChat = ({ handleClose, visible, customClassName = "", placeholder = PLACEHOLDER_DEFAULT, onNewMessageHandler, customMsgsAddition, startMessage = "", disableChat = false }) => {
    const { user } = useAuth0();
    const [messages, setMessages] = useState([]);
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const navigationSearchValue = useSelector((state) => state.navigationReducer.searchValue);

    const isConversationDate = messages?.[0]?.createdAt;

    const isCustomNewMessageFunction = isFunction(onNewMessageHandler);
    const CustomMsgsComponent = customMsgsAddition;

    useEffect(() => {
        if (startMessage) {
            setMessages([{ text: startMessage, isUser: false, createdAt: moment(), creator: THINKER_CREATOR, loadMessage: true }]);
            setTimeout(() => {
                setMessages([{ text: startMessage, isUser: false, createdAt: moment(), creator: THINKER_CREATOR }]);
            }, 1300);
        }
    }, []);

    useEffect(() => {
        if (messages.length || !visible) return;
        if (visible) {
            window.addEventListener('keydown', (e) => e.key === 'Escape' && handleClose());
        }
    }, [visible]);

    useEffect(() => {
        const shouldScrollDown = isCustomNewMessageFunction && messages.length > 2;
        if (shouldScrollDown) {
            const chat = document.getElementById('aiChat');
            if (!chat) return;
            chat.scrollTop = chat?.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if(navigationSearchValue) {
            handleOnNewMessage(navigationSearchValue);
        }
    }, [navigationSearchValue]);

    const onNewCustomComponentMessage = (msg,  isAiMessagePrompt = false, successMessage = "") => {
        if (disableChat) return;
        if (isAiMessagePrompt) { // if is suggestion to Ai -> move to the scnerio of the requesting AI
            return handleOnNewMessage(msg, true);
        }
        const newMessagesArray = [...messages, { text: msg, isUser: true, createdAt: moment(), creator: user.name }, { loadMessage: true }];
        setLoading(true);
        setMessages(newMessagesArray);
        setTimeout(() => {
            setLoading(false)
            const cloneArr = [...newMessagesArray];
            cloneArr[cloneArr.length - 1] = { text: successMessage || SUCCESS_MESSAGES[Math.floor(Math.random() * SUCCESS_MESSAGES.length)], isUser: false, loadMessage: false, creator: THINKER_CREATOR, createdAt: moment() };
            setMessages(cloneArr);
        }, 1000);
    };

    const handleOnNewMessage = async (searchValue, isSuggestionPrompt = false) => {
        if (disableChat) return;
        const tempValue = searchValue || value;
        if (!tempValue) return;
        
        const userMsg = { isUser: true, text: tempValue, createdAt: moment(), creator: user.name };
        const newMessagesArray = [...messages, userMsg, { loadMessage: true }];
        
        setMessages(newMessagesArray);
        setValue("");
        setLoading(true);

        let resOk = false;
        let responseText = "";
        if (isCustomNewMessageFunction) {
            const response = await onNewMessageHandler(tempValue, isSuggestionPrompt) || {}; // custom function to handle new message
            resOk = response.reqSuccess;
            responseText = response.text;

        } else { // default chat (inventory) behavior
            resOk = await dispatch(setInventoryFiltersByPrompt(tempValue));
        }
        const cloneArr = [...newMessagesArray];
        cloneArr[cloneArr.length - 1] = { text: resOk ? responseText || SUCCESS_MESSAGES[Math.floor(Math.random() * SUCCESS_MESSAGES.length)] :  FAILURE_MESSAGES[Math.floor(Math.random() * FAILURE_MESSAGES.length)], isUser: false, loadMessage: false, creator: THINKER_CREATOR, createdAt: moment() };
        
        setTimeout(() => {
            setLoading(false)
            setMessages(cloneArr);
        }, isCustomNewMessageFunction ? 100 : 3000);

        if(searchValue) {
            dispatch({
                type: "NAVIGATION_SEARCH",
                payload: { searchValue: undefined },
            });
        }
    }

    if (!visible) return null;

    return (
        <div className={`FiltersChat grow-animation ${customClassName}`}>
            <div className="FiltersChat__container">
                <FontAwesomeIcon icon="times" className="FiltersChat__close" onClick={handleClose} />
                <span className="purple-flag-outline FiltersChat__preview">Preview</span>
                <div className="FiltersChat__header col g5 center">
                    <ThinkerbellIcon className='FiltersChat__header-icon'/>
                    <div className='FiltersChat__title row g5'>
                        <span className='gradient-text bold'>Thinkerbell AI</span>
                        assistant
                    </div>
                </div>
                <div className='FiltersChat__messages col g20' id="aiChat">
                    {isConversationDate && <span className="FiltersChat__conversationTime font-12">{messages[0].createdAt?.format('DD MMM')}</span>}
                    {messages.map((msg = {}) => {
                        const { text, isUser, loadMessage, createdAt, creator = "" } = msg;
                        return loadMessage ? <TypingDots key={uuidv4()} />
                        : <div className={`FiltersChat__message ${!isUser ? 'thinkerbell' : ''} col g5`} key={uuidv4()}>
                            <span className='FiltersChat__message-creator row g5 bold'>
                                {creator}
                                {!isUser && <ThinkerbellStars className="FiltersChat__message-creator-thinker" />}
                            </span>
                            {text}
                            <span className='FiltersChat__message-time '>{createdAt?.format('h:mm A')}</span>
                            {isUser ? <div className="FiltersChat__message-userIcon" style={{ backgroundImage: `url(${user?.picture})` }}/>
                                : <ThinkerbellIcon className='FiltersChat__message-thinkerbellIcon'/>}
                        </div>
                    })}
                </div>
                {customMsgsAddition ? <CustomMsgsComponent onAddMsg={onNewCustomComponentMessage} loading={loading} />: null}
                <div className='FiltersChat__input row'>
                    <Input type="text" placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleOnNewMessage();
                            e.preventDefault();
                        }
                    }} disabled={loading} />
                    <ThinkerbellStars className="FiltersChat__input-thinkerIcon" />
                    {!disableChat && <SendMessage className={`FiltersChat__input-sendIcon ${value ? 'pointer': ''}`} onClick={() => handleOnNewMessage()} />}
                </div>
            </div>
        </div>
    );
};

export default FiltersChat;