import React from "react";
import isFunction from "lodash/isFunction";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import AppBtn from "../../../../shared/appBtn/appBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as PullRequestIcon } from "../../../../Images/ci/icons/ci-pull-request.svg";
import "./aiRemediationFooter.scss";

const AiRemediationManagementFooter = ({
  configWizard = [],
  setCurrentStep,
  loadingSubmit,
  selectedPath,
  loadingPR,
}) => {
  const { t } = useTranslation("common");


  const prData = useSelector(
    (state) => state.aiRemediationReducer?.prData || {}
  );

  const currentStep =  useSelector(
    (state) => state.aiRemediationReducer?.currentStep
  );

  const onClickNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const onClickPreviousStep = async () => {
    if (isFunction(configWizard[currentStep].beforePreviousStep)) {
      try {
        await configWizard[currentStep].beforePreviousStep();
      } catch (err) {
        return;
      }
    }
    setCurrentStep(currentStep - 1);
  };


  const onClickPreviousStepCloud =  () => {
    return setCurrentStep(0);
  }

  return (
    <div className="AiRemediationManagementFooter col g5">
      <div className="row between">
        {(currentStep > 0 && currentStep !== 3) && (
          <Button className="AiRemediationManagementFooter__btn button-margin" onClick={onClickPreviousStep}>
            {t('previous')}
          </Button>
        )}
        {currentStep === 3 && (          
          <Button className="AiRemediationManagementFooter__btn button-margin" onClick={onClickPreviousStepCloud}>
            {t('previous')}
          </Button>)}
        <div className="AiRemediationManagement__footer-paging row g8">
          {currentStep === 1 && (
            <Button
              type="primary"
              onClick={async () => {
                if (isFunction(configWizard[currentStep].beforeNextStep)) {
                  try {
                    await configWizard[currentStep].beforeNextStep();
                  } catch (err) {
                    return;
                  }
                }
                onClickNextStep();
              }}
              disabled={!selectedPath}
              loading={loadingSubmit}
            >
              {configWizard[currentStep]?.nextButtonText}
            </Button>
          )}
          {currentStep === 2 && (
            <AppBtn
              type="primary"
              onClick={async () => {
                if (isFunction(configWizard[currentStep].beforeNextStep)) {
                  try {
                    await configWizard[currentStep].beforeNextStep(prData, selectedPath);
                  } catch (err) {
                    return;
                  }
                }
              }}
              text={
                !isEmpty(prData[selectedPath]) && prData[selectedPath] ? "Pull request" : "Create pull request"
              }
              key="create-pr"
              icon={
                !isEmpty(prData[selectedPath]) &&  prData[selectedPath] ? (
                  <FontAwesomeIcon icon="external-link-alt" />
                ) : (
                  <PullRequestIcon className="FixDrift-prIcon" />
                )
              }
              loading={loadingPR}
            ></AppBtn>
          )}
          {currentStep === 3 && (
            <Button
              type="primary"
              onClick={async () => {
                if (isFunction(configWizard[currentStep].beforeNextStep)) {
                  try {
                    await configWizard[currentStep].beforeNextStep();
                    return;
                  } catch (err) {
                    return;
                  }
                }
              }}
            >
              {configWizard[currentStep]?.nextButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AiRemediationManagementFooter;
