import React, { useState } from 'react';
import isString from 'lodash/isString';
import CodeDiff from '../../../../../shared/codeDiff/codeDiff';
import ActionBtn from '../../../../../shared/actionBtn/actionBtn';
import Configuration from '../../../../inventory/assetDataModal/assetConfig/configuration';
import { sendEvent } from '../../../../../utils/amplitude';
import { CiEvents } from '../../../../../utils/amplitudeEvents';
import { useTranslation } from 'react-i18next';

import { DIFFS_MOCK } from '../../../../../consts/ci-workflows';
import './planDrift.scss';
import { formatJsonDiffs } from '../../../../../utils/workflowsHelper';

const LARGE_DIFFS_LENGTH = 10000;
const PlanDrift = ({ diffs, workspaceId }) => {
    const [jsonView, setJsonView] = useState(false);
    const isStringContent = isString(diffs);
    const isLargeDiffContent =  isStringContent ? diffs.length > LARGE_DIFFS_LENGTH : false;
    const jsonDiffs = isStringContent ? diffs : JSON.stringify(diffs);
    const diffsArr = isStringContent ? JSON.parse(diffs) : diffs;
    const jsonWithoutEscaping = formatJsonDiffs(jsonDiffs);
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "postPlan.planDrift"});
    return (
        <div className="PlanDrift col g10">
             <span className="title">{t("title")}</span>
             { jsonView ? <Configuration item={jsonWithoutEscaping} />
             : <div className="PlanDrift__table col">
                <div className="PlanDrift__table-header">
                    <span className="PlanDrift__table-header-title">{t("property")}</span>
                    <span className="PlanDrift__table-header-title">{t("currentConfiguration")}</span>
                    <span className="PlanDrift__table-header-title">{t("desiredConfiguration")}</span>
                </div>
                <div className="PlanDrift__table-body">
                    {isLargeDiffContent ?
                    <>
                    <div className="PlanDrift__table-row longContent-preview">
                                <span className="PlanDrift__table-row-title">preview</span>
                                <CodeDiff oldValue={DIFFS_MOCK.before} newValue={DIFFS_MOCK.after} />
                     </div>
                    <div className="PlanDrift__table-row longContent">
                        Changes can't be shown, diff is too large. Download the full changes to view them locally.
                    </div> 
                    </>

                    : diffsArr.map((item, index) => {
                        const { path = '', before = '', after = '' } = item;
                        const beforeString = isString(before) ? before : JSON.stringify(before);
                        const afterString = isString(after) ? after : JSON.stringify(after);
                        
                        return (
                            <div className="PlanDrift__table-row" key={index}>
                                <span className="PlanDrift__table-row-title">{path}</span>
                                <CodeDiff oldValue={beforeString} newValue={afterString} />
                            </div>
                        )
                    })}
                </div>
             </div>}
             <div className="PlanDrift__actions row">
                    <ActionBtn text="" action="json" icon="json" onClick={() => setJsonView(!jsonView)} purple/>
                    <ActionBtn text="" icon="copy" action="copy" stringToAction={jsonWithoutEscaping} purple onClickDifferentActions={() => sendEvent(CiEvents.ciRunResourceCopyClick, { workspaceID: workspaceId })}/>
                    <ActionBtn
                        purple
                        text=""
                        action="download"
                        fileType="json"
                        fileName="resource-plan-drift"
                        icon="donwload"
                        stringToAction={jsonWithoutEscaping}
                    />               
             </div>
        </div>
    );
};

export default PlanDrift;