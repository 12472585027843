import React from 'react';
import map from 'lodash/map'
import {v4 as uuidv4} from "uuid";
import {ReactComponent as SelectModuleIcon} from "../../../../Images/general_icons/select_module.svg";
import {ReactComponent as CloudIcon} from "../../../../Images/general_icons/provider_cloud.svg";
import {ReactComponent as SettingsIcon} from "../../../../Images/general_icons/advance_settings_icon.svg";
import {formatAwsStringWithUnderscore} from "../../../../utils/formatting";
import AssetTypeImage from "../../../../shared/assetTypeImage/assetTypeImage";
import {Divider} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const MODULE_MISMATCH_SUBTITLE = 'These resources are listed in the module but not in the dependencies';
const CODIFIED_MISMATCH_SUBTITLE = 'These resources are listed in the dependencies but not in the module hence will remain unmanaged';

const ModulesResources = ({moduleData, tModuleCall = () => null}) => {
    const {
        ModuleMismatches: moduleMismatches,
        CodifiedMismatches: codifiedMismatches,
        HasUnknownModules: hasUnknownModules = false,
        ModuleCallGaps: moduleCallGaps = []
    } = moduleData;
    const isModuleMismatches = moduleMismatches?.length > 0;
    const isCodifiedMismatches = codifiedMismatches?.length > 0;
    const isModuleCallGaps = moduleCallGaps?.length > 0;

    if (!isCodifiedMismatches && !isModuleMismatches && !hasUnknownModules && !isModuleCallGaps) return null;

    return (
        <div className="ModulesTab__resources col">
            <div className="ModulesTab__resources-container row">
                <div className="ModulesTab__resources-line">
                    {isModuleMismatches &&
                        <div className="ModulesTab__resources-mark">
                            <div className="ModulesTab__resources-mark-dot">
                                <CloudIcon/>
                            </div>
                        </div>}
                    {isCodifiedMismatches || hasUnknownModules &&
                        <div className={`ModulesTab__resources-mark ${isModuleMismatches ? 'second' : ''}`}>
                            <div className="ModulesTab__resources-mark-dot">
                                <SelectModuleIcon/>
                            </div>
                        </div>}
                    {isModuleCallGaps &&
                        <div
                            className={`ModulesTab__resources-mark ${isModuleMismatches && isCodifiedMismatches ? 'third' : isModuleMismatches || isCodifiedMismatches ? 'second' : ''}`}>
                            <div className="ModulesTab__resources-mark-dot">
                                <SettingsIcon/>
                            </div>
                        </div>}
                </div>
                <div className="col g10">
                    {isModuleMismatches &&
                        <div className="ModulesTab__resources-itemContainer col g5">
                            <span className="ModulesTab__resources-title">Additional resources will be created</span>
                            <span className="ModulesTab__resources-subtitle">{MODULE_MISMATCH_SUBTITLE}</span>
                            <div className="ModulesTab__resources-item">
                                {map(moduleMismatches, (modMismatch) => (
                                    <div className="row g10" key={uuidv4()}>
                                        <AssetTypeImage assetType={modMismatch}/>
                                        <span>{formatAwsStringWithUnderscore(modMismatch)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    {isModuleMismatches && (isCodifiedMismatches || hasUnknownModules) && <Divider className='ModulesTab__resources-divider'/>}
                    {isCodifiedMismatches &&
                        <div className="ModulesTab__resources-itemContainer col g5">
                            <span className="ModulesTab__resources-title">Import will not be applied</span>
                            <span className="ModulesTab__resources-subtitle">{CODIFIED_MISMATCH_SUBTITLE}</span>
                            <div className="ModulesTab__resources-item">
                                {map(codifiedMismatches, (codMismatch) => (
                                    <div className="row g10" key={uuidv4()}>
                                        <AssetTypeImage assetType={codMismatch}/>
                                        <span>{formatAwsStringWithUnderscore(codMismatch)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                    {hasUnknownModules && <div className="ModulesTab__resources-itemContainer col g5">
                        <div className="row g5">
                            <FontAwesomeIcon icon="exclamation-triangle"
                                             className="ModulesTab__resources-itemContainer-warning"/>
                            <span
                                className="ModulesTab__resources-title">{tModuleCall("hasUnknownModulesWarning")}</span>
                        </div>
                    </div>}
                    {(isModuleMismatches || isCodifiedMismatches || hasUnknownModules) && isModuleCallGaps && <Divider className='ModulesTab__resources-divider'/>}
                    {isModuleCallGaps &&
                        <div className="ModulesTab__resources-itemContainer col g5">
                            <span className="ModulesTab__resources-title">Module misalignment</span>
                            <ul className="ModulesTab__gaps-item col g5">
                                {map(moduleCallGaps, (moduleCallGap) => (
                                    <div key={uuidv4()}>
                                        <li>{moduleCallGap}</li>
                                    </div>
                                ))}
                            </ul>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default ModulesResources;