import React, { useState, useCallback, useRef, useEffect } from "react";
import { Collapse } from "antd";
import TableWrapper from "../../../../shared/tableWrapper/tableWrapper";

import { getGuardrailsTableColumnsByType } from "./guardrailsTableHelper";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCodeOrPlanScanRemediation as getCodeOrPlanOrGuardrailsRemediation, getWorkflowResources } from "../../../../redux/actions/ciWorkflowsActions";
import { GUARDRAILS_TYPES } from "../../../guardrails/guardrailsModal/guardrailsHelper";
import { sendEvent } from "../../../../utils/amplitude";
import { aiRemediationEvents, guardrailsEvents } from "../../../../utils/amplitudeEvents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemediationBlock from "../remediationBlock/remediationBlock";
import { handleRowClick as handleOpenCloseRow, openRowsByIds } from "../../../../utils/table";
import "./guardrailsBlockTable.scss";

const { Panel } = Collapse;

const GuardrailsBlockTable = ({ data, type, Icon, handleOpenConfig, taskId, ind, onClickCodeChangedLink, openRemediationsByDefault, allRemediationsLoading }) => {
    const [selectedPlanResource, setSelectedPlanResource] = useState({});
    const [loadingViolation, setLoadingViolation] = useState({});
    const [pageSize, setPageSize] = useState(5);
    const { ruleId, ruleType = "", ruleName = "", isPassed = false, violations = [] } = data;
    const remediationGuardrailsByViolationId = useSelector((state) => state.ciWorkflowsReducer.remediationGuardrailsByViolationId);
    const apiMainRef = useRef();
    const { t: tColumns } = useTranslation("ci-pipeline", { keyPrefix: "columns"});
    const shouldShowTable = !isPassed && ruleType && ruleId;
    const dataLength = violations?.length;
    const hasRemediation = ["tag", "policy"].includes(type);

    useEffect(() => {
        if (hasRemediation && openRemediationsByDefault) {
            const violationsIds = violations.map((vio = {}) => vio.id);
            openRowsByIds(apiMainRef, violationsIds);
        }
    }, [openRemediationsByDefault]);

    const getDetailPanelContent = useCallback(({ row = {} } = {}) => row.violationId && hasRemediation && <RemediationBlock data={remediationGuardrailsByViolationId[row.violationId]} remediationLoading={loadingViolation[row?.id] || allRemediationsLoading} isDiagnosticRemediation={remediationGuardrailsByViolationId[row?.violationId]} 
    setIsRemediateOpen={() => handleOpenCloseRow(apiMainRef, row?.id)} isFromExpandedBox />, [remediationGuardrailsByViolationId, openRemediationsByDefault, allRemediationsLoading, loadingViolation]);
    const getDetailPanelHeight = useCallback(() => "auto", []);

    const dispatch = useDispatch();
    
    const handleOpenPlanConfig = async (address, triggeredTab) => {
        const isElementExists = selectedPlanResource?.address === address;
        if (isElementExists) {
           return handleOpenConfig(selectedPlanResource);
        }
        const filters = { orClauses: { address } };
        const response = await dispatch(getWorkflowResources(taskId, { filters }));
        if (response?.resources?.length > 0) {
            const element = response.resources[0] || {};
            handleOpenConfig(element, triggeredTab);
            setSelectedPlanResource(element);
        }
    }
    const handleRowClick = (params) => {
        if (type === GUARDRAILS_TYPES.cost) return;
        const address = params.row?.assetInfo?.address || "";
        const triggeredTab = type === GUARDRAILS_TYPES.policy ? GUARDRAILS_TYPES.policy : ""
        handleOpenPlanConfig(address, triggeredTab)
    }

    const fetchGuardrailsViolationRemediation = async (guardrailsViolations) => {
        const payload = { guardrailsViolations };
        await dispatch(getCodeOrPlanOrGuardrailsRemediation(payload, taskId, false, true));
    };

    const handleRemediateClick = async (row = {}) => {
        const { id, violationId } = row;
        const isExists = remediationGuardrailsByViolationId[violationId];
        handleOpenCloseRow(apiMainRef, id);
        if (isExists) return;
        setLoadingViolation({ ...loadingViolation,  [id]: true });
        await fetchGuardrailsViolationRemediation([violationId]);
        setLoadingViolation({ ...loadingViolation, [id]: false });
        sendEvent(aiRemediationEvents.clickedWorkflowPolicyGuardrailsViolation, { ruleType: type, ruleName});
    };

    return (
        <Collapse
            className={`GuardrailsBlockTable ${isPassed ? "passed" : ""} ${dataLength < 5 ? "hideNav" : ""}`}
            key={ruleId}
            defaultActiveKey={isPassed ? "" : (ind === 0 || hasRemediation) ? ruleId : ""}
            // activeKey={ruleId}
            expandIconPosition="right"
            onChange={(change = []) => {
                const closed = change.length === 0;
                sendEvent(guardrailsEvents.guardrailsStepCollapse, { collapseType: closed ? "closed" : "opened", ruleType: type, ruleStatus: isPassed ? "passed": "violated" })
            }}
        >
            <Panel key={ruleId} collapsible={isPassed ? "disabled" : null} header={
                <div className="GuardrailsBlockTable__header row g5">
                    <FontAwesomeIcon icon={isPassed ? "check" : "times"} className={`GuardrailsBlockTable__item-icon ${isPassed ? "passed" : ""} pointer`}/>
                    <span className="GuardrailsBlockTable__title unbold">{ruleName}</span>
                </div>
            }>
                 {shouldShowTable &&
                  <TableWrapper
                    autoHeight={dataLength < 5 ? "100%" : "auto"}
                    height={dataLength < 5 ? "100%" : "auto"}
                    rowKey="id"
                    tableData={violations}
                    columns={getGuardrailsTableColumnsByType(type, tColumns, onClickCodeChangedLink, handleRemediateClick, apiMainRef)}
                    disableSelectionOnClick
                    pageSize={pageSize}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={getDetailPanelHeight}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    onRowClick={(params) => handleRowClick(params)}
                />}
            </Panel>
        </Collapse>
    );
};

export default GuardrailsBlockTable;