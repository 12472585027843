import React, { useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import SpinnerLoader from "../../../shared/spinnerLoader/spinnerLoader";
import { getConnectedServicesByProvider } from "../../../redux/actions/dashboardActions";
import { S3_ASSETS } from "../../../consts/config";
import { connectedServicesIcons } from "../../../utils/icons";
import { sendEvent } from "../../../utils/amplitude";
import { dashboardEvents, integrationEvents } from "../../../utils/amplitudeEvents";
import "./connectedServices.scss";

const ICONS_DISPLAY_LIMIT = 3;

const ConnectedServices = ({ provider = "", disableOnClick = false, outerData }) => {
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const themeMode = themeDark ? "dark" : "light";
    const data = useSelector((state) => state.dashboardReducer.connectedServices) || {};
    const [loadingByService, setLoadingByService] = useState({});

    const dispatch = useDispatch();
    const dataByProvider = data[provider] || [];
    const connectedServices = outerData || dataByProvider;
    
    const handleServiceClick = async(e, service) => {
        e.stopPropagation();
        if (disableOnClick) return;
        const loadingService = service || "general";
        setLoadingByService({ ...loadingByService, [loadingService]: true });
        await dispatch(getConnectedServicesByProvider(provider, service))
        setLoadingByService({ ...loadingByService, [loadingService]: false });
    };

    const onImgErr = (e) => {
      e.target.src = connectedServicesIcons("default", themeMode);
    }
    const getPopoverContent = () => {
        return <ul className="ConnectedServices__body__popover-content" onClick={(e) => e.stopPropagation()}>
          {connectedServices?.map((item = {}) => {
            const { iconURL: iconSuffix = "", canonicalName = "", services = [], category = ""} = item;
            
            const icon = `${S3_ASSETS}/connected-services/${themeMode}_${iconSuffix}.svg`;
            const serviceName  = canonicalName ? `${canonicalName} (${category})` : services[0];
          return <li key={uuidv4()} className={disableOnClick ? "" : "pointer"} onClick={(e) => handleServiceClick(e, canonicalName)}>
           <div className="ConnectedServices__body__multi-image-container popover center">
              <img src={icon} onError={onImgErr} loading="lazy" />
            </div> 
            {serviceName}
            {loadingByService[canonicalName] && <SpinnerLoader size="small" />}
          </li>})}
        </ul>
    }
    
    const arrLength = useMemo(() => connectedServices.length || 0, [connectedServices]);
    const moreItems = useMemo(() => arrLength > ICONS_DISPLAY_LIMIT ? arrLength - ICONS_DISPLAY_LIMIT + 1 : null, [arrLength]);
    const popoverContent = useMemo(() => getPopoverContent(), [connectedServices, themeDark, loadingByService]);

    return (
        <Popover content={popoverContent} placement="bottomLeft">
        <div className="ConnectedServices__body__multi-image" onClick={(e) => {
            const isIntegration = disableOnClick;
            sendEvent(isIntegration ? dashboardEvents.clickedOnConnectedServices : integrationEvents.integrationClickedOnService, { provider });
            handleServiceClick(e);
          }} >
          {loadingByService.general ? <SpinnerLoader size="small" />
          : connectedServices.map((item = {}, index) => {
            const { iconURL: iconSuffix = "", canonicalName = ""} = item;
            const shouldRenderImg = (!moreItems || index < ICONS_DISPLAY_LIMIT - 1);
            const icon = `${S3_ASSETS}/connected-services/${themeMode}_${iconSuffix}.svg`;
            return shouldRenderImg && (
            <div className="ConnectedServices__body__multi-image-container center" key={uuidv4()}>
                <img src={icon} alt={canonicalName} onError={onImgErr} loading="lazy" />
            </div>)
            })}
          {moreItems && <div className={`ConnectedServices__body__multi-image-purpleCount ${moreItems > 9 ? 'wider': ''} row`}>
          <span className="ConnectedServices__body__multi-image-purpleCount-text">+{moreItems}</span></div>}
        </div>
      </Popover>
    );
};

export default ConnectedServices;