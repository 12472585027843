import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { toggleGuidePlaylist } from "../../redux/actions/userPreferencesActions";
import { getPlaylistGuide } from "../../redux/actions/globalAppActions";
import ModalVideo from "react-modal-video";
import { sendEvent } from "../../utils/amplitude";
import { generalEvents } from "../../utils/amplitudeEvents";
import PlaylistDrawer from "./playlistDrawer";
import "./playlistBtn.scss";

const PlaylistBtn = () => {
    const dispatch = useDispatch();
    const showGuidePlaylist = useSelector((state) => state.userPreferencesReducer.showGuidePlaylist);
    const playlists = useSelector((state) => state.globalAppReducer.playlistGuide);
    const [playlistsLoading, setPlaylistsLoading] = useState(false);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [selectedVideoId, setSelectedVideoId] = useState("");

    useEffect(() => {
        if (showGuidePlaylist && !playlists.length) {
            fetchPlaylistsGuide();
        }
        document.addEventListener("keydown", (e) => {
          const userTryToEsc = e.key === "Escape";
          const shouldEscapeFromPlaylist = showGuidePlaylist && userTryToEsc;
          if (shouldEscapeFromPlaylist) {
              dispatch(toggleGuidePlaylist());
          }
        })
        return ()  => document.removeEventListener("keydown", () => {});
    }, [showGuidePlaylist]);
    
    const handleClick = async () => {
        const shouldSendOpenEvent = !showGuidePlaylist;
        await dispatch(toggleGuidePlaylist());
        if (shouldSendOpenEvent) {
            sendEvent(generalEvents.clickedFireflyPlaylistOpen, {})
        }
    };
    const fetchPlaylistsGuide = async () => {
      setPlaylistsLoading(true);
      await dispatch(getPlaylistGuide());
      setPlaylistsLoading(false)
    }
    const onPlayPreview = (videoUrl = "", title = "") => {
        const videoId = videoUrl.split("/").pop();
        setSelectedVideoId(videoId);
        setVideoModalOpen(true);
        sendEvent(generalEvents.clickedFireflyPlaylistVideo, { videoUrl, title });
    };

    return (
        <>
            <div className={`PlaylistBtn pointer ${showGuidePlaylist ? "withPlaylist" : ""} center`} onClick={handleClick}>
                <FontAwesomeIcon icon={showGuidePlaylist ? "stop" : "play"} className="pointer" />
            </div>
            {showGuidePlaylist && <PlaylistDrawer playlists={playlists} loading={playlistsLoading} handleClose={handleClick} onPlayPreview={onPlayPreview} />}
              {videoModalOpen &&
                <ModalVideo
                  channel="youtube"
                  youtube={{ mute: 1, autoplay: 1 }}
                  isOpen={videoModalOpen}
                  videoId={selectedVideoId}
                  onClose={() => setVideoModalOpen(false)} 
              />}
        </>
    );
};

export default PlaylistBtn;
