import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import { RightOutlined } from "@ant-design/icons";
import "./aiRemediationCliCollapse.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TablePagination } from "@mui/material";
import AiRemediationCli from "./aiRemediationCli";
import { useDispatch, useSelector } from "react-redux";
import {
  getAiRemediateCloud,
  getAiRemediationCloudAssets,
} from "../../../../redux/actions/aiRemediationActions";
import { isEmpty } from "lodash";
import SpinnerLoader from "../../../../shared/spinnerLoader/spinnerLoader";

const AiRemediationCliCollapse = ({
  setActiveKey,
  activeKey = [],
  integrationId = "",
  classificationsId = "",
  totalAssets,
  classificationData = {},
  searchValue = "",
}) => {
  const { Panel } = Collapse;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const cloudAssets = useSelector(
    (state) => state.aiRemediationReducer?.cloudAssets || {}
  );

  const assetsCloudData = cloudAssets[integrationId] || [];
  
  const isPagination = (totalAssets || 0) > 10

  useEffect(() => {
    fetchCode();
  }, [rowsPerPage, page]);

  const fetchCode = async () => {
    const skip = page * rowsPerPage;
    setLoading(true);
    const response = await dispatch(
      getAiRemediationCloudAssets(
        classificationsId,
        integrationId,
        rowsPerPage,
        skip,
        searchValue,
      )
    );
    if (response?.abort) return;
    setLoading(false);
  };

  const onCollapseChange = (keys = []) => {
    if (!keys) return;

    setActiveKey((prev = []) => {
      const newActiveKey = {
        ...prev,
        [integrationId]: keys,
      };

      const isOpen = keys.length > (prev[integrationId]?.length || 0);
      setIsOpen(isOpen);

      return newActiveKey;
    });
  };

  const handleSetRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const renderHeader = (title = "", assetId = "") => (
    <>
      <div className="panel-header">
        <div className="panel-header-content">
          <FontAwesomeIcon
            icon={
              activeKey[integrationId]?.includes(assetId) ? "minus" : "plus"
            }
          />
          <span className="panel-title">{title}</span>
        </div>
      </div>
    </>
  );

  return loading ? (
    <div className="row center mgTtm10 mgBtm5">
      <SpinnerLoader />
    </div>
  ) : (
    <div className="AiRemediationCliCollapse col g15">
      <Collapse
        activeKey={activeKey[integrationId]}
        onChange={onCollapseChange}
      >
        {(assetsCloudData || []).map((item) => (
          <Panel
            header={renderHeader(item.name, item.assetId)}
            key={item.assetId}
          >
            <AiRemediationCli
              assetId={item.assetId}
              isOpen={isOpen}
              classificationData={classificationData}
              setIsOpen={setIsOpen}
              integrationId={integrationId}
            />
          </Panel>
        ))}
      </Collapse>

      {isPagination && <TablePagination
        component="div"
        className="AiRemediationVc__pagination"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onRowsPerPageChange={handleSetRowsPerPage}
        count={totalAssets}
        page={page}
        onPageChange={(e, page) => setPage(page)}
      />}
    </div>
  );
};

export default AiRemediationCliCollapse;
