import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../../shared/appModal/appModal";
import { PROVIDERS } from "../../../consts/general";
import { useTranslation } from "react-i18next";
import { setDashboardProperty } from "../../../redux/actions/dashboardActions";
import { clouds, connectedServicesIcons } from "../../../utils/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { S3_ASSETS } from "../../../consts/config";
import { sendEvent } from "../../../utils/amplitude";
import { dashboardEvents } from "../../../utils/amplitudeEvents";
import "./connectedServices.scss";

const ConnectedServicesModal = () => {
  const [integrationMode ,setIntegrationMode] = useState(false);
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  const themeMode = themeDark ? "dark" : "light";
  const modalDetails = useSelector((state) => state.dashboardReducer.connectedServicesModal) || {};
  const providerIntegrations = useSelector((state) => state.globalAppReducer.clouds) || {};
  const { visible, data = [], selectedProvider = PROVIDERS.aws, selectedService = "" } = modalDetails;

  const integCountByProvider = providerIntegrations[selectedProvider]?.length || 0;
  const selectedServiceItem = useMemo(() => data.find((item) => item.canonicalName === selectedService), [selectedService, themeDark]);
  const selectedServiceIcon = selectedServiceItem?.iconURL;
  const providerIntegrationNamesByIntegrations = (providerIntegrations[selectedProvider] || []).reduce((acc, item = "") => {
    acc[item.id] = item.name || "";
    return acc;
  }, {});
  
  useEffect(() => {
    const shouldTurnToIntegrationMode = selectedService && visible && !integrationMode;
    if (shouldTurnToIntegrationMode){
      setIntegrationMode(true);
    }
  }, [selectedService, visible]);

  const { t } = useTranslation("dashboard");

  const titleByProvider = t(`integrations.${selectedProvider === PROVIDERS.aws ? "awsAccounts" : "k8sClusters"}`);
  const dispatch = useDispatch();

  const handleClose = async () => {
    await dispatch(setDashboardProperty("connectedServicesModal", { visible: false }));
    setIntegrationMode(false);
  };
  
  const onSetSelectedService = async (service) => {
    sendEvent(dashboardEvents.connectedServiceClickedOnService, { service, provider: selectedProvider });
    await dispatch(setDashboardProperty("connectedServicesModal", { ...modalDetails, selectedService: service }));
    setIntegrationMode(true);
  };
  const onImgErr = (e) => {
    e.target.src = connectedServicesIcons("default", themeMode);
  };
  return (
    <AppModal
      title={
        <span className="row g5">
          { integrationMode ? <>
          <FontAwesomeIcon icon="chevron-left" className="pointer" onClick={() => setIntegrationMode(false)} />
            <div className="row g10">
              <div className="ConnectedServices__body__multi-image-container center">
                <img src={`${S3_ASSETS}/connected-services/${themeMode}_${selectedServiceIcon}.svg`} onError={onImgErr}/>
              </div>
              <span className="font-16">{selectedService ? `${selectedService} (${selectedServiceItem?.category})` : selectedServiceItem?.services?.[0]}</span>
            </div>
          </>
          : <>
              <span className="bold">{t(`connectedServiceModalTitles.${selectedProvider}`)}</span>
              <span className="font-16">|</span>
              <span className="font-16">{integCountByProvider} {titleByProvider} </span>
            </>}
        </span>
      }
      hideSubmitBtn={true}
      visible={visible}
      handleClose={handleClose}
      bodyClassName={`ConnectedServicesModal col ${integrationMode ? "pad0" : ""}`}
      width="580px"
      zIndex={100000}
      handleOnSubmit={() => null}
      destroyOnClose
    >
      {integrationMode ?
      <div className="row align-items-start">
        <div className="ConnectedServicesModal-line"/>
        <div className="ConnectedServicesModal__integrations col g10">
          <div className="row g10">
            <FontAwesomeIcon icon="check-circle" className="ConnectedServicesModal__green" />
            <span className="bold ConnectedServicesModal__green">Integrated ({selectedServiceItem?.integrated?.length || 0})</span>
          </div>
          <div className="col g10">
              {selectedServiceItem?.integrated?.map((item) => <div className="row g10" key={uuidv4()}>
                <img className="img16" src={clouds(selectedProvider, themeDark)} />
                <span>{providerIntegrationNamesByIntegrations[item]}</span>
              </div>)}
            </div>
        </div>
        <div className="ConnectedServicesModal__integrations col g10">
          <div className="row g10">
            <FontAwesomeIcon icon="times-circle" className="ConnectedServicesModal__red" />
            <span className="bold ConnectedServicesModal__red">Missing ({selectedServiceItem?.missing?.length || 0})</span>
          </div>
          <div className="col g10">
              {selectedServiceItem?.missing?.map((item) => <div className="row g10" key={uuidv4()}>
                <img className="img16" src={clouds(selectedProvider, themeDark)}/>
                <span>{providerIntegrationNamesByIntegrations[item]}</span>
              </div>)}
            </div>
        </div>
      </div>
      :<div className="col g10">
        <div className="row between">
            <span className="bold">Integrations</span>
            <div className="row g15">
              <span className="bold">Installed on</span>
              <span className="bold">Missing on</span>
            </div>
        </div>
        {data?.map((item = {}) => {
            const installedOn = item.integrated?.length || 0;
            const canonicalName = item.canonicalName;
            const serviceName = canonicalName ? `${canonicalName} (${item.category})` : item?.services[0];

            return(
            <div className="ConnectedServicesModal-services row between" key={uuidv4()}>
                <div className="row g10">
                    <div className="ConnectedServices__body__multi-image-container popover center pointer" onClick={() => onSetSelectedService(canonicalName)}>
                        <img src={`${S3_ASSETS}/connected-services/${themeMode}_${item.iconURL}.svg`} onError={onImgErr} />
                    </div>
                    <span className="ConnectedServicesModal-serviceName pointer" onClick={() => onSetSelectedService(canonicalName)}>{serviceName}</span>
                </div>
                <div className="row g20">
                  <span className="ConnectedServicesModal-installedOn pointer" onClick={() => onSetSelectedService(canonicalName)}>{installedOn}</span>
                  <span className="ConnectedServicesModal-missingOn pointer" onClick={() => onSetSelectedService(canonicalName)}>{item.missing?.length || 0}</span>
                </div>
            </div>
            )})}
      </div>}
    </AppModal>
  );
};

export default ConnectedServicesModal;
