import React from "react";

import { getChatSuggestions } from "./suggestionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { changeCodifyDrawerProperty } from "../../../../redux/actions/iacImportActions";
import "./codifyChatSuggestions.scss";
import { sendEvent } from "../../../../utils/amplitude";
import { codifyEvents } from "../../../../utils/amplitudeEvents";

const CodifyChatSuggestions = ({ onAddMsg = () => null, loading = false }) => {
    const codifyState = useSelector((state) => state.iacImportReducer);
    const dispatch = useDispatch();
    
    const handleGenericChangeCodifyState = (key, value, isMultiKeys = false) => dispatch(changeCodifyDrawerProperty(key, value, isMultiKeys)); 

    const chatSuggestions = getChatSuggestions(codifyState, handleGenericChangeCodifyState);
    
    const onSuggestionClicked = (suggestion, disabled, onClickFunc, isAiPrompt = false, successMessage) => {
        if (disabled || loading) return;
        onAddMsg(suggestion, isAiPrompt, successMessage);
        onClickFunc();
        if (!isAiPrompt) {
            sendEvent(codifyEvents.codificationAiChatRequest, { isRecommendedPrompt: true, promptContent: suggestion });
        }
    }
    return (
        <div className="CodifyChatSuggestions row flex-wrap">
            {chatSuggestions.map((item) => {
                const { title, onClick, disabled, isAiPrompt, successMessage = "" } = item;
                if (disabled) return null;
                return (
                    <div className={`CodifyChatSuggestions__item ${disabled ? "disabled" : ""}`} key={title} onClick={() => onSuggestionClicked(title, disabled, onClick, isAiPrompt, successMessage)}>
                        <div className="CodifyChatSuggestions__item-content" >
                            {title}
                        </div>
                    </div>
                );
            })}
        </div>);
}

export default CodifyChatSuggestions;